
import React, {useContext} from 'react';
import {WispContext} from './WispProvider';
import {WispButton} from './components/WispButton';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import {wispHistory} from './components/WispHistory.js';
import {WispNotification} from './components/WispNotification';

export function getChallengeDiamonds(wispData, challenge) {
    var diamonds = [];
    if (wispData.eventData['challenges']) {
        var cells = wispData.eventData['challenges']['cells'];
        for (var i = 0;i < cells.length;i++) {
            var row = cells[i];
            if (row['cols'][0] && row['cols'][1] && row['cols'][0]['v'] === challenge) {
                var level = row['cols'][1]['v'];
                if (level) {
                    try{
                        level = parseInt(level);
                        for (var ti = 0;ti < level; ti++) {
                            diamonds.push(
                                <div key={ti} style={{marginRight: '2px'}}><FontAwesomeIcon icon={faCircle} /></div>
                            );
                        }
                    } catch(err) {
                        console.log('challenge level error!');
                    }
            
                }
            }				
        }
    }
    return diamonds
}

// This popup is used for both challenges on the challenge map and trips on the user's trip map - the former will provide a challengeId prop
export const EventPopup = (props) => {

    var wispData = useContext(WispContext);
	const [notificationMessage, setNotificationMessage] = React.useState('');

	function closePopup() {
		props.closeFN(false);
    }

    function gotoEvent(eventId, eventType, docId, url) {
        /* if (process.env.REACT_APP_TEST === 'true') {    // need a test site
            url = 'cotswoldwa_2041';
        } */
        if (eventType === 'motor') {
            if (docId) {
                wispHistory.push('supportcrazydoes/' + url + '/' + docId);         // other_3012021
            } else {
                setNotificationMessage("More information on " + props.title + " coming soon!");
            }
        } else if (eventType === 'my') {
            wispHistory.push('/' + props.userId + '/' + eventId);
        } else {
            wispHistory.push('event/' + url);
        }
    }

    var notificationDiv;
    if (notificationMessage !== '') {
        notificationDiv = (
            <div style={{marginTop: '10px'}}>
    			<WispNotification message={notificationMessage} type='success' />
            </div>
        );
	}

    var title='';
    var challengeDesc;
    var challengeLoc;
    var challengeDif;
    if (wispData.eventData['challenges'] && props.challengeId) {
        var cells = wispData.eventData['challenges']['cells'];
        cells.forEach((row) => {
            if (row['cols'][0] && row['cols'][0]['v'] === props.challengeId) {
                if (row['cols'][2]) {
                    title = row['cols'][2]['v'] + ' Challenge';
                }
                if (row['cols'][3]) {
                    challengeDesc = row['cols'][3]['v'];
                }
                if (props.desc && props.desc.length > 1) {
                    challengeLoc = props.title;
                }
                if (row['cols'][1]) {
                    challengeDif = row['cols'][1]['v'];
                }
            }
        });
    } else if (!props.challengeId) {
        title = props.title;
    }

    var difficulty;
    if (challengeDif) {
        difficulty = (
            <div className='challengeInfo'>
                difficulty&nbsp;{getChallengeDiamonds(wispData, challengeDif)}
            </div>
        );
    }

    return (
        <div className='event_popup_div'>
            <div className="event_popup_close" onClick={closePopup}>
                <FontAwesomeIcon className='fa-2x' icon={faTimes} />
            </div>
            <div className='event_popup_title'>{title}</div>
            {difficulty}
            <div className='event_popup_desc'>{challengeDesc}</div>
            <div className='event_popup_location'>{challengeLoc}</div>
            <div className='event_popup_desc'>{props.desc}</div>
            <div style={{marginTop: '30px'}} >
                <WispButton
                    bcls='primaryButton'
                    d='inline-block'
                    width="260px"
                    js={() => gotoEvent(props.eventId, props.eventType, props.docId, props.url)}
                    m='0px'
                    mes={props.action} />
            </div>
            {notificationDiv}
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
