import React, {useContext, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {WispContext} from './WispProvider';
import {getDateStr, idsMatch, isAuthenticated} from './util/base.js';
import { fileType, getLinkedDoc } from './util/document.js';
import { fetchGet, getWispUrl, wisp_urls } from './util/fetch.js';
import { CrazyEvent, handleApiLoaded } from './util/googlemap.js';
import { goToSite } from './util/site.js';
import { followDoer, isFollowing, unfollowDoer } from './util/user.js';
import GoogleMapReact from 'google-map-react';
import RemoveSitePopup from './popups/RemoveSitePopup.js';
import { SharePopup } from './popups/SharePopup.js';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';
import { MapGroup } from './components/MapGroup.js';
import { WispMenu } from './components/WispMenu';
import { EventPopup } from './EventPopup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faList, faPlus, faExclamation, faShareSquare} from '@fortawesome/free-solid-svg-icons';
import videojs from 'video.js';
import $ from 'jquery';
import ReactPlayer from 'react-player';

// props.user is the user object associated with this dashboard
export const DashboardView = (props) => {

	var forceUpdateCount = 0;
	var newUrl = '';

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [showRemoveSitePopup, setShowRemoveSitePopup] = React.useState(false);
	const [removeSite, setRemoveSite] = React.useState(null);
	const [center, setCenter] = React.useState([53.1355, -57.6604]);
	const [showEventId, setShowEventId] = React.useState('');
	const [mapMode, setMapMode] = React.useState('map');
	// const [videoDocRefs, setVideoDocRefs, videoDocRef] = React.useState({});
	const [vidPlayers, setVidPlayers] = React.useState({});
	const [posters, setPosters] = React.useState({});
	const [createdListener, setCreatedListener] = React.useState(false);
	const [gotFollowMaps, setGotFollowMaps] = React.useState(false);
	const [nRenderDocs, setNRenderDocs] = React.useState(3);
	const [yScroll, setYScroll] = React.useState(0);
    const [zoom, setZoom] = React.useState(1);
    const [openGroupNum, setOpenGroupNum] = React.useState(-1);
    const [overLappedGroups, setOverLappedGroups] = React.useState([]);
    const [overlappedDocGroupSites, setOverlappedDocGroupSites] = React.useState({});
	const [showShareMap, setShowShareMap] = React.useState(null);

	// props.user is homeUser, which is userId from url - i.e. not necessarily signed in user. props.user was derived from props.userId
    useEffect(() => {
		console.log("DashboardView: useEffect");
		// get follow maps if the auth user is this user's page, otherwise you should get a stream of posts from this user, i.e. only follow maps that are from this user
		// how do you get stream of posts from this user? user_followmaps_get handles it
		//if (isAuthenticated(wispData) && idsMatch(props.userId, wispData.publicState.wispUserId) && !gotFollowMaps) {
			setGotFollowMaps(true);
			console.log('Getting follow maps');
			var url = getWispUrl(wisp_urls.user_followmaps_get, props.user.id);
			fetchGet(url, getFollowMapResults, wispData, true, null, {'app-id': process.env.REACT_APP_ID});
		// }
		if (isAuthenticated(wispData) && wispData.following === null) {
			reloadFollowing(false);
		}
		if (!createdListener) {
			setCreatedListener(true);
			console.log('creating listener');
			window.addEventListener('scroll', handleScroll);
		}
    }, [props.user]);

	function onMapChange(map) {
        if (map.zoom !== zoom) {
            calcOverlappedTrips(map.zoom, false);    // do this first otherwise the next two calls cause rerender before locationpts are updated
            setZoom(map.zoom);
            setOpenGroupNum(-1);
        }
    }

    function calcOverlappedTrips(tzoom, initCenter) {
        console.log('calcOverlappedTrips with tzoom = ' + tzoom);
        var tsites = {};
        var tgroups = [];
		var sites = props.sites;
		if (sites && sites.length > 0) {
			sites.forEach((site) => {
                // already in group?
                if (typeof tsites[site.id] === 'undefined') {
                    // got a valid lat long?
					var app_data = site.app_data;
					if (app_data && app_data.lat && app_data.long) {
                        var lat = parseFloat(app_data.lat);
                        var long = parseFloat(app_data.long);
                        if (!isNaN(lat) && !isNaN(long) && site.name) {
                            checkForOverlap(lat, long, site, tsites, tgroups, tzoom);
                        }
                    }
                }
            });
        }
		setOverLappedGroups(tgroups);
		setOverlappedDocGroupSites(tsites);
        console.log('final maps = ' + JSON.stringify(tsites));
    }

	function checkForOverlap(lat,long, thissite, tsites, tgroups, tzoom) {
        var meters_per_pixel = 156543.03392 * Math.cos(center[0] * Math.PI / 180) / Math.pow(2, tzoom);
        var meters_radius = meters_per_pixel * 35;
        var nOverlapped = 0;
		var sites = props.sites;
        console.log('CHECKING OVERLAPPED for ' + thissite.name + '/' + thissite.id + ' with lat = ' + lat + ' and long = ' + long);
        if (sites && sites.length > 0) {
            sites.forEach((site) => {
                // make sure not checking the same site
                if (site.id !== thissite.id) {
                    // already in group?
                    if (typeof tsites[site.id] === 'undefined') {
						console.log('iterating site - name = ' + site.name + ' and id = ' + site.id + ' not same site and not in group yet');
                        // got valid lat long?
						var app_data = site.app_data;
						if (app_data && app_data.lat && app_data.long) {
							var tlat = parseFloat(app_data.lat);
							var tlong = parseFloat(app_data.long);
							if (!isNaN(tlat) && !isNaN(tlong)) {
								var dLat = Math.abs((Math.abs(tlat) - Math.abs(lat)) * 111139);
								var dLong = Math.abs((Math.abs(tlong) - Math.abs(long)) * 111139);
								console.log('dLat = ' + dLat + ' and dLong = ' + dLong + ' and meters_radius = ' + meters_radius);
								if (dLat < meters_radius && dLong < meters_radius){
									// OVERLAPPED!!!
									var ngroups = tgroups.length;
									if (typeof tsites[thissite.id] === 'undefined') {
										tgroups[ngroups] = {lat:lat, long:long, num:ngroups+1};
										ngroups = tgroups.length;
										tsites[thissite.id] = ngroups;
									}
									tsites[site.id] = ngroups;
									// console.log('OVERLAPPED filename = ' + thissite.filename + ' and othermap = ' + map.filename);
									nOverlapped++;
								}
							} else {
								console.log('invalid lat long');
							}
						}
                    }
                }
            });
        }
        if (tgroups.length > 0) {
            console.log('group = ' + JSON.stringify(tgroups[tgroups.length-1]));
        }
        console.log('maps = ' + JSON.stringify(tsites));

        return nOverlapped;
    }

	function onMapGroupClick(name) {
        var sites = props.sites;
        if (sites && sites.length > 0) {
            console.log('sites length = ' + sites.length);
            sites.forEach((site) => {
                if (site.name === name) {
					setShowEventId(site.url);
                }
            });
        }
    }

    function onMapGroupOpen(group) {
        console.log('open mapgroup num = ' + group['num']);
        // this is callback - need to get latest value of zoom
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
		calcOverlappedTrips(tzoom, false);
		setOpenGroupNum(group['num']);
    }

    function onMapGroupClose() {
        console.log('close mapgroups');
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
		calcOverlappedTrips(tzoom, false);    // do this first otherwise the next two calls cause rerender before locationpts are updated
        setOpenGroupNum(-1);
    }

	function handleScroll() {
		var tYScroll;
		setYScroll(prevScroll => {tYScroll = prevScroll; return (prevScroll)});
		if (window.scrollY != tYScroll) {
			// what's this doing?
			// setYScroll(prevScroll => {return (window.scrollY)});
			setYScroll(window.scrollY);

			// how far are we down the window?
			var el = document.getElementById('siteContainer');
			if (el) {
				var height = el.offsetHeight;
				var windowHeight = window.innerHeight;
				var yPos = window.scrollY + windowHeight;
				// console.log('yPos = ' + yPos + ' and height = ' + height);
				if ((yPos + 600) > height) {
					var nTRenderDocs;
					setNRenderDocs(prevCount => {nTRenderDocs = prevCount+3; return (prevCount + 3)});
					// setNRenderDocs(prevState => ({value: prevState.value + 3}));
					var tFollowMaps;
					wispData.setFollowMaps(prevMaps => {tFollowMaps = prevMaps; return (prevMaps)});
					if (tFollowMaps) {
						loadCurrentDocs(tFollowMaps, nTRenderDocs);
					}					
					setForceUpdate(forceUpdateCount+1);
				}
			}
		}
	}

	// maps is followmaps - NOT DOCMAPS
	function loadCurrentDocs(maps, nRenderDocs2) {
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}
		var tContent;
		wispData.setDocContent(prevContent => {tContent = prevContent; return (prevContent)});
		var tPosters;
		setPosters(prevValue => {tPosters = prevValue; return (prevValue)});
		if (tPosters === null || typeof tPosters === 'undefined') {
			console.log('tPosters = null');
			return;
		}

		// we start out with nRenderDocs = 3, when the user scrolls it gets increased by 3
		// I don't like how we count linked docs (like captions) in the count - ok if they're spread out - otherwise could get weird behavior?
		// so gonna keep track of actual renders versus map index - I guess the problem with that is if all the linked-docs got piled up in the front we'd load the entire stream
		var actualRenders = 0;
		maps.forEach((map, i) => {	// i starts at 0
			if (map.link_doc_id == null) {
				actualRenders++;
			}

			var postUndefined = (typeof tContent[map.doc_id + '_post'] === 'undefined');
			var postersUndefined = (typeof tPosters[map.doc_id] === 'undefined');
			// console.log('gonna check doc_id = ' + map.doc_id + " nRenderDocs/i/actualRenders = " + nRenderDocs2 + "/" + i + "/" + actualRenders  + " postUndefined = " + postUndefined + " postersUndefined = " + postersUndefined);
			// load the doc content if we don't have it yet - actualRenders will be 1 when i = 0
			if (actualRenders <= nRenderDocs2 && postUndefined && postersUndefined) {
				console.log('gonna load doc_id = ' + map.doc_id);
				if (fileType(map.filename) === 'mov') {
					var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + map.user_id + '/' + map.doc_id + '_video/' + map.doc_id + '.0000000.jpg';
					tPosters[map.doc_id] = poster;
					setPosters(tPosters);
				} else {

					var url = getWispUrl(wisp_urls.document_get, map.doc_id);
					if (wispData.mobileView) {
						url += "?view=mobile";
					} else {
						url += "?view=desktop";
					}
					url += "&format=html";
					// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
					fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog', selection: 'post' }, 'html');
				}
			}
		});
	}

	function getFollowMapResults(success, data) {
		if (success) {
            if ('detail' in data) {
				console.log('failed to load followmaps: ' + data);
			} else {
				wispData.setFollowMaps(data);
				var count = 0;

				var nTRenderDocs;
				setNRenderDocs(prevCount => {nTRenderDocs = prevCount; return (prevCount)});
				loadCurrentDocs(data, nTRenderDocs);
	
				// setVideoDocRefs(videoDocRefs);
				setForceUpdate(forceUpdateCount+1);
			}
		}
	}


	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			if (fileType(map.filename) === 'docx') {
				setTimeout(function () {
					processVideoNodes(map);
				}, 1000);
			}

			content[map.doc_id + '_post'] = data;
			wispData.setDocContent(content);
			forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
			setForceUpdate(forceUpdateCount+1);
		}
	}

	function processVideoNodes(map) {
		// var dom_nodes = $($.parseHTML(data));
		// var vidnodes = dom_nodes.find(".tengine-video");
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}
		var tPosters;
		setPosters(prevValue => { tPosters = prevValue; return (prevValue) });
		var vidnodes = $(".tengine_video_" + map.doc_id);
		var postfix = 'docx';
		for (var i = 0; i < vidnodes.length; i++) {
			var node = vidnodes[i];
			var html = node.innerHTML;
			// html will be of the format - process.env.REACT_APP_URL + "/" + getCreatorId() + '/' + props.site.url + '/' + props.map.doc_id;
			var ti = html.lastIndexOf('/');
			if (ti > 0) {
				var docId = html.substring(ti + 1);
				html = html.substring(0, ti);
				ti = html.lastIndexOf('/');
				if (ti > 0) {
					var siteUrl = html.substring(ti + 1);
					html = html.substring(0, ti);
					ti = html.lastIndexOf('/');
					if (ti > 0) {
						var creatorId = html.substring(ti + 1);
						var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '.0000000.jpg';
						tPosters[docId] = poster;
						setPosters(tPosters);
						var turl = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '_1.m3u8';
						var h = '700px';
						if (wispData.mobileView) {
							h = '280px';
						}
						var videoContent = (
							<div className='videoPlayerCont'>
								<ReactPlayer 
									url={turl}
									controls={true}
									width='100%'
									height={h} 
									/>
							</div>
						);
						ReactDOM.render(videoContent, node);
					}
				}
			}
		}
		var i = 0;

	}

	// tried putting this in base.js but got issues with videoDocRefs
	/* function createPlayer(map, deployment, userId, count) {
		console.log("createplayer count = " + count);
		// make sure we have the current value of videoDocRefs
		var tVideoDocRefs;
		setVideoDocRefs(currentState => {tVideoDocRefs = currentState; return (currentState)});

		if (tVideoDocRefs[map.doc_id] && typeof tVideoDocRefs[map.doc_id].current != 'undefined') {
			console.log('dashboardview: creating player for ' + map.doc_id);
			const videoJsOptions = {
				autoplay: false,
				controls: true,
				fill: true,
				sources: [{
					src: 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + userId + '/' + map.doc_id + '_video/' + map.doc_id + '_1.m3u8',
					type: 'application/vnd.apple.mpegurl',
				}]
			}
			var player = videojs(tVideoDocRefs[map.doc_id].current, videoJsOptions, function onPlayerReady() {
				console.log('onPlayerReady', this)
			});
		} else if (count < 10) {
			console.log('dashboardview: video element not ready');
			setTimeout(function () {
				createPlayer(map, deployment, userId, count + 1);
			}, 1000);
		} else {
			console.log('dashboardview: count >= 10');
		}
	} */

	function createFN() {
		wispHistory.push('/newtrip');
	}

	function handleMenuItem(key, id) {
		var selectedSite;
		if (props.sites) {
			props.sites.forEach((site) => {
				if (site.id === id) {
					selectedSite = site;
				}
			});
		}
		if (key === 'settings') {
			if (props.sites) {
				props.sites.forEach((site) => {
					if (site.id === id) {
						wispHistory.push('/' + publicState.wispUserId + '/' + site.url + '/edit');
					}
				});
			}
		} else if (key === 'delete') {
			setShowRemoveSitePopup(true);
			setRemoveSite(selectedSite);
		}
	}

	function closeRemoveSitePopup(deleted) {
		if (deleted) {
			props.updateSitesFN();
			var url = getWispUrl(wisp_urls.user_followmaps_get, props.user.id);
			fetchGet(url, getFollowMapResults, wispData, true, null, {'app-id': process.env.REACT_APP_ID});
		}
		setShowRemoveSitePopup(false);
	}

	function centerFN(lat, lng) {
		setCenter([lat, lng]);
	}
	
	function showFN(eventId) {
		setShowEventId(eventId);
	}

	function closeFN() {
		setShowEventId('');
	}

	function doMapToggle() {
		if (mapMode === 'map') {
			setMapMode('list');
		} else {
			setMapMode('map');
		}
	}

	/* function selPanDoc() {

	} */

	function selTrip(site) {
		goToSite(site.url, props.userId);
	}

	function postOnMap(map) {
		wispHistory.push('/' + map.user_id + '/' + map.site_url);
	}

	function goRead(map) {
		wispHistory.push('/' + map.user_id + '/' + map.site_url);
	}

	function gotoUser(map) {
		wispHistory.push('/' + map.user_id + '/' + map.site_url);
	}

	function inTSites(tsites,site) {
		var inTSites = false;
			tsites.forEach((tsite) => {
				if (tsite.id === site.id) {
					inTSites = true;
				}
			});
		return inTSites;
	}

	function onShare(map) {
		setShowShareMap(map);
	}

	function closeSharePopup() {
		setShowShareMap(null);
	}

	function reloadFollowing(refresh) {
		var url = getWispUrl(wisp_urls.user_following, wispData.publicState.wispUserId);
		fetchGet(url, getFollowingResults, wispData, true, null, {'app-id': process.env.REACT_APP_ID});
		if (refresh) {
			setForceUpdate(forceUpdateCount+1);			
		}
	}

	function getFollowingResults(success, data) {
		if (success) {
            if ('detail' in data) {
				console.log('failed to load following list: ' + data);
			} else {
				wispData.setFollowing(data);
				var tFollowUser;
				wispData.setFollowUser(prevValue => {tFollowUser = prevValue; return (prevValue)});

				if (tFollowUser != null) {
					// do we have a followmap to this user?
					var found = false;
					data.forEach((id) => {
						if (id === tFollowUser.user_id) {
							found = true;
						}
					})
					if (!found) {
						followDoer(wispData, '/' + props.user.id, tFollowUser, props.user.id, reloadFollowing, props.user);
					} else {
//						setAlertMessage({text:"You're already following " + tFollowUser.first_name + " and will get notified when " + tFollowUser.first_name + " content is updated.",type:'warning'});
					}
					//var tRedir = wispData.createAccRedirect;
					wispData.setCreateAccRedirect(null);
					wispData.setFollowUser(null);
					wispData.setCreateAccNote(null);
					setForceUpdate(forceUpdateCount+1);
					// wispHistory.push(tRedir);
					// shouldn't have to redirect
				}
			}
		}
	}

	/* if (props.user) {
		console.log('user id = ' + props.user.id);
	} else {
		console.log('no user');
	} */

	var removeSitePopup;
	if (showRemoveSitePopup) {
		removeSitePopup = (
			<RemoveSitePopup closeFN={closeRemoveSitePopup} site={removeSite} type='trip' />
		);
	}
	var crazyEvents;
    var sites = props.sites;
	var popup;
	var nSites = 0;
	var testGroup;
    if (sites) {
		crazyEvents = sites.map(site => {
			nSites++;
			var app_data = site.app_data;
			if (app_data && app_data.lat && app_data.long) {
				var id = site.name;
				if (site.name.length > 10) {
					id = site.name.substring(0,10);
				}
				var lat = '44.815100000000001';
				if (app_data.lat) {
					lat = app_data.lat;
				}
				var long = '66.950400000000002';
				if (app_data.long) {
					long = app_data.long;
				}
				if (site.url === showEventId) {
					popup = (
						<EventPopup 
							closeFN={closeFN} 
							title={site.name} 
							desc={site.description} 
							docId={null} 
							userId={props.userId} 
							eventId={site.url} 
							eventType='my' 
							url={null} 
							challengeId={null} 
							action={"Go To " + site.name} 
						/>
					);
				}
				if (typeof overlappedDocGroupSites[site.id] === 'undefined') {
					return (
						<CrazyEvent
							action={"Go To " + site.name}
							lat={lat}
							lng={-long}  // longitudes are West - seems google maps wants East
							text={site.name}
							desc={site.description}
							eventId={site.url}
							eventType={'my'}
							key={id + nSites}
							centerFN={centerFN}
							showFN={showFN}
							show={showEventId === site.url}
							closeFN={closeFN}
							userId={props.userId}
						/>
					);
				}
			}
		});
		if (overLappedGroups && overLappedGroups.length > 0) {
			overLappedGroups.forEach((group) => {
				var numSites = 0;
				sites.forEach((site) => {
					if (overlappedDocGroupSites[site.id] === group['num']) {
						numSites++;
					}
				});
				// console.log('pushing group with lat = ' + group['lat'] + ' and long = ' + group['long']);
				var isOpen = group['num'] === openGroupNum;
				// console.log('rendering group with num = ' + group['num'] + ' and openGroupNum = ' + openGroupNum + ' and isOpen = ' + isOpen);
				testGroup = (
					<MapGroup 
						location={null}
						lat={group['lat']}
						lng={-group['long']}	  // longitudes are West - seems google maps wants East
						numDocs={numSites}
						groupMaps={overlappedDocGroupSites}
						sites={props.sites}
						group={group}
						clickFN={onMapGroupClick}
						openFN={onMapGroupOpen}
						closeFN={onMapGroupClose}
						isOpen={isOpen}
					/>

				);
				crazyEvents.push(testGroup);
			});
		}
	}

	var mapOrList;
	var toggleIconCont;
	if (mapMode === 'map') {
		toggleIconCont = (
			<FontAwesomeIcon className='tripBarIcon mapToggleIcon' icon={faList}/>
		)
		mapOrList = (
			<div className='dashboardMap'>
				<GoogleMapReact
					bootstrapURLKeys={{ key: 'AIzaSyDkHESKIvvZYnKgWo_n0XffV_L9S013_pY' }}
					center={center}
					onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, false)}
					defaultZoom={zoom}
                    yesIWantToUseGoogleMapApiInternals
                    onChange={onMapChange}
				>
					{crazyEvents}
				</GoogleMapReact>
				{popup}
			</div>
		);
	} else if (sites) {
		toggleIconCont = (
			<FontAwesomeIcon className='tripBarIcon listToggleIcon' icon={faMapMarkerAlt}/>
		)
		var count = 0;
		// order sites alphabetically - no efficient way to do it on backend so offloading it to front end
		var tsites = [];
		while (count < sites.length) {
			sites.forEach((site) => {
				if (tsites.length === count) {
					if (inTSites(tsites,site) === false) {
						tsites[count] = site;
					}
				} else if(tsites[count].name > site.name) {
					if (inTSites(tsites,site) === false) {
						tsites[count] = site;
					}
				}
			});
			count++;
		}

		count = 0;
		mapOrList = tsites.map(site => {
			count++;
			var siteMenu;
			if(site.user_access.role === 'admin' && idsMatch(props.userId, wispData.publicState.wispUserId)){
				var items = [
					{ key: 'settings', value: 'Edit' },
					{ key: 'delete', value: 'Delete' },
				]
				var menuStyle = { right: '-18px', top: '34px' };
				var iconStyle = { fontSize: '15px', padding: '2px 6px', cursor: 'pointer' };
				siteMenu = (
					<div className='tripMenuCont'>
						<WispMenu id={site.id} items={items} itemFN={handleMenuItem} iconStyle={iconStyle} menuStyle={menuStyle} />
					</div>
				);

			}

			var classes = 'tripListDiv';
			if (count === 1) {
				classes += ' tripListFirstDiv';
			}

			return (
				<div key={site.url} className={classes} onClick={() => selTrip(site)}>
					{siteMenu}
					<div style={{fontSize:'10px',textAlign:'center'}}>{site.name}</div>
				</div>
			);
		});
	}
	var newTrip;
	var newText = 'create new';
	var switchText1 = 'switch to ';
	if (wispData.mobileView) {
		newText = 'new';
		switchText1 = '';
	}
	var switchText = 'list view';
	if (mapMode === 'list') {
		switchText = 'map view';
	}
	var mapToggle = (
		<React.Fragment>
			<div className='mapToggleDiv' onClick={doMapToggle}>
				{toggleIconCont}
			</div>
			<div className='mapToggleTextDiv' onClick={doMapToggle}>
				{switchText1}{switchText}
			</div>
		</React.Fragment>
	);
	if(idsMatch(props.userId, wispData.publicState.wispUserId)){
		var exclaim;
		if (nSites === 0) {
			exclaim = (
				<div className='exclaimDiv' onClick={createFN}>
					<FontAwesomeIcon className='exclaimIcon' icon={faExclamation}/>
				</div>
			);
		}
		newTrip = (
			<React.Fragment>
				<div className='newAdvDiv' onClick={createFN}>
					{newText}
				</div>
				{exclaim}
			</React.Fragment>
		);
	}

	var posts;
	if (wispData.followMaps !== null && wispData.docContent) {
		var tc = 0;
		posts = wispData.followMaps.map(map => {
			if (map.link_doc_id === null) {
				tc = tc + 1;
				if (tc <= nRenderDocs && props.user) {
					var dt = new Date(map.date_time);
					dt = getDateStr(dt, false);
					var content;
					var mapFloater;
					var shareFloater;
					var keepReading;
					var tcreatorId = props.user.id;
					// follow maps don't have creator_id
					if (map.user_id) {
						tcreatorId = map.user_id;	// should have the creatorId in map
					}
					var deployment = 'prod';
					if (process.env.REACT_APP_TEST === 'true') {
						deployment = 'test';
					}
					var linkedDocEl;
					if (fileType(map.filename) === 'mov') {
						var turl = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + tcreatorId + '/' + map.doc_id + '_video/' + map.doc_id + '_1.m3u8';
						var h = '700px';
						if (wispData.mobileView) {
							h = '280px';
						}
						content = (
							<div className='videoPlayerCont'>
								<ReactPlayer 
									url={turl}
									controls={true}
									width='100%'
									height={h} 
									/>
							</div>
						);
						/* content = (
							<div className='videoPlayerCont'>
								<div data-vjs-player>
									<video controls poster={posters[map.doc_id]} ref={videoDocRefs[map.doc_id]} className="video-js" ></video>
								</div>
							</div>
						); */
					} else {
						if (wispData.docContent[map.doc_id + '_post'] && props.user) {
							content = (
								<div className='wispBlog' dangerouslySetInnerHTML={{ __html: wispData.docContent[map.doc_id + '_post'] }} style={{ position: 'relative' }} /> 
							);
						}

						/* if (map.lat && map.long) {
							mapFloater = (
								<div className='postMapIconDiv' onClick={() => { postOnMap(map) }} >
									<FontAwesomeIcon className='postMapIcon' icon={faMapMarkerAlt} />
								</div>
							);
						} */
						var cls = 'lower';
						if (fileType(map.filename) === 'docx' || fileType(map.filename) === 'xlsx') {
							cls = 'upper';
						}
						// this code is similar to siteview but map is followmap - not docmap
						if (map.lat && map.long) {
							mapFloater = (
								<div className='postIconDiv' onClick={() => { postOnMap(map) }} >
									<div className={'postIconInnerDiv ' + cls}>
										<FontAwesomeIcon className='postMapIcon' icon={faMapMarkerAlt} />
									</div>
								</div>
							);
						}
						var shareFloater = (
							<div className='postIconDiv' onClick={() => { onShare(map) }} >
								<div className={'postIconInnerDiv ' + cls}>
									<FontAwesomeIcon className='postMapIcon' icon={faShareSquare} />
								</div>
							</div>
						);

						if (fileType(map.filename) === 'docx' || fileType(map.filename) === 'xlsx') {
							keepReading = (
								<div className='keepReading' onClick={() => { goRead(map) }}>View Trip &gt;</div>
							);
						}
						var linkedDocMap = getLinkedDoc(wispData.followMaps, map.doc_id);
						if (linkedDocMap) {
							var lcontent;
							if (wispData.docContent[linkedDocMap.doc_id + '_post']) {
								lcontent = (
									<div className='wispBlog' dangerouslySetInnerHTML={{ __html: wispData.docContent[linkedDocMap.doc_id + '_post'] }} style={{ position: 'relative' }} /> 
								);
							}
							linkedDocEl = (
								<div className='te_caption' key={linkedDocMap.doc_id + "_caption"}>
									{lcontent}
								</div>
							);
						}

					}


					return (
						<div key={map.id} className='postItemCont' style={{position:'relative'}}>
							<div className='postTitleCont' id={map.doc_id + '_pan_item'}>
								<div className='postItemName'>by <span className='userLink' onClick={() => gotoUser(map)}>{map.user_name}</span></div>
								<div className='postItemDate'>{dt}</div>
							</div>
							{shareFloater}
							{mapFloater}
							<div id={map.doc_id + '_content'} className='contentWrapper'>
								{content}
								{linkedDocEl}
							</div>
							{keepReading}
						</div>
					);
				} else {
					return (
						<div key={map.id} />
					)
				}
			}
		});
		if (!posts || posts.length === 0 && wispData.followMaps) {	// don't display a message until we get the follow maps
			// new account scenarios: 1) no trips or follows (no sitemaps - no followmaps)
			// 2) Created a trip
			// 3) Following
			var userSites = wispData.userSitesAuth;
			var msg = 'No new posts. Create an adventure or follow a Crazy Doer!';
			if (userSites && userSites.length > 0) {
				msg = 'All new posts will appear here. Choose an adventure above to create a new post!';
			} else if (wispData.followMaps && wispData.followMaps.length > 0) {
				msg = 'No new posts. Check back soon!';
			}
			posts = (
				<div className='noPostMessage'>
					<div className='noPostMessageInner'>
						{msg}
					</div>
				</div>
			);
		}
	}

	var sharePopup;
	if (showShareMap) {		// this is followmap - not docmap like in siteview
		var embed = false;
		var type = fileType(showShareMap.filename);
		if (type === 'mov' || type === 'jpeg' || type === 'png') {
			embed = true;
		}
		sharePopup = (
			<SharePopup map={showShareMap} site_url={showShareMap.site_url} user_id={showShareMap.user_id} closeFN={closeSharePopup} embed={embed} />
		)
	}

	var followButton;
	if (!isAuthenticated(wispData) || (wispData.following && (wispData.publicState.wispUser && props.user.id != wispData.publicState.wispUser.id))){
		if (isFollowing(wispData, props.user.id)) {
			followButton = (
				<div key='followBut' className='dashFollowBut'>
					<div className='mapToggleTextDiv' onClick={() => unfollowDoer(wispData, props.user.id, reloadFollowing)}>
						{"Unfollow " + props.user.first_name}
					</div>
				</div>
			);
		} else {
			followButton = (
				<div key='followBut' className='dashFollowBut'>
					<div className='mapToggleTextDiv' onClick={() => followDoer(wispData, '/' + props.user.id, props.user, props.user.id, reloadFollowing, props.user )}>
						{"Follow " + props.user.first_name}
					</div>
				</div>
			);
		}
	}

    return (
		<div style={{marginTop: '60px'}} id='siteContainer'>
			{removeSitePopup}
			<div className='tripBarDiv'>
				<div className='tripBarDivCont'>
					<div className='tripBarTitle'>
						Adventures
					</div>
					{followButton}
					{mapToggle}
					{newTrip}
				</div>
			</div>
			{mapOrList}
			{/*testGroup*/}
			{posts}
			{sharePopup}
        </div>
    );
}
