import React, {useContext, useEffect} from 'react';
import { WispContext } from './WispProvider';
import { DocManager } from './components/DocManager';
import { SiteProps } from './SiteProps';
import { SiteEdit } from './components/SiteEdit';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';

export const BookSettings = (props) => {

	var wispData = useContext(WispContext);

	useEffect(() => {

	}, [wispData.userSites]);

	return (
		<SiteEdit site={props.site} new={false} multiTrip selectView setPosition />
	);

}
