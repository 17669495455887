import React, { useContext, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faFileWord, faFileVideo, faFileImage } from '@fortawesome/free-regular-svg-icons';
import Decision from './components/Decision';
import { Footer } from './components/Footer';
import { WispContext } from './WispProvider.js';
import GoogleMapReact from 'google-map-react';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';
import { EventPopup } from './EventPopup.js';
import {loadData} from './util/fetch.js';
import { CrazyEvent, handleApiLoaded } from './util/googlemap.js';
/* import Shirt1Img from './images/nh48shirt_1.jpg';
import Shirt2Img from './images/nh48shirt_2.jpg';
import Shirt3Img from './images/nh48shirt_3.jpg'; */
import iconImage from './images/subpanelimg1.png';
import freeImage from './images/freetag.jpg';
import liveImage from './images/livesite.png';
import tripMapImage from './images/tripmap.png';
import tracksImage from './images/tracks.png';
import advPageImage from './images/adv_page.jpg';
import arcticBike from './images/arctic_bike.jpg';
import highCup from './images/highcup_small.jpg';
import cessFordImage from './images/cessford.jpg';
import $ from 'jquery';

// video test
import videojs from 'video.js';



export const Home = (props) => {
  const gearRef = useRef(null)
  var defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

	var wispData = useContext(WispContext);
  var videoNode;

  const [center, setCenter] = React.useState([53.1355, -57.6604]);
  const [showEventId, setShowEventId] = React.useState('');
  const [eventType, setEventType] = React.useState(null);
  const [forceUpdate, setForceUpdate] = React.useState(0);
  const [showAccountCreate, setShowAccountCreate] = React.useState(false);
  const [vidPlayer, setVidPlayer] = React.useState(null);
  const [shopifyInit, setShopifyInit] = React.useState(false);
  const [shopifyUI, setShopifyUI] = React.useState(null);
  const [shopifyComponents, setShopifyComponents] = React.useState({});
	useEffect(() => {
      var etype = window.localStorage.getItem("challenge_category");
      if (!etype) {
        etype = 'hikes';
      }
      eventTypeClick(etype);
      loadEvents(etype);

			if (window.ShopifyBuy && !shopifyInit) {
				if (window.ShopifyBuy.UI) {
					ShopifyBuyInit();
				}
			}  
    }, []);

  function loadEvents(etype) {
    if (etype === 'hikes') {
      loadData(wispData, 'hikes', '5638443006689280', doneFN); // doc id from easyappdata
    } else {
      loadData(wispData, 'motor', '5679818439917568', doneFN);   // '5689837499711488'
    }
  }


  function ShopifyBuyInit() {
    console.log('shopifyInit');
		setShopifyInit(true);
    var client = window.ShopifyBuy.buildClient({
      domain: 'crazy-does.myshopify.com',
      storefrontAccessToken: '45f7a50b3fc023616f8bb06c9b0e676c',
    });
    window.ShopifyBuy.UI.onReady(client).then(function (ui) {
      setShopifyUI(ui);
      var gear = props.gear;
      var scroll = true;
      if (typeof gear === 'undefined' || gear === '') {
        gear = 'lejog';
        scroll = false;
      }
      setTimeout(function () {
        onGear(gear, ui, scroll);
      }, 1000);	
    });
  }

  function createAcct() {
    setShowAccountCreate(false);
    wispData.setShowCreateAccount(true);
  }

  function eventClick(eventId) {
    wispHistory.push('event/' + eventId);
  }

  function centerFN(lat, lng) {
    setCenter([lat, lng]);
  }

  function showFN(eventId) {
    setShowEventId(eventId);
  }

  function closeFN() {
    setShowEventId('');
  }

  function eventTypeClick(type) {
    $(".eventType").removeClass('active');
    $("#" + type + "Type").addClass('active');
    setEventType(type);
    window.localStorage.setItem("challenge_category", type);
    
    loadEvents(type);

  }

  function doneFN() {
    setForceUpdate(forceUpdate+1);
  }

  function decisionCancel() {
    setShowAccountCreate(false);
  }

  function goToStore() {
    window.location = 'https://store.crazydoes.com';
  }

  function readMore(val) {
    if (val === 1) {
      wispHistory.push('/jefftidd/lejog_07252021')
    } else if (val === 2) {
      wispHistory.push('/jefftidd/deadhorse_ak_07082021/5747714960654336')
    } else if (val === 3) {
      window.location = '/jefftidd/lejog_2022';
    }
  }

  function onGear(cat, ui, scroll) {
    var options = {
      "product": {
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "20px",
              "margin-bottom": "50px",
              "width": "calc(25% - 20px)"
            }
          }
        },
        "buttonDestination": "modal",
        "contents": {
          "options": false
        },
        "text": {
          "button": "View product"
        }
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          }
        },
        "text": {
          "button": "Add to cart"
        }
      },
      "option": {},
      "cart": {
        "text": {
          "total": "Subtotal",
          "button": "Checkout"
        }
      },
      "toggle": {}
    };

    if (cat === 'nh48' && typeof shopifyComponents['nh48'] === 'undefined') {
      ui.createComponent('collection', {
        id: '238833991871',
        node: document.getElementById('collection-nh48-1632430927498'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options,
      });
      shopifyComponents['nh48'] = true;
      setShopifyComponents(shopifyComponents);
    } else if (cat === 'lejog' && typeof shopifyComponents['lejog'] === 'undefined') {
      ui.createComponent('collection', {
        id: '297200582847',
        node: document.getElementById('collection-lejog-1632430927498'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options,
      });
      shopifyComponents['lejog'] = true;
      setShopifyComponents(shopifyComponents);
    } else if (cat === 'four' && typeof shopifyComponents['four'] === 'undefined') {
      ui.createComponent('collection', {
        id: '284406546623',
        node: document.getElementById('collection-four-1632430927498'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options,
      });
      shopifyComponents['four'] = true;
      setShopifyComponents(shopifyComponents);
    } else if (cat === 'crazy' && typeof shopifyComponents['crazy'] === 'undefined') {
      ui.createComponent('collection', {
        id: '270527758527',
        node: document.getElementById('collection-crazy-1632430927498'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options,
      });
      shopifyComponents['crazy'] = true;
      setShopifyComponents(shopifyComponents);
    } else if (cat === 'jmt' && typeof shopifyComponents['jmt'] === 'undefined') {
      ui.createComponent('collection', {
        id: '284454420671',
        node: document.getElementById('collection-jmt-1632430927498'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: options,
      });
      shopifyComponents['jmt'] = true;
      setShopifyComponents(shopifyComponents);
    }

    var els = document.getElementsByClassName("tripGear");
    for(var i = 0;i < els.length;i++) {
      els[i].classList.remove("selected");
    }
    var el = document.getElementById(cat + "_gear")
    el.classList.add("selected");

    $("#collection-lejog-1632430927498").hide();
    $("#collection-crazy-1632430927498").hide();
    $("#collection-nh48-1632430927498").hide();
    $("#collection-four-1632430927498").hide();
    $("#collection-jmt-1632430927498").hide();

    var id = 'collection-' + cat + '-1632430927498';
    $("#" + id).show();

    if (scroll) {
        // need this on time out otherwise something during page load pops the view back up the top - f'ing react!
        console.log('scrolling to gear');
        $('html, body').animate({
          scrollTop: $("#gear").offset().top
      }, 1000);    }
  }
  console.log('home render');
  var crazyEvents;
  var popup;

  if (eventType === 'hikes' && wispData.eventData['hikes']) {
    var cells = wispData.eventData['hikes']['cells'];
    crazyEvents = cells.map(row => {
      var name = '';
      var url = '';
      var lat = '0';
      var long = '0';
      var desc = '';
      var id = '';
      var challengeId;

      row.cols.forEach((col, i) => {
        if (col['c'] === 'A') {
          name = col['v'];
        } else if (col['c'] === 'B') {
          url = col['v'];
        } else if (col['c'] === 'C') {
          lat = col['v'];
        } else if (col['c'] === 'D') {
          long = col['v'];
        } else if (col['c'] === 'E') {
          desc = col['v'];
        } else if (col['c'] === 'F') {
          id = col['v'];
        } else if (col['c'] === 'G') {
          challengeId = col['v'];
        }
      });

      /* if (row['cols'][0]) {
        name = row['cols'][0]['v'];
      }
      if (row['cols'][1]) {
        url = row['cols'][1]['v'];
      }
      if (row['cols'][2]) {
        lat = row['cols'][2]['v'];
      }
      
      if (row['cols'][3]) {
        long = row['cols'][3]['v'];
      }
      if (row['cols'][4]) {
        desc = row['cols'][4]['v'];
      }
      if (row['cols'][5]) {
        id = row['cols'][5]['v'];
      }

      if (row['cols'][6]) {
        challengeId = row['cols'][6]['v'];
      } */

      var cLat;
      var cLong;
      try {
        cLat = Number(lat);
        cLong = Number(long);
      } catch(err) {
      }

      if (name && name != '' && cLat && cLong) {
        if (id === showEventId) {
          popup = (
            <EventPopup closeFN={closeFN} title={name} desc={desc} docId={null} eventId={id} eventType={eventType} url={url} challengeId={challengeId} action="Learn More" />
          );
        }
        return (
          <CrazyEvent
              action="Learn More"
              lat={lat}
              lng={-parseFloat(long)}  // longitudes are West - seems google maps wants East
              text={name}
              challengeId={challengeId}
              desc={desc}
              eventId={id}  // not used I think
              eventType={eventType}
              key={id}
              url={url}
              centerFN={centerFN}
              showFN={showFN}
              show={showEventId === id}
              closeFN={closeFN}
              // doesn't need userId - those only for a user's trip
            />
        );
      }
    });
  } else if (eventType === 'motor' && wispData.eventData['motor'] != null) {
    var cells = wispData.eventData['motor']['cells'];
    crazyEvents = cells.map(row => {
      var name = '';
      if (row['cols'][0]) {
        name = row['cols'][0]['v'];
      }
      var url = '';
      if (row['cols'][1]) {
        url = row['cols'][1]['v'];
      }
      var lat = '0';
      if (row['cols'][2]) {
        lat = row['cols'][2]['v'];
      }
      var long = '0';
      if (row['cols'][3]) {
        long = row['cols'][3]['v'];
      }
      var desc = '';
      if (row['cols'][4]) {
        desc = row['cols'][4]['v'];
      }
      var docId = '';
      if (row['cols'][5]) {
        docId = row['cols'][5]['v'];
      }
      var challengeId;
      if (row['cols'][6]) {
        challengeId = row['cols'][6]['v'];
      }

      if (name && name != '') {
        if (docId === showEventId) {
          popup = (
            <EventPopup closeFN={closeFN} title={name} desc={desc} docId={docId} eventId={docId} eventType={eventType} url={url} challengeId={challengeId} action="Learn More" />
          );
        }
        return (
          <CrazyEvent
            action="Learn More"
            lat={lat}
            lng={-parseFloat(long)}  // longitudes are West - seems google maps wants East
            text={name}
            challengeId={challengeId}
            desc={desc}
            docId={docId}
            eventId={docId}
            eventType={eventType}
            key={docId}
            url={url}
            centerFN={centerFN}
            showFN={showFN}
            show={showEventId === docId}
            closeFN={closeFN}
            // doesn't need userId - those only for a user's trip
          />
        );
      }
    });
  }

  var story1 = (
    <div className='crazyTile crazyTile1' onClick={() => eventClick('lejog')}>
      <div className='crazyTileImage1'>
        <div className='eventTileTitleCont'>
          <div className="eventTileTitle">
            LEJOG
          </div>
          <div className="eventTileSubTitle">
            Land's End to John O' Groats
          </div>
        </div>
      </div>
      <div className='eventAction'>
        View Event&nbsp; <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    </div>
  );

  var story2 = (
    <div className='crazyTile crazyTile2' onClick={() => eventClick('nh48')}>
      <div className='crazyTileImage2'>
        <div className='eventTileTitleCont'>
          <div className="eventTileTitle">
            NH48
          </div>
          <div className="eventTileSubTitle">
            New Hampshire 4000 footers
          </div>
        </div>
      </div>
      <div className='eventAction'>
        View Event&nbsp; <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    </div>
  );

  var story3 = (
    <div className='crazyTile crazyTile3' onClick={() => eventClick('cotswold')}>
      <div className='crazyTileImage3'>
        <div className='eventTileTitleCont'>
          <div className="eventTileTitle">
            Cotswold Way
          </div>
          <div className="eventTileSubTitle">
            Bath to Chipping Campden
          </div>
        </div>
      </div>
      <div className='eventAction'>
        View Event&nbsp; <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    </div>
  );

  var story4 = (
    <div className='crazyTile crazyTile4' onClick={() => eventClick('jmt')}>
      <div className='crazyTileImage4'>
        <div className='eventTileTitleCont'>
          <div className="eventTileTitle">
          John Muir Trail
          </div>
          <div className="eventTileSubTitle">
            Yosemite to Mount Whitney
          </div>
        </div>
      </div>
      <div className='eventAction'>
        View Event&nbsp; <FontAwesomeIcon icon={faArrowRight}/>
      </div>
    </div>
  );

  var homeActionDiv = (
    <div className="promoAction">
      <WispButton
        bcls='primaryButton'
        d='block'
        js={createAcct}
        m='0px'
        mes="Start Doing!" />
    </div>
  );

  var featuredGearSection = (
      <div id='gear' className="homeGearSection">
        <div className='promoTitle'>Reward Yourself with Crazydoes Gear</div>
        <div className='tripGearCont'>
          <div id='lejog_gear' className='tripGear' onClick={() => onGear('lejog', shopifyUI, false)}>LEJOG</div>
          <div id='crazy_gear' className='tripGear' onClick={() => onGear('crazy', shopifyUI, false)}>Crazydoes</div>
          <div id='nh48_gear' className='tripGear' onClick={() => onGear('nh48', shopifyUI, false)}>NH48</div>
          <div id='jmt_gear' className='tripGear' onClick={() => onGear('jmt', shopifyUI, false)}>JMT</div>
          <div id='four_gear' className='tripGear' onClick={() => onGear('four', shopifyUI, false)}>Four Points</div>
        </div>
        <div id='collection-lejog-1632430927498'></div>
        <div id='collection-crazy-1632430927498'></div>
        <div id='collection-nh48-1632430927498'></div>
        <div id='collection-four-1632430927498'></div>
        <div id='collection-jmt-1632430927498'></div>
      </div>
  );

  var newAcountSection;
  if (!wispData.publicState.validToken) {
    newAcountSection = (
      <div className="crazySection2">
        <div className='promoTitle'>Join Crazydoes</div>
        <div className='promoItemCont'>
          <div className='promoItem'>
            <div className='promoItemHeading'>Personal Trip Map</div>
            <div className='promoItemText'>See your trips on a global map with links to your trip logs.</div>
            <div className='innerBottomPanel' style={{paddingTop: '91px'}}>
              <img width='300px' src={tripMapImage} />
            </div>
          </div>
          <div className='promoItem'>
            <div className='promoItemHeading'>Forever Trip Logs&trade;</div>
            <div className='promoItemText'>Will trip logs on other sites be around in 10 - 20 years? Or even tomorrow? All Crazydoes trip logs use file formats supported by 100s of other apps so they'll last forever.</div>
            <div className='innerBottomPanel' style={{paddingTop: '29px', width: '100px', margin: 'auto'}}>
              <img width='100px' src={iconImage} />
            </div>
          </div>
          <div className='promoItem'>
            <div className='promoItemHeading'>Live Trips</div>
            <div className='promoItemText'>Use the <a href='https://apps.apple.com/us/app/crazydoes/id1570555460'>Crazydoes mobile app</a> to post geotagged photos, videos &amp; text notes your own Crazydoes trip page.</div>
            <div className='innerBottomPanel' style={{paddingTop: '110px'}}>
              <img width='300px' src={liveImage} />
            </div>
          </div>
          <div className='promoItem'>
            <div className='promoItemHeading'>GPS Integration</div>
            <div className='promoItemText'>If you a Garmin inReach GPS, Crazydoes can pull tracks from your inReach and display them on a map. Tracks are automatically stored in open-standard KML format for long term access.</div>
            <div className='innerBottomPanel' style={{paddingTop: '25px'}}>
              <img width='300px' src={tracksImage} />
            </div>
          </div>
          <div className='promoItem'>
            <div className='promoItemHeading'>Pricing</div>
            <div className='promoItemText'>Crazydoes is free!</div>
            <div className='innerBottomPanel' style={{paddingTop: '100px'}}>
              <img width='300px' src={freeImage} style={{marginTop: '-20px'}} />
            </div>
          </div>
        </div>
        {homeActionDiv}
      </div>
    )
  }

  var createPopup;
  if (showAccountCreate) {
    var mes = 'Create a Crazydoes account to record & view your trips!';
    createPopup = (
      <Decision
          mes={mes}
          c1="Create Account"
          c2="Cancel"
          fn1={createAcct}
          fn2={decisionCancel}
          p1=""
          p2=""
          y="40"
        closeFN={decisionCancel}
      />

    );
  }

  var crazyPanel1;
  if (!window.cordova) {
    crazyPanel1 = (
      <div className="crazyPanel1">
        <div className="crazyCont1">
            <div className='crazyTitle'>Crazydoes</div>
            <div className='crazySubTitle'>The ultimate tool for outdoor adventures</div>
        </div>
      </div>
    );
  }

  var imageEl1, imageEl2;
  if (wispData.mobileView) {
    imageEl1 = (
      <div className="marketing_panel2">
        <img src={advPageImage} />
      </div>
    );
  } else {
    imageEl2 = (
      <div className="marketing_panel2 back_lgray">
        <img src={advPageImage} />
      </div>
    );
  }

  return (
      <div id="wrapper">
        { /* <div data-vjs-player style={{marginTop: '60px'}}>
          <video controls ref={ node => videoNode = node } className="video-js"></video>
          </div> */ }
        {crazyPanel1}
        {createPopup}
        <div className="marketing_section">
          <div className="marketing_row">
            <div className="marketing_panel1">
              <h2 className="h1">A dedicated page for every adventure</h2>
              <div className='marketing_subtext'>You and your companions can each use the <a href='https://apps.apple.com/us/app/crazydoes/id1570555460'>Crazydoes mobile app</a> to post geolocated comments, photos & videos to a shareable adventure map & feed, and optionally add Garmin inReach location tracking.</div>
              {imageEl1}
              <div className="promoAction">
                <WispButton
                  bcls='primaryButton'
                  d='block'
                  js={createAcct}
                  m='0px'
                  mes="Create a Free Crazydoes Page!" />
              </div>

            </div>
            {imageEl2}
          </div>
        </div>
        <div className="marketing_section">
          <div className="marketing_row">
            <div className="marketing_panel4 back_gray">
              <div className='marketing_icon_cont'>
                <FontAwesomeIcon className='marketing_icon' icon={faFileWord}/>Microsoft Word
              </div>
              <div className='marketing_icon_cont'>
                <FontAwesomeIcon className='marketing_icon' icon={faFileVideo}/>MP4 Videos
              </div>
              <div className='marketing_icon_cont'>
                <FontAwesomeIcon className='marketing_icon' icon={faFileImage}/>JPEG &amp; PNG Images
              </div>
              <div className='marketing_icon_cont'>
                <FontAwesomeIcon className='marketing_icon' icon={faMapMarkerAlt}/>KML Tracks
              </div>
            </div>
            <div className="marketing_panel3">
              <h2 className="h1">Create an Adventure Record that Lasts a Lifetime</h2>
              <div className='marketing_subtext'>Everything you post on Crazydoes is downloadable and supported by 100s of apps so you can be sure to enjoy it for years to come.</div>
              <div className="promoAction">
                <WispButton
                  bcls='primaryButton'
                  d='block'
                  js={createAcct}
                  m='0px'
                  mes="Get Started!" />
              </div>
            </div>
          </div>
        </div>

        {featuredGearSection}

        <div className="crazyPanel2" id="crazyPanel2" >
          <div className="crazyPanel2Inner">
            <div className='mapTitle'>Get Inspired for your Next Adventure</div>
            <div className='mapSubtitle'>Discover new adventures with the Crazydoes Challenge Explorer</div>
            <div className="eventTypes">
              <div id='hikesType' className="eventType active" onClick={() => eventTypeClick('hikes')}>Walking</div>
              <div id='motorType' className="eventType" onClick={() => eventTypeClick('motor')}>Motorized</div>
            </div>
            <div style={{ position: 'relative', height: '600px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDkHESKIvvZYnKgWo_n0XffV_L9S013_pY' }}
                center={center}
                defaultZoom={1}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, false)}
              >
                {crazyEvents}
              </GoogleMapReact>
              {popup}
            </div>
          </div>
        </div>
        <div className='storySection'>
          <div className='storyHeader'>
            Latest Adventures
          </div>
          <div className='crazyCont'>
            <div className='crazyStory'>
              <div className='storyImage'>
                <img src={highCup} />
              </div>
              <div className='storyTitle'>
                Lands End to John O' Groats - Part III
              </div>
              <div className='storyLink' onClick={() => readMore(1)}>
                Read More
              </div>
            </div>
            <div className='crazyStory'>
              <div className='storyImage'>
                <img src={arcticBike} />
              </div>
              <div className='storyTitle'>
                10,000 Miles to the Edge of America
              </div>
              <div className='storyLink' onClick={() => readMore(2)}>
                Read More
              </div>
            </div>  
            <div className='crazyStory'>
              <div className='storyImage'>
                <img src={cessFordImage} />
              </div>
              <div className='storyTitle'>
              Lands End to John O' Groats - Part IV
              </div>
              <div className='storyLink' onClick={() => readMore(3)}>
                Read More
              </div>
            </div>  
          </div>
        </div>

        <Footer mobileApp />
      </div>
  );
}
