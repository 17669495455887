import React, { useCallback, useContext, useEffect } from 'react';
import { WispContext } from '../WispProvider';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faMapMarker, faStickyNote, faUser, faVideo } from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { MapPopup } from '../popups/MapPopup.js';
import { TrackPopup } from '../popups/TrackPopup.js';
import { MapGroup } from './MapGroup.js';
import $ from 'jquery';


// TripPoint is used for tracks, users, photos, videos, map groups, etc
const TripPoint = ({ location, lat, lng, text, desc, docId, eventId, pointType, url, centerFN, showFN, show, closeFN, markerColor, user, wispData, ownerId, popup }) => {

    function crazyClick(text) {
        console.log('crazyClick for  ' + eventId)
        showFN(location, eventId, pointType, ownerId);
        centerFN(lat, lng);
    }

    function closePopup() {
        closeFN();
    }

    var fontSize = '28px';
    var icon;
    var m = 12;
    if (pointType === 'track') {
        icon = faMapMarker;
        fontSize = '14px';
    } else if (pointType === 'photo') {
        icon = faCamera;
    } else if (pointType === 'video') {
        icon = faVideo;
    } else if (pointType === 'user') {
        icon = faUser;
        fontSize = '48px';
        m = 24;
        if (wispData.mobileView) {
            m = 16;
            fontSize = '32px';
        }
    } else {
        icon = faStickyNote;
    }
    
    if (popup != null) {
        console.log('Got popup! ' + eventId);
    }

    return (
        <div style={{ cursor: 'pointer', margin: '-' + m + 'px 0px 0px -' + m + 'px', position: 'relative' }}>
            <FontAwesomeIcon icon={icon} style={{ color: markerColor, fontSize: fontSize }} onClick={() => crazyClick()} />
            {popup}
        </div>
    );
}

export const PositionMap = (props) => {
	var forceUpdateCount = 0;

    const [center, setCenter] = React.useState([53.1355, -57.6604]);
    const [zoom, setZoom] = React.useState(12);
    const [defaultZoom, setDefaultZoom] = React.useState(12);
    const [showEventId, setShowEventId] = React.useState('');
    const [showLocation, setShowLocation] = React.useState(null);
    const [showPointType, setShowPointType] = React.useState('');
    const [showOwnerId, setShowOwnerId] = React.useState(null); // user id of person who owns this track, doc, etc
    const [loadedLocationPoints, setLoadedLocationPoints] = React.useState(false);
    // const [overlappedDocGroupMaps, setOverlappedDocGroupMaps] = React.useState({});
    // const [overLappedGroups, setOverLappedGroups] = React.useState([]);
    const [openGroupNum, setOpenGroupNum] = React.useState(-1);
	const [forceUpdate, setForceUpdate] = React.useState(0);
    var wispData = useContext(WispContext);
    var markerColors = [
        'rgb(80,80,240)',
        'rgb(240,80,80)',
        "rgb(70,240,70)",
        'rgb(160,160,70)',
        'rgb(70,160,160)',
    ];

    useEffect(() => {
        if (props.siteDocMaps && !loadedLocationPoints) {
            console.log('calling site_locations_get');
            var url = getWispUrl(wisp_urls.site_locations_get, props.site.id);
            fetchGet(url, getLocationResult, wispData, true, '', { 'start-date': props.site.date_start, 'end-date': props.site.date_end });
        } else if (props.centerMapId) {
            calcOverlappedDocsAndGetPts(wispData.locationData, openGroupNum, zoom, true, showEventId, showPointType, showOwnerId,showLocation);
        }

    }, [props.siteDocMaps, props.centerMapId]);

    function createMapOptions(maps) {
        // next props are exposed at maps
        // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
        // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
        // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
        // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
        // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
        return {
            mapTypeId: 'terrain',
            zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_CENTER,
                style: maps.ZoomControlStyle.SMALL
            },
            mapTypeControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT
            },
            mapTypeControl: true,
            styles: [
                {
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "saturation": 36
                        },
                        {
                            "color": "#FFFFFF"
                        },
                        {
                            "lightness": 10
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 46
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        },
                        {
                            "weight": 1.2
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 20
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape.natural",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "saturation": "-21"
                        },
                        {
                            "lightness": "-66"
                        },
                        {
                            "gamma": "1.00"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 21
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "color": "#284d1e"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "lightness": "-63"
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 29
                        },
                        {
                            "weight": 0.2
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 18
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 16
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "lightness": 19
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "color": "#0f252e"
                        },
                        {
                            "lightness": 17
                        }
                    ]
                }
            ]
        };
    }

    function centerFN(lat, lng) {
        setCenter([lat, lng]);
    }

    function handleApiLoaded(map, maps) {
        map.setOptions(createMapOptions(maps));
    }

    function getLocationResult(success, data) {
        if (success && typeof data['detail'] === 'undefined') {
            wispData.setLocationData(data);
            calcOverlappedDocsAndGetPts(data, openGroupNum, zoom, true, showEventId, showPointType, showOwnerId, showLocation);
        }
        setLoadedLocationPoints(true);
    }

    function doNothing() {

    }

    // userId that owns this doc or track
    function mapClick(location, id, type, ownerId, tShowLocation) {
        setShowLocation(location);
        setShowEventId(id);
        setShowPointType(type);
        setShowOwnerId(ownerId);

        // update popups
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
        var locData;
        wispData.setLocationData(data => {locData = data; return (data)});

        calcOverlappedDocsAndGetPts(locData, -1, tzoom, false, id, type, ownerId, tShowLocation);
    }

    function closePopup() {
        setShowEventId(null);
        setShowPointType(null);
        setShowLocation(null);
        setShowOwnerId(null);
        // update popups
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
        var locData;
        wispData.setLocationData(data => {locData = data; return (data)});

        calcOverlappedDocsAndGetPts(locData, -1, tzoom, false);
    }

    function timeInRange(ts) {
        var when = ts[0].getElementsByTagName("ns0:when");
        if (when && when.length > 0) {
            var val = when[0].innerHTML;
            var d = new Date(val);
            var start = new Date(props.site.date_start);
            var end = new Date(props.site.date_end);
            end = new Date(end.getTime() + (23 * 60 * 60 * 1000) + (59 * 60 * 1000));
            if (d >= start && d <= end) {
                return true;
            }
            return false;
        }
        return true;
    }
/*
    function freshTime(ts) {
        var when = ts[0].getElementsByTagName("ns0:when");
        if (when && when.length > 0) {
            var val = when[0].innerHTML;
            var d = new Date(val);
            var d2 = new Date();
            var Difference_In_Time = d2.getTime() - d.getTime();
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            if (Difference_In_Days < 1) {
                return true;
            }
            return false;
        }
        return false;

    } */

    function onMapChange(map) {
        if (map.zoom !== zoom) {
            calcOverlappedDocsAndGetPts(wispData.locationData, -1, map.zoom, false);    // do this first otherwise the next two calls cause rerender before locationpts are updated
            setZoom(map.zoom);
            setOpenGroupNum(-1);
        }
    }

    function checkForOverlap(lat,long, thismap, tmaps, tgroups, tzoom) {
        var meters_per_pixel = 156543.03392 * Math.cos(center[0] * Math.PI / 180) / Math.pow(2, tzoom);
        var meters_radius = meters_per_pixel * 35;
        var nOverlapped = 0;
        var siteDocMaps = props.siteDocMaps;
        // console.log('thismap.lat = ' + thismap.lat + ' and thismap.long = ' + thismap.long);
        // console.log('checking overlapped for ' + thismap.id + ' with lat = ' + lat + ' and long = ' + long);
        if (siteDocMaps && siteDocMaps.length > 0) {
            siteDocMaps.forEach((map) => {
                // console.log('searching for overlapping - id = ' + map.id);
                // make sure not checking the same doc
                if (map.id !== thismap.id) {
                    // already in group?
                    if (typeof tmaps[map.id] === 'undefined') {
                        // got valid lat long?
                        var tlat = parseFloat(map.lat);
                        var tlong = parseFloat(map.long);
                        if (!isNaN(tlat) && !isNaN(tlong)) {
                            var dLat = Math.abs((Math.abs(tlat) - Math.abs(lat)) * 111139); // guess there's 111,139 meters per degree of latitude and longitude
                            var dLong = Math.abs((Math.abs(tlong) - Math.abs(long)) * 111139);
                            // console.log('dLat = ' + dLat + ' and dLong = ' + dLong + ' and meters_radius = ' + meters_radius);
                            if (dLat < meters_radius && dLong < meters_radius){
                                // OVERLAPPED!!!
                                var ngroups = tgroups.length;
                                if (typeof tmaps[thismap.id] === 'undefined') {
                                    tgroups[ngroups] = {lat:lat, long:long, num:ngroups+1};
                                    ngroups = tgroups.length;
                                    tmaps[thismap.id] = ngroups;
                                }
                                tmaps[map.id] = ngroups;
                                // console.log('OVERLAPPED filename = ' + thismap.filename + ' and othermap = ' + map.filename);
                                nOverlapped++;
                            }
                        } else {
                            // console.log('invalid lat long');
                        }
                    }
                }
            });
        }
        /* if (tgroups.length > 0) {
            console.log('group = ' + JSON.stringify(tgroups[tgroups.length-1]));
        }
        console.log('maps = ' + JSON.stringify(tmaps)); */

        return nOverlapped;
    }

    function calcOverlappedDocsAndGetPts(tlocationData, topenGroupNum, tzoom, initCenter, tShowEventId, tShowPointType, tShowOwnerId, tShowLocation) {
        console.log('calcOverlappedDocsAndGetPts with tzoom = ' + tzoom);
        var tmaps = {};
        var tgroups = [];
        var siteDocMaps = props.siteDocMaps;
        if (siteDocMaps && siteDocMaps.length > 0) {
            siteDocMaps.forEach((map) => {
                // already in group?
                if (typeof tmaps[map.id] === 'undefined') {
                    // got a valid lat long?
                    if (map.lat && map.long) {
                        var lat = parseFloat(map.lat);
                        var long = parseFloat(map.long);
                        if (!isNaN(lat) && !isNaN(long) && map.filename) {
                            checkForOverlap(lat, long, map, tmaps, tgroups, tzoom);
                        }
                    }
                }
            });
        }
        console.log('final maps = ' + JSON.stringify(tmaps));
        // setOverlappedDocGroupMaps(tmaps);
        // setOverLappedGroups(tgroups);
        getLocationPts(tlocationData, showEventId, tgroups, tmaps, topenGroupNum, initCenter, tShowEventId, tShowPointType, tShowOwnerId, tShowLocation);

    }

    function onMapGroupClick(filename) {
        var siteDocMaps = props.siteDocMaps;
        if (siteDocMaps && siteDocMaps.length > 0) {
            console.log('siteDOcMaps length = ' + siteDocMaps.length);
            siteDocMaps.forEach((map) => {
                if (map.filename === filename) {
                    var pointType = 'photo';
                    if (map.filename.toLowerCase().indexOf('docx') > 0) {
                        pointType = 'note';
                    } else if (map.filename.toLowerCase().indexOf('mov') > 0) {
                        pointType = 'video';
                    }

                    mapClick(null,map.doc_id,pointType, map.creator_id, showLocation);
                }
            });
        }
    }

    function onMapGroupOpen(group) {
        console.log('open mapgroup num = ' + group['num']);
        // this is callback - need to get latest value of zoom
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
        calcOverlappedDocsAndGetPts(wispData.locationData, group['num'], tzoom, false, showEventId, showPointType, showOwnerId, showLocation);
        setOpenGroupNum(group['num']);
    }

    function onMapGroupClose() {
        console.log('close mapgroups');
        var tzoom;
        setZoom(zoom => {tzoom = zoom; return (zoom)});
        calcOverlappedDocsAndGetPts(wispData.locationData, -1, tzoom, false, showEventId, showPointType, showOwnerId, showLocation);
        setOpenGroupNum(-1);
    }

    // this gets all TrackPoints that we put on the map - see TrackPoints at top of this file
    function getLocationPts(locationData, curId, toverLappedGroups, toverlappedDocGroupMaps, topenGroupNum, initCenter, tShowEventId, tShowPointType, tShowOwnerId) {
        console.log('getLocationPts openGroupNum = ' + topenGroupNum);
        var parser = new DOMParser();
        var locPts = [];
        var posTracker = [];
        var lastLat;
        var lastLong;
        if (props.tracks && locationData) {     // caller wants location tracks shown
            var userCount = 0;
            var minLat = 100000;
            var minLong = 100000;
            var maxLat = -100000;
            var maxLong = -100000;
            locationData.forEach((location) => {
                userCount++;
                var xmlDoc = parser.parseFromString(location.data, "text/xml");
                var kmlEls = xmlDoc.getElementsByTagName("kml");

                // get the lastLat & long for this user
                if (kmlEls.length > 0) {
                    var docEls = kmlEls[0].getElementsByTagName("Document");
                    if (docEls.length > 0) {
                        var folderEls = docEls[0].getElementsByTagName("Folder");
                        if (folderEls.length > 0) {
                            var placeMarkEls = folderEls[0].getElementsByTagName("ns0:Placemark");
                            for (var pi = 0; pi < placeMarkEls.length; pi++) {
                                var mark = placeMarkEls[pi];
                                var ts = mark.getElementsByTagName("ns0:TimeStamp");
                                if (ts && ts.length > 0 && timeInRange(ts)) {
                                    var point = mark.getElementsByTagName("ns0:Point");
                                    if (point && point.length > 0) {
                                        var coordinates = point[0].getElementsByTagName("ns0:coordinates");
                                        if (coordinates && coordinates.length > 0) {
                                            var id = coordinates[0].innerHTML + pi;
                                            var ti = id.indexOf(',');
                                            var ti2 = id.indexOf(',', ti + 1);
                                            if (ti > 0 && ti2 > ti) {
                                                var long = id.substring(0, ti);
                                                var lat = id.substring(ti + 1, ti2);
                                                lastLat = parseFloat(lat);
                                                lastLong = parseFloat(long);
                                                if (lastLat < minLat) {
                                                    minLat = lastLat;
                                                }
                                                if (lastLat > maxLat) {
                                                    maxLat = lastLat;
                                                }
                                                if (lastLong < minLong) {
                                                    minLong = lastLong;
                                                }
                                                if (lastLong > maxLong) {
                                                    maxLong = lastLong;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                if (minLat !== -100000 && minLong !== -100000) {
                    var dLatMeters = Math.abs((maxLat - minLat) * 111139);
                    var dLongMeters = Math.abs((maxLong - minLong) * 111139);  // guess there's 111,139 meters per degree of latitude and longitude
                    // how many pixels is the map vertically?
                    var h = $("#posMapDiv").height();
                    // start zoomed in (12) and zoom out until all lat points fit on map
                    var zoom = 12;
                    var fits = false;
                    while (!fits && zoom > 1) {
                        var meters_per_pixel = 156543.03392 * Math.cos(center[0] * Math.PI / 180) / Math.pow(2, zoom);
                        var mapRadius = meters_per_pixel * h;
                        if (dLatMeters < mapRadius) {
                            fits = true;
                        } else {
                            zoom--;
                        }
                    }
                    setDefaultZoom(zoom);
                    lastLong = minLong + (maxLong - minLong)/2;
                    lastLat = minLat + (maxLat - minLat)/2;
                }

                // add the map TripPoints
                if (kmlEls.length > 0) {
                    var docEls = kmlEls[0].getElementsByTagName("Document");
                    if (docEls.length > 0) {
                        var folderEls = docEls[0].getElementsByTagName("Folder");
                        if (folderEls.length > 0) {
                            var placeMarkEls = folderEls[0].getElementsByTagName("ns0:Placemark");
                            for (var pi = 0; pi < placeMarkEls.length; pi++) {
                                var mark = placeMarkEls[pi];
                                var ts = mark.getElementsByTagName("ns0:TimeStamp");
                                if (ts && ts.length > 0 && timeInRange(ts)) {
                                    var point = mark.getElementsByTagName("ns0:Point");
                                    if (point && point.length > 0) {
                                        var coordinates = point[0].getElementsByTagName("ns0:coordinates");
                                        if (coordinates && coordinates.length > 0) {
                                            var coordTxt = coordinates[0].innerHTML;
                                            var id = coordTxt + pi;     // this seems like a bogus id but it's used in TrackPopup and perhaps elsewhere
                                            var keyId = id + userCount;
                                            // extract lat long from coordinates
                                            var ti = coordTxt.indexOf(',');
                                            var ti2 = coordTxt.indexOf(',', ti + 1);
                                            if (ti > 0 && ti2 > ti) {
                                                var long = coordTxt.substring(0, ti);
                                                var lat = coordTxt.substring(ti + 1, ti2);
                                                var dLat = parseFloat(lat);
                                                var dLong = parseFloat(long);
                                                var markerColor = markerColors[userCount-1];
                                                posTracker.push({ lat: dLat, long: dLong });
                                                var pointType = 'track';
                                                // if this is the last point and it's < day old then show user icon instead of map pos icon
                                                if (dLat === lastLat && dLong === lastLong) { //} && freshTime(ts)) {
                                                    pointType = 'user';
                                                }

                                                var popup = null;
                                                if (tShowPointType === 'track' && tShowEventId === id) {
                                                    console.log('launching a popup for track ' + id)
                                                    popup = (
                                                        <TrackPopup closeFN={closePopup} id={tShowEventId} location={location} />
                                                    );
                                                }

                                                locPts.push(
                                                    <TripPoint
                                                        location={location}
                                                        lat={dLat}
                                                        lng={dLong}
                                                        text=''
                                                        desc=''
                                                        eventId={id}
                                                        pointType={pointType}
                                                        key={keyId}
                                                        url=''
                                                        centerFN={centerFN}
                                                        showFN={mapClick}
                                                        show={showEventId === id}
                                                        closeFN={closePopup}
                                                        markerColor={markerColor}
                                                        user={props.user}
                                                        wispData={wispData}
                                                        ownerId={location.user_id}
                                                        popup={popup}
                                                    />
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            });
        }

        // do this last so photos, etc will be on top of tracks
        // only set last lat long for centering map if there's no tracks
        // adding the ability to center on a geotagged element
        var setLatLong = true;
        if (lastLat && props.centerMapId === null) {
            setLatLong = false;
        }
        // console.log('getLocationData: do docs');
        var siteDocMaps = props.siteDocMaps;
        if (siteDocMaps && siteDocMaps.length > 0) {
            // console.log('toverlappedDocGroupMaps = ' + JSON.stringify(toverlappedDocGroupMaps));


            siteDocMaps.forEach((map) => {
                if (map.lat && map.long) {
                // check validity of lat/long
                    var dLat = parseFloat(map.lat);
                    var dLong = parseFloat(map.long);
                    if (!isNaN(dLat) && !isNaN(dLong) && map.filename) {
                        // part of group?
                        if (typeof toverlappedDocGroupMaps[map.id] === 'undefined') {
                            // console.log('map ' + map.id + ' isnt part of group - site id = ' + map.site_id);
                            var id = map.doc_id;
                            var markerColor = 'rgb(218,218,60)';
                            if (setLatLong && (props.centerMapId === null || props.centerMapId === map.id)) {
                                lastLat = dLat;
                                lastLong = dLong;
                            }
                            var pointType = 'photo';
                            if (map.filename.toLowerCase().indexOf('docx') > 0) {
                                pointType = 'note';
                            } else if (map.filename.toLowerCase().indexOf('mov') > 0) {
                                pointType = 'video';
                            }
                            posTracker.push({ lat: dLat, long: dLong });
                            // dLong = preventOverlap(dLat, dLong, posTracker);

                            var popup = null;
                            if (tShowEventId && tShowPointType !== 'track') {
                                if (tShowEventId === id) {
                                    console.log('MapPopup docId = ' + tShowEventId + ' and sShowPointType = ' + tShowPointType);
                                    // don't think MapPopup is the right thing here
                                    popup = (
                                        <MapPopup closeFN={closePopup} docId={tShowEventId} siteDocMaps={props.siteDocMaps} type={tShowPointType} ownerId={tShowOwnerId} />
                                    );
                                }
                            }
                        
                            locPts.push(
                                <TripPoint
                                    location={null}
                                    lat={dLat}
                                    lng={dLong}
                                    text=''
                                    desc=''
                                    eventId={id}
                                    pointType={pointType}
                                    key={id}
                                    url=''
                                    centerFN={centerFN}
                                    showFN={mapClick}
                                    show={curId === id}
                                    closeFN={closePopup}
                                    markerColor={markerColor}
                                    user={props.user}
                                    wispData={wispData}
                                    ownerId={map.creator_id}
                                    popup={popup}
                                >
                                </TripPoint>
                            );
                        }
                    }
                }
            });
        }

        // got any doc map groups???
        // console.log('tnumOverlappedGroups = ' + toverLappedGroups.length);
        // console.log('toverlappedDocGroupMaps = ' + JSON.stringify(toverlappedDocGroupMaps));
        if (toverLappedGroups && toverLappedGroups.length > 0) {
            toverLappedGroups.forEach((group) => {
                var numDocs = 0;
                if (siteDocMaps) {
                    siteDocMaps.forEach((map) => {
                        if (toverlappedDocGroupMaps[map.id] === group['num']) {
                            numDocs++;
                        }
                    });
                    // console.log('pushing group with lat = ' + group['lat'] + ' and long = ' + group['long']);
                    var isOpen = group['num'] === topenGroupNum;
                    // console.log('rendering group with num = ' + group['num'] + ' and openGroupNum = ' + topenGroupNum + ' and isOpen = ' + isOpen);
                    locPts.push(
                        <MapGroup 
                            key={group['num']}
                            location={null}
                            lat={group['lat']}
                            lng={group['long']}
                            numDocs={numDocs}
                            groupMaps={toverlappedDocGroupMaps}
                            siteDocMaps={siteDocMaps}
                            group={group}
                            clickFN={onMapGroupClick}
                            openFN={onMapGroupOpen}
                            closeFN={onMapGroupClose}
                            isOpen={isOpen}
                        />
                    );
                }
            });
        }

        // setZoom(9);
        // markerColor = 'rgb(100, 255, 100)';
        var locationPts = wispData.locationPts;
        locationPts[props.site.id] = locPts;
        console.log('setting logpts with length = ' + locPts.length);
        wispData.setLocationPts(locationPts);   // not causing rerender?
        if (lastLat && initCenter) {
            setCenter([parseFloat(lastLat), parseFloat(lastLong)]);
        }
    }
    // END GETLOCATIONPTS

    // console.log('rendering position map - fyi zoom = ' + zoom);
    var stl = {};
    var location;
    var locationPts = wispData.locationPts[props.site.id];

    // showOwnerId is used for videos to construct the video path - some old photos don't have ownerId
/*    var popup;
    if (showEventId && showPointType) {
        if (showPointType === 'track') {
            popup = (
                <TrackPopup closeFN={closePopup} id={showEventId} location={showLocation} />
            );
        } else {
            console.log('MapPopup docId = ' + showEventId + ' and showPointType = ' + showPointType);
            popup = (
                <MapPopup closeFN={closePopup} docId={showEventId} siteDocMaps={props.siteDocMaps} type={showPointType} ownerId={showOwnerId} />
            );
        }
    } */

    if (locationPts) {      //  && locationPts.length > 0
        // console.log('render positionmap with locationPts.length = ' + locationPts.length);
        location = (
            <div className={props.mapClass} style={stl}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDkHESKIvvZYnKgWo_n0XffV_L9S013_pY' }}
                    center={center}
                    defaultZoom={defaultZoom}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps, true)}
                    onChange={onMapChange}
                >
                    {locationPts}
                </GoogleMapReact>
            </div>
        );
    } else if (props.user) {
        location = (
            <div className={props.mapClass + ' mapNotReady'}>
                Map: Finding {props.user.first_name}...
            </div>
        );
    }



    return (
        <div id="posMapDiv">
            {location}
        </div>
    );
}

/*

            <MapGroup 
                numDocs={5}
                groupMaps={overlappedDocGroupMaps}
                siteDocMaps={props.siteDocMaps}
                group={null}
                clickFN={onMapGroupClick}
            />
*/