import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { DocManager } from './DocManager';
import { SiteProps } from '../SiteProps';
import { ViewLog } from './ViewLog';
import { SiteAuthorManager } from './SiteAuthorManager';
import {WispButton} from './WispButton';
import {wispHistory} from './WispHistory.js';

// controls to publish a site - this is only used by Crazydoes at the moment (2/17/24)
export const SiteEdit = (props) => {

	const [forceUpdate, setForceUpdate] = React.useState(0);

	var wispData = useContext(WispContext);

	useEffect(() => {


	}, []);

	function goToSite() {
		var userId = wispData.publicState.wispUserId;
		var siteUrl = props.site.url;
		wispHistory.push('/' + userId + '/' + siteUrl);
	}

	return (
        <div>
            <DocManager
                docMaps={wispData.siteDocMaps}
                site={props.site}
                azure_client_id='cbec7cde-9647-4672-90c4-a4554f0f24fa'
                type='Trip'
                label='Content'
                status
                video
                preferMobile
                draggable
                accept='.pdf, .docx, .xlsx, .xlsm, .jpg, .jpeg, .png, .mov'
                showDocUrl
                setPosition={props.setPosition}
            />
            <SiteProps 
                new={props.new} 
                docMaps={wispData.siteDocMaps} 
                site={props.site}
                siteName='trip'
                setAccess={false}
                setDates
                eventType
                setLatLong
                multiTrip={props.multiTrip}
                selectView={props.selectView}
                />

            <SiteAuthorManager site={props.site} state='invited' />
            <ViewLog site={props.site} />
            <div style={{width: '260px', margin: '20px auto'}}>
                <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="260px"
                        js={goToSite}
                        m='0px'
                        mes= {"Done"} />
            </div>
        </div>
    );


}

