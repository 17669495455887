import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { WispButton } from '../components/WispButton';
import $ from 'jquery';

export const CaptionPopup = (props) => {

    const [creatingNote, setCreatingNote] = React.useState(false);

    function noteChange() {
    }

    function submitNote() {
        var caption = $("#captionArea").val();
        console.log("caption = " + caption);
        props.doneFN(caption);
    }
    var img;
    if (props.image) {
        img = (
            <img src={'data:image/png;base64,'+props.image.base64String} />
        );
    }

    return (
        <React.Fragment>
            <div id='supp_div'>
                <div className="close_float_caption" onClick={props.closeSupp}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div>
                    {img}
                </div>
                <div className='noteTitle'>Caption (optional)</div>
                <div className='noteSubContainer'>
                    <div className='noteAreaDiv'>
                        <textarea id='captionArea' onChange={noteChange} />
                    </div>
                </div>
                <div style={{marginTop: '10px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="260px"
                        js={submitNote}
                        m='0px'
                        mes='Post' />
                </div>
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );
}

