
import React, {useContext, useEffect} from 'react';
import {WispContext} from '../WispProvider';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {WispNotification} from '../components/WispNotification';
import {getDateStr} from '../util/base.js';

export const TrackPopup = (props) => {

    var wispData = useContext(WispContext);
    const [theMark, setTheMark] = React.useState(null);

	useEffect(() => {
        // find the point from wispData.locationData and props.id
        console.log('useEffect for TrackPopup' + props.id);
        var parser = new DOMParser();
        if (props.location) {
            var xmlDoc = parser.parseFromString(props.location.data,"text/xml");
            var kmlEls = xmlDoc.getElementsByTagName("kml");
            if (kmlEls && kmlEls.length > 0) {
                var docEls = kmlEls[0].getElementsByTagName("Document");
                if (docEls.length > 0) {
                    var folderEls = docEls[0].getElementsByTagName("Folder");
                    if (folderEls.length > 0) {
                        var placeMarkEls = folderEls[0].getElementsByTagName("ns0:Placemark");
                        for (var pi = 0; pi < placeMarkEls.length;pi++) {
                            var mark = placeMarkEls[pi];
                            var ts = mark.getElementsByTagName("ns0:TimeStamp");
                            if (ts && ts.length > 0) {
                                var point = mark.getElementsByTagName("ns0:Point");
                                if (point && point.length > 0) {
                                    var coordinates = point[0].getElementsByTagName("ns0:coordinates");
                                    if (coordinates && coordinates.length > 0) {
                                        var id = coordinates[0].innerHTML + pi;
                                        if (id === props.id) {
                                            setTheMark(mark);
                                            console.log('mark = ' + JSON.stringify(mark));
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}, [props.id]);

    var divTop = '0px';
    if (window.cordova && wispData.safeArea) {
        divTop = wispData.safeArea.top + 'px';
        console.log('divTop = ' + divTop);
    }

    var content;
    if (theMark) {
        var dateStr;
        var ts = theMark.getElementsByTagName("ns0:TimeStamp");
        if (ts && ts.length > 0) {
            var when = ts[0].getElementsByTagName("ns0:when");
            if (when && when.length > 0) {
                var val = when[0].innerHTML;
                var d = new Date(val);
                dateStr = getDateStr(d,false);
            }
        }
        var altitude = "Unknown";
        var velocity = "Unknown";
        var course = "Unknown";
        var ed = theMark.getElementsByTagName("ns0:ExtendedData");
        if (ed && ed.length > 0) {
            var datas = ed[0].getElementsByTagName("ns0:Data");
            if (datas && datas.length > 0) {
                for (var pi = 0; pi < datas.length;pi++) {
                    var data = datas[pi];
                    var attr = data.getAttribute("name");
                    if (attr && attr === 'Elevation') {
                        var valEl = data.getElementsByTagName("ns0:value");
                        if (valEl && valEl.length > 0) {
                            altitude = valEl[0].innerHTML;
                        }
                    } else if (attr && attr === 'Velocity') {
                        var valEl = data.getElementsByTagName("ns0:value");
                        if (valEl && valEl.length > 0) {
                            velocity = valEl[0].innerHTML;
                            var i = velocity.indexOf(' ');
                            if (i > 0) {
                                var s = parseFloat(velocity.substring(0,i)) / 1.6;
                                velocity = s.toFixed(2) + ' mph';
                            }
                        }
                    } else if (attr && attr === 'Course') {
                        var valEl = data.getElementsByTagName("ns0:value");
                        if (valEl && valEl.length > 0) {
                            course = valEl[0].innerHTML;
                            var i = course.indexOf(' ');
                            if (i > 0) {
                                course = course.substring(0,i+1) + 'degrees';
                            }
                        }
                    }
                }
            }
        }

        content = (
            <div>
                <div className='acctRow'>
                    <div className='trackCol1'>Date:</div>
                    <div className='trackCol2'>{dateStr}</div>
                </div>
                <div className='acctRow'>
                    <div className='trackCol1'>Altitude:</div>
                    <div className='trackCol2'>{altitude}</div>
                </div>
                <div className='acctRow'>
                    <div className='trackCol1'>Velocity:</div>
                    <div className='trackCol2'>{velocity}</div>
                </div>
                <div className='acctRow'>
                    <div className='trackCol1'>Direction:</div>
                    <div className='trackCol2'>{course}</div>
                </div>
            </div>
        );
    }

    return (
        <div className='track_popup_div' onClick={props.closeFN} >
            <div className="close_pos_float_30_2" onClick={props.closeFN} style={{paddingBottom: '10px'}}>
                <div id="fullscreen_close" className="close">
                    <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                </div>
            </div>
            {content}
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
