import React, {useCallback, useContext, useEffect} from 'react';
import { CaptionPopup } from '../popups/CaptionPopup';
import { WispIconButton } from './WispIconButton';
import { WispNotification } from './WispNotification';
import { WispContext } from '../WispProvider';
import { Uploader } from './Uploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faImage, faShareSquare, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import {_base64ToArrayBuffer, _base64ToUint8Array, getDateStr} from '../util/base.js';
import { getVidMetaData, takePicture } from '../util/document.js';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { Geolocation } from '@capacitor/geolocation';
import { useDropzone } from 'react-dropzone';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import $ from 'jquery';

export const AuthorPanel = (props) => {

	const [alertDocMessage, setAlertDocMessage] = React.useState('');
    const [caption, setCaption] = React.useState('');
	const [creatingNote, setCreatingNote] = React.useState(false);
	const [gettingVideo, setGettingVideo] = React.useState(false);
	const [note, setNote] = React.useState('');
	const [takingPicture, setTakingPicture] = React.useState(false);
	const [curFileNum, setCurFileNum] = React.useState(1);
    const [uploadFiles, setUploadFiles] = React.useState([]);
    const [uploadingBytes, setUploadingBytes] = React.useState(null);
    const [uploadingName, setUploadingName] = React.useState('');
    const [uploadCount, setUploadCount] = React.useState(0);
    const [alt, setAlt] = React.useState(null);
    const [lat, setLat] = React.useState(null);
    const [long, setLong] = React.useState(null);
    const [dateTime, setDateTime] = React.useState(null);
    const [uploadMode, setUploadMode] = React.useState(null);
    const [pictureData, setPictureData] = React.useState(null);
    const [showCaption, setShowCaption] = React.useState(false);

    var wispData = useContext(WispContext);
	// var realTimeUploadCount = 0;		// can't count on uploadCont being up to date - gonna lag as crappy state stuff does... no, turns out the problem is callbacks have old data

	useEffect(() => {
        return () => { console.log('AUTHORPANEL UNMOUNTED'); };
	}, []);

    // ONEDRIVE CODE
	function authOneDrive(prefix) {
		var redirectURI = process.env.REACT_APP_AZURE_REDIRECT_URI;
		window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
			'client_id=' + props.azure_client_id +
			'&response_type=code' +
			'&redirect_uri=' + redirectURI +
			'&response_mode=query' +
			'&scope=offline_access%20user.read%20files.read' +
			'&state=' + prefix + props.site.url
	}
    // END ONEDRIVE CODE 

    // FILE UPLOAD CODE
	function MyDropzone() {
		const onDrop = useCallback(acceptedFiles => {
		  // Do something with the files
		    acceptedFiles.forEach((file) => {
                //uploadFile(file);
                var tuploadFiles = uploadFiles;
                tuploadFiles.push(file);
                setUploadFiles(tuploadFiles);
                setUploadMode("files");
		    });
            if (curFileNum === 1) {
                uploadFile(curFileNum);
            }

		}, [])
		const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: props.accept}) // .mov, .mp4,
	  
		// , border: 'dashed', background: '#F0F0F0', padding: '5px'
        var busy = false;
        if (uploadingBytes>0) {
            busy = true;
        }
		return (
			<div {...getRootProps()}>
                <input {...getInputProps()} />
                <WispIconButton clickFN={doNothing} icon={faFile} id='filePickBut' busy={busy} />
			</div>
		)
	}
    function doNothing() {
    }

	function uploadFile(tCurFileNum) {        
		console.log('uploadFile');
        
        // get current values for curFileNum and uploadFiles
        var tUploadFiles;
        setUploadFiles(prevVal => {tUploadFiles = prevVal; return (prevVal)});

        var file = tUploadFiles[tCurFileNum-1];
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onloadend = function (evt) {
            if (evt.target.readyState == FileReader.DONE) {
                var arrayBuffer = evt.target.result
                setUploadingName(file.name);    // need to put this first or we don't get name?
                setUploadingBytes(arrayBuffer);
            }
        }
	}
    // END FILE UPLOAD CODE

    // MOBILE VIDEO PICKER CODE
    function addVideo() {
        if (window.cordova) {
             
            var cameraOptions = {
                destinationType: 1,		//Camera.DestinationType.FILE_URI,
                sourceType: 2, 			// 0 - Camera.PictureSourceType.PHOTOLIBRARY,
                mediaType: 1,			// Camera.MediaType.VIDEO,
                targetWidth: 1000,
                targetHeight: 800,
            };
            setGettingVideo(true);

            // put it on timeout otherwise video button doesn't rerender for some reason
            setTimeout(function () { 
                navigator.camera.getPicture(cameraSuccess, cameraError, cameraOptions);
            }, 50);
            
            /* var options = {

            };
            navigator.device.capture.captureVideo(
                captureSuccess, captureError, options
            ); */
        }
    }

    const cameraSuccess = async (videoUri, arg2) => {
		console.log('videoUri = ' + videoUri);
		console.log('arg2 = ' + arg2);
        const contents = await Filesystem.readFile({
            path: videoUri,
        });
        var vidBytes = _base64ToUint8Array(contents.data);  // vidBytes is an ArrayBuffer
        console.log('vidBytes length = ' + vidBytes.byteLength);
        getVidMetaData(vidBytes, videoUri, cameraSuccess2);
    }

    function cameraSuccess2(vidBytes, videoUri, alt, lat, long, date_time) {
        // prep video upload data
        setUploadMode("video");
        setAlt(alt);
        setLat(lat);
        setLong(long);
        setDateTime(date_time);
        setUploadingBytes(vidBytes);
        setUploadingName(''); // make sure this is null - we'll set it once we get the caption which will trigger the uploader (it needs the bytes and name)
        // give the option of entering a caption, when it's done, doneCaption will be called
        setShowCaption(true);
    }

    function cameraError() {
		setGettingVideo(false);		
        setUploadMode(null);
	}
    // END VIDEO CODE

    // MOBILE PHOTO PICKER CODE
    function preTakePicture() {
        // get a picture from the phone
		takePicture(wispData, props.site, takePicture2);		
	}

    function takePicture2(success, wispData, site, date_time, headers, image) {
        // ok, got the picture, now display it and get an optional caption
        if (success) {
            // setup photo upload data
            setPictureData({
                date_time: date_time,
                headers: headers,
                image: image
            });

            // give the option of entering a caption, when it's done, doneCaption will be called
            setUploadingName(''); // make sure this is null - we'll set it once we get the caption which will trigger the uploader (it needs the bytes and name)
            setShowCaption(true);
            setUploadMode("photo");
        }
    }
    // END PHOTO UPLOAD CODE

    // TEXT POST CODE
    function noteChange() {
        setNote($("#authNoteArea").val());
    }
    const submitNote = async () => {
        // const hasPermission = await Permissions.query({ name: 'geolocation' });
        // console.log('permissions = ' + hasPermission);
        var lat, long;
        if (window.cordova) {
            try {
                const permissions = await Geolocation.requestPermissions();
                console.log('permissions = ' + JSON.stringify(permissions));
                const coordinates = await Geolocation.getCurrentPosition();
                if (coordinates) {
                    console.log('coordinates = ' + JSON.stringify(coordinates));
                    var coords = coordinates.coords;
                    if (coords && coords.latitude && coords.longitude) {
                        lat = coords.latitude;
                        long = coords.longitude;
                    }
                }
            } catch(err) {
                console.log('error = ' + err);
            }
        }

        if (lat && long) {
            doAddNote(note, lat, long, null, props.addFN);
        } else {
            // setAlertDocMessage("Couldn't get current location");
            doAddNote(note, null, null, null, props.addFN);
        }
    }
    function doAddNote(noteText, lat, long, linkDocId, doneAddNoteFN) {
        console.log('doAddNote');
        var date = new Date();
        var filename = props.site.name + ' ' + getDateStr(date, false) + ".docx";
        var token = wispData.token;
        var boxId = '';
        var pages = wispData.sitePages[props.site.id];
        if (pages) {
            var page = pages[0];
            var box = page.boxes[0];
            boxId = box.id;
        }
        var sectPr = "<w:sectPr><w:pgSz w:w ='12240' w:h='15840' w:orient='portrait'/>";
        sectPr += "<w:pgMar w:top='0' w:right='0' w:bottom='0' w:left='0' w:header='0' w:footer='0' w:gutter='0' />";
        sectPr += "<w:cols w:space='720' />";
        sectPr += "<w:docGrid w:linePitch='360' />";
        sectPr += "</w:sectPr>";
        console.log('adding note = ' + noteText + ' with lat,lng = ' + lat + ',' + long);
        // normalize, decodeURI, etc not working when trying to change unicode ' (8217) to '
        // this doesn't work either...         note2 = decodeURIComponent(JSON.parse('"' + note2.replace(/\"/g, '\\"') + '"'));
        // they all leave 8217 as is.
        var note2 = noteText;
        for (var i = 0;i < note2.length;i++) {
            // console.log("charCodeAt = " + note2.charCodeAt(i));
            if (note2.charCodeAt(i) === 8217 || note2.charCodeAt(i) === 8216) {
                note2 = note2.substring(0,i) + '\'' + note2.substring(i+1);
            } else if (note2.charCodeAt(i) === 8220 || note2.charCodeAt(i) === 8221) {
                note2 = note2.substring(0,i) + '\"' + note2.substring(i+1);
            } else if (note2.charCodeAt(i) === 8230) {
                note2 = note2.substring(0,i) + '...' + note2.substring(i+1);
            }
        }
        /* for (var i = 0;i < note2.length;i++) {
            alert(note2.charCodeAt(i));
        }
        return; */
        var body = "<w:document><w:body>";
        var done = false;
        var ti = 0;
        while (!done) {
            var i = note2.indexOf('\n', ti);
            body += "<w:p><w:r><w:t>";
            if (i === -1) {
                done = true;
                body += note2.substring(ti);
            } else {
                body += note2.substring(ti, i);
                ti = i + 1;
            }
            body += "</w:t></w:r></w:p>";
		}
        body += '</w:body><w:/document>'
        const formData = new FormData();
        formData.append('file', body);

        var linkId = '';
        if (linkDocId) {
            linkId = linkDocId;
        }

        var headers = {
            'Authorization': 'Bearer ' + token,
            'app-id':  process.env.REACT_APP_ID,
            'user-id': wispData.publicState.wispUser.id,
            'box-id': boxId,
            'link-doc-id': linkId,
            'position': 'first',
            'source': 'inline',
            'filename': filename,
            'lat': lat,
            'long': long,
            'site-url': props.site.url,
        }
        var url = wisp_urls.document_create;
        console.log('url = ' + url);
        setCreatingNote(true);

        fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: formData })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                setCreatingNote(false);
                console.log('success = ' + data);
                if ('detail' in data) {
                    if (data['detail'] === 'exceeded storage') {
                        setAlertDocMessage("You've exceeded your 1 GB storage limit");
                    } else {
                        setAlertDocMessage("Failed to add the document(s) to this " + props.type);
                    }
                } else {
                    $("#authNoteArea").val('');
                    setNote(null);
                    doneAddNoteFN();
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setCreatingNote(false);
            });
    }
    // END TEXT POST CODE

    // CAPTION CODE
    function doneCaption(caption) {
        setCaption(caption);
        console.log('setting caption = ' + caption);
        setShowCaption(false);  // hide the caption popup

        if (uploadMode === 'photo') {
            // submit image first
            var date_time = pictureData.date_time;
            var image = pictureData.image;
            var headers = pictureData.headers;
            
            var imgBytes = _base64ToArrayBuffer(image.base64String);

            setAlt(headers.alt);
            setLat(headers.lat);
            setLong(headers.long);
            setDateTime(date_time);
            // this will cause the uploader component to be rendered
            setUploadingBytes(imgBytes);
            var filename = 'camera_' + date_time + '.' + image.format;
            console.log('filename = ' + filename);    
            setUploadingName(filename);
            // this will set the busy spinner on the image icon
            setTakingPicture(true);
        } else if (uploadMode === 'video') {
            var filename = props.site.name + ' ' + getDateStr(dateTime, false) + ".mov";
            setUploadingName(filename);    
        }
    }
    function cancelCaption() {
        setUploadingBytes(null);
        setUploadMode(null);
        setGettingVideo(false);
        setTakingPicture(false);
        setPictureData(null);
        setUploadingName('');
        setShowCaption(false);
    }
    // END CAPTION CODE

    // UPLOADER CODE
    function doneUploaderFN(newDoc) {
        console.log('AuthorPanel: doneFN newDoc = ' + JSON.stringify(newDoc));
        setUploadingBytes(null);
        if (uploadMode === 'files') {
            setTimeout(function () { 
                // get current values for curFileNum (and add 1 to it) and uploadFiles
                var tCurFileNum;
                setCurFileNum(prevVal => {tCurFileNum = prevVal; return (prevVal)});
                var tUploadFiles;
                setUploadFiles(prevVal => {tUploadFiles = prevVal; return (prevVal)});
                if (tCurFileNum === tUploadFiles.length) {
                    setUploadMode(null);
                    setCurFileNum(1);
                    setUploadFiles([]);
                    setUploadingBytes(null);
                    setUploadingName('');
                    props.addFN();                
                } else {
                    setCurFileNum(tCurFileNum+1);
                    uploadFile(tCurFileNum+1);
                }
            }, 150);
        } else if(uploadMode === 'video' || uploadMode === 'photo') {
            // get current value of caption (callback react trickery!)
            var tCaption;
            setCaption(caption => {tCaption = caption; return (caption)});
            
            console.log('uploadMMode = ' + uploadMode + ' and caption = ' + tCaption);
            if (tCaption && tCaption.length > 0) {
                doAddNote(tCaption, null, null, newDoc.id, completeUpload);
            } else {
                completeUpload();
            }
        }
    }
    function completeUpload() {
        setUploadingBytes(null);
        setTimeout(function () { 
            // get current values for curFileNum (and add 1 to it) and uploadFiles
            props.addFN();                
        }, 150);    
        setUploadMode(null);
        setGettingVideo(false);
        setTakingPicture(false);
        setPictureData(null);
        setUploadingName('');
    }
    // END UPLOADER CODE

    var submitIcon;
    // if (note && note.length > 0) {  // noteSendIcon
        submitIcon = (
            <div className='authorSubmitButton'>
                <WispIconButton clickFN={submitNote} icon={faShareSquare} id='onedriveBut' busy={creatingNote} />
            </div>
        );
    // }


    var cb = 'siteAuthorBar';
    var locationPts = wispData.locationPts[props.site.id];
    if (locationPts && locationPts.length === 0) {
        cb += ' noMap';
    }

    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = <WispNotification message={alertDocMessage} type='error' />
	}

    var captionDiv;
    // console.log('showCaption = ' + showCaption + ' and pictureData = ' + pictureData);
    if (showCaption) {
        var image;
        if (pictureData) {
            image = pictureData.image;
        }
        captionDiv = (
            <CaptionPopup closeSupp={cancelCaption} doneFN={doneCaption} image={image}/>
        );
    }

	var addIcons;
	if (window.cordova) {
		addIcons = (
            <React.Fragment>
                <div className='authorIconBut'>
                    <WispIconButton clickFN={preTakePicture} icon={faImage} id='pictureBut' busy={takingPicture} />
                </div>
                <div className='authorIconBut'>
                    <WispIconButton className='authorIconBut' clickFN={addVideo} icon={faVideo} id='videoBut' busy={gettingVideo} />
                </div>
            </React.Fragment>
        )
	} else {
		addIcons = (
            <React.Fragment>
                <div className='authorIconBut'>
                    <MyDropzone />
                </div>
                <div className='authorIconBut'>
                    <WispIconButton clickFN={() => authOneDrive('picker_')} icon={faMicrosoft} id='onedriveBut' />
                </div>
            </React.Fragment>
        );
	}

    var buttons = addIcons;
    var tClass = 'noteAreaSmall';
    var submitButton = submitIcon;
    if (note && note.length > 0) {
//        submitButton = submitIcon;
        tClass = 'noteAreaBig';
    } else {
        tClass = 'noteAreaSmall';
    }

    var uploader;
    var textArea;
    if (uploadingBytes && uploadingName.length > 0) {
        console.log('uploadingBytes len = ' + uploadingBytes.byteLength + ' and name = ' + uploadingName);
        uploader = (
            <Uploader bytes={uploadingBytes} site={props.site} name={uploadingName} doneFN={doneUploaderFN} alt={alt} lat={lat} long={long} date_time={dateTime} />
        );
        addIcons = null;
    } else {
        textArea = (
            <div className='authNoteAreaDiv'>
                <textarea id='authNoteArea' className={tClass} onChange={noteChange} />
            </div>    
        );
    }



    var postControls = (
        <React.Fragment>        
            {buttons}
            {uploader}
        </React.Fragment>
    );
    var postMobile;
    var postDesktop;
    if (wispData.mobileView) {
        postMobile = (
            <div style={{display: 'flex'}}>
                {postControls}
            </div>
        );        
    } else {
        postDesktop = postControls;
    }


    return (
        <React.Fragment>
            <div key='togglediv' className={cb}>
                <div className='authorTitleDiv'>
                    Post Content to {props.site.name}
                </div>
                <div className='noteContainer'>
                    {textArea}
                    {submitButton}
                    {postDesktop}
                </div>
                {postMobile}
            </div>
            {alertDiv}
            {captionDiv}
        </React.Fragment>
    );
}

    /* function encodeXml(s) {
        return (s
            .replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&apos;')
            .replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(/\t/g, '&#x9;').replace(/\n/g, '&#xA;').replace(/\r/g, '&#xD;')
        );
    } */

