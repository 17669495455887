import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { fetchGet, getWispUrl, wisp_urls } from '../util/fetch.js';
import { Document, Page, pdfjs } from 'react-pdf';

export const WispDoc = (props) => {
    var wispData = useContext(WispContext);

	useEffect(() => {
	}, []);

	var url = getWispUrl(wisp_urls.document_get, props.value.doc_id) + '?format=native&view=mobile';
	var pages=[];
	for(var i = 0; i < props.numPages; i++) {
		pages.push(
			<Page key={i+1} pageNumber={i+1} />
		);
	}

	var file = {
		url: url,
		httpHeaders: {'Authorization': 'Bearer ' + wispData.token, 'user-id': wispData.publicState.wispUserId},
	};

    return (
		<div className={'wispPageContainer wispBlog'} >
			{props.head}
			<Document file={file} onLoadSuccess={props.loadFN}>
				{pages}
			</Document>
		</div>
    );
}

