import React, {useContext, useEffect} from 'react';
import { Footer } from './components/Footer';
import { HTMLRenderer } from './components/HTMLRenderer';

export const TermsView = (props) => {
//    <iframe className='faqDocDiv' src="https://webtotality-my.sharepoint.com/personal/jefft_webtotality_com/_layouts/15/Doc.aspx?sourcedoc={216f87c4-bc57-4cd5-8e67-8151e2fbbbd9}&amp;action=embedview" width="100%" height="100%" frameBorder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> document, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>

    return (
        <div className='termsContainer'>
			<HTMLRenderer
                url='https://api.crazydoes.com/document/5768387913318400?style=classic'
            />
            <Footer mobileApp/>
        </div>
    );
}