import React, { useContext, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import {WispContext} from './WispProvider';
import {getDateStr, idsMatch, isAuthenticated} from './util/base.js';
import {fileType} from './util/document.js';
import { NextLink } from './components/NextLink.js';
import { PrevLink } from './components/PrevLink.js';
import {WispDoc} from './components/WispDoc.js';
import {wispHistory} from './components/WispHistory.js';
import { fetchGet, getWispUrl, loadData, wisp_urls } from './util/fetch.js';
// import videojs from 'video.js';
import ReactPlayer from 'react-player';
import $ from 'jquery';

// this is a page on a site, which contains boxes, which contain one or more documents. Documents may have multiple "document pages" of course
export const DocView = (props) => {
	/* var videoRefs = [];
	// can't call useRef in loop
	videoRefs[0] = useRef();
	videoRefs[1] = useRef();
	videoRefs[2] = useRef();
	videoRefs[3] = useRef();
	videoRefs[4] = useRef();
	videoRefs[5] = useRef();
	videoRefs[6] = useRef();
	videoRefs[7] = useRef();
	videoRefs[8] = useRef();
	videoRefs[9] = useRef();
	videoRefs[10] = useRef(); */

	var forceUpdateCount = 0;
    var wispData = useContext(WispContext);
	const [curMap, setCurMap] = React.useState(null);
	const [curPageId, setCurPageId] = React.useState(0);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
	const [forceUpdate, setForceUpdate] = React.useState(0);
    const [homeUser, setHomeUser] = React.useState(null);
	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [posters, setPosters] = React.useState({});
	const [videoDocRefs, setVideoDocRefs] = React.useState({});

    useEffect(() => {
		if (!docMapsLoaded) {
			var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
			fetchGet(url, getPagesResult, wispData);	// will get one page with all the docBoxMaps, so probably redundant we're calling getDocMaps next
			var url = getWispUrl(wisp_urls.site_docMaps_get, props.site.id);
			fetchGet(url, getDocMapsResult, wispData, false);		// don't forceupdate - in any case, this is getting called twice - gonna try to fix that but still don't see the need to force - this is getting called by DocManager too
			if (props.userId) {
				if (isAuthenticated(wispData) && idsMatch(props.userId, wispData.publicState.wispUserId)) {
					setHomeUser(wispData.publicState.wispUser);
				} else {
					var url = wisp_urls.user_get + props.userId + '/';
					fetchGet(url, getUserResult, wispData);
				}
			}
		} else {
			var data = wispData.siteDocMaps;
			data.forEach((map) => {
				if (map.doc_id === parseInt(props.docId)) {
					setCurMap(map);
					// make sure the content is loaded - DocView isn't used for mov content
					if (typeof content === 'undefined' || typeof content[map.doc_id] === 'undefined') {	
						var url = getWispUrl(wisp_urls.document_get, map.doc_id);
						if (wispData.mobileView) {
							url += "?view=mobile";
						} else {
							url += "?view=desktop";
						}
						url += "&format=html";
						// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
						fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog' }, 'html');
					}
				}
			});

		}

	}, [props.docId]);

	function getPagesResult(success, data) {
        if (success && props && props.site) {
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[props.site.id] = data;
            } else {
                var id = props.site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
            }
        }
	}

    function getUserResult(success, data) {
        if (success) {
            setHomeUser(data);
        }
    }

	function getDocMapsResult(success, data) {
		if (success) {
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);

			var count = 0;
			var deployment = 'prod';
			if (process.env.REACT_APP_TEST === 'true') {
				deployment = 'test';
			}
			var tContent;
			wispData.setDocContent(prevContent => { tContent = prevContent; return (prevContent) });
			var tPosters;
			setPosters(prevValue => { tPosters = prevValue; return (prevValue) });

			var content = wispData.docContent;
			data.forEach((map) => {
				if (map.doc_id === parseInt(props.docId)) {
					setCurMap(map);
					if (fileType(map.filename) === 'mov') {
						var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + map.creator_id + '/' + map.doc_id + '_video/' + map.doc_id + '.0000000.jpg';
						tPosters[map.doc_id] = poster;
						setPosters(tPosters);
						/* var count = Object.keys(videoDocRefs).length;
						videoDocRefs[map.doc_id] = videoRefs[count];
						setVideoDocRefs(videoDocRefs);
						// when a doc is added, a previous mov file will already be loade din videoDocRefs, but have to recreate the player it seems
						setTimeout(function () {
							createPlayer(map.doc_id, "", map.creator_id, deployment, props.userId, 0);
						}, 1000); */
					} else if (typeof content === 'undefined' || typeof content[map.doc_id] === 'undefined') {	
						var url = getWispUrl(wisp_urls.document_get, map.doc_id);
						if (wispData.mobileView) {
							url += "?view=mobile";
						} else {
							url += "?view=desktop";
						}
						url += "&format=html";
						// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
						fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog' }, 'html');
					} else {
						setTimeout(function() {
							$("#" + map.doc_id).html(content[map.doc_id]);
						},25);
					}
				}
			});
		}
	}

	function getDocResults2(success, data, map) {
		if (success) {
			var deployment = 'prod';
			if (process.env.REACT_APP_TEST === 'true') {
				deployment = 'test';
			}
			var tContent;
			wispData.setDocContent(prevContent => { tContent = prevContent; return (prevContent) });
			var tPosters;
			setPosters(prevValue => { tPosters = prevValue; return (prevValue) });
			tContent[map.doc_id] = data;
			wispData.setDocContent(tContent);
			$("#" + map.doc_id).html(data);
			if (fileType(map.filename) === 'docx') {
				setTimeout(function () {
					processVideoNodes(map);
				}, 1000);
			}

			// forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
			// setForceUpdate(forceUpdateCount+1);
		}
	}
	// tried putting this in base.js but got issues with videoDocRefs
	// postfix allows for embedding videos in docx files - i.e. you can have a video on a page with docId=x and the same video embedded in a word document
	/* function createPlayer(docId, postfix, creatorId, deployment, userId, count) {
		// console.log('base videoDocRefs = ' + videoDocRefs);
		var tVideoDocRefs;
		setVideoDocRefs(currentState => { tVideoDocRefs = currentState; return (currentState) });
		if (tVideoDocRefs[docId + postfix] && typeof tVideoDocRefs[docId + postfix].current != 'undefined') {
			if (!isNaN(docId)) {
				console.log('creating player for ' + docId + postfix);
				const videoJsOptions = {
					autoplay: false,
					controls: true,
					fill: true,
					sources: [{
						src: 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '_1.m3u8',
						type: 'application/vnd.apple.mpegurl',
					}]
				}
				var player = videojs(videoDocRefs[docId + postfix].current, videoJsOptions, function onPlayerReady() {
					console.log('onPlayerReady', this)
				});
			}
		} else if (count < 10) {
			// console.log('video element not ready');
			setTimeout(function () {
				createPlayer(docId, postfix, creatorId, deployment, userId, count + 1);
			}, 1000);
		}
	} */

	function processVideoNodes(map) {
		// var dom_nodes = $($.parseHTML(data));
		// var vidnodes = dom_nodes.find(".tengine-video");
		console.log('DocView: processVideoNodes');
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}
		var tPosters;
		setPosters(prevValue => { tPosters = prevValue; return (prevValue) });
		var vidnodes = $(".tengine_video_" + map.doc_id);
		var postfix = 'docx';
		for (var i = 0; i < vidnodes.length; i++) {
			var node = vidnodes[i];
			var html = node.innerHTML;
			// html will be of the format - process.env.REACT_APP_URL + "/" + getCreatorId() + '/' + props.site.url + '/' + props.map.doc_id;
			var ti = html.lastIndexOf('/');
			if (ti > 0) {
				var docId = html.substring(ti + 1);
				html = html.substring(0, ti);
				ti = html.lastIndexOf('/');
				if (ti > 0) {
					var siteUrl = html.substring(ti + 1);
					html = html.substring(0, ti);
					ti = html.lastIndexOf('/');
					if (ti > 0) {
						console.log('processing video with docId = ' + docId + ' and postfix = ' + postfix);
						var creatorId = html.substring(ti + 1);
						var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '.0000000.jpg';
						tPosters[docId] = poster;
						setPosters(tPosters);
						/* var count = Object.keys(videoDocRefs).length;
						videoDocRefs[docId + postfix] = videoRefs[count];
						setVideoDocRefs(videoDocRefs);
						var videoContent = (
							<div className='videoPlayerCont'>
								<div data-vjs-player>
									<video controls poster={posters[docId]} ref={videoDocRefs[docId + postfix]} className="video-js" ></video>
								</div>
							</div>
						);
						ReactDOM.render(videoContent, node);
						// when a doc is added, a previous mov file will already be loade din videoDocRefs, but have to recreate the player it seems
						createPlayer(docId, postfix, creatorId, deployment, props.userId, 0);
						*/
						var turl = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '_1.m3u8';
						var h = '700px';
						if (wispData.mobileView) {
							h = '280px';
						}
						var videoContent = (
							<div className='videoPlayerCont'>
								<ReactPlayer 
									url={turl}
									controls={true}
									width='100%'
									height={h} 
									/>
							</div>
						);
						ReactDOM.render(videoContent, node);


					}
				}
			}
		}
		var i = 0;

	}

	function selPanDoc(map) {
		if (map) {
			wispHistory.push('/' + props.userId + '/' + props.site.url + '/' + map.doc_id);
			window.scrollTo(0, 60);
			console.log('docview scroll');
		}
	}

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function seeAllPosts() {
		wispHistory.push('/' + props.userId + '/' + props.site.url);
	}

	var h = '100%';
	var docContent;
	if (curMap) {
		docContent = wispData.docContent[curMap.doc_id];
		if (fileType(curMap.filename) === 'pdf') {

		}
	}

	var head;
	
	if (homeUser) {
		var dt;
		if (curMap && curMap.date_time.length > 0) {
			dt = new Date(curMap.date_time);
			dt = getDateStr(dt, false);
		}
		head = (
			<div className='postTitleCont'>
				<div className='postItemName'>by {homeUser.first_name + ' ' + homeUser.last_name}</div>
				<div className='postItemDate'>{dt}</div>
			</div>
		);
	}

	var content;
	if (curMap) {
		if (curMap.url != null && curMap.url != '') {
			var url = curMap.url;
			var i = url.lastIndexOf('/');
			if (i > 0) {
				url = 'https://www.youtube.com/embed' + url.substring(i);
			}
			content = (
				<div style={{ marginTop: '80px', width: '100%', height: '100%' }}>
					<iframe width='100%' height='100%' style={{ minHeight: '500px' }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				</div>
			);
		} else if (fileType(curMap.filename) === 'mov') {
			content = (
				<div className='videoPlayerCont'>
					<div data-vjs-player>
						<video controls poster={posters[curMap.doc_id]} ref={videoDocRefs[curMap.doc_id]} className="video-js" ></video>
					</div>
				</div>
			);
		} else if (fileType(curMap.filename) === 'pdf') {
			content = (
				<div id={curMap.doc_id} className='pdfFullView' />
			);
		} else {
			// if (docContent) {	// dangerouslySetInnerHTML={{ __html: docContent }} 
				content = (
					<div id={curMap.doc_id} style={{ height: h, position: 'relative' }} /> 
				);
			// }
/*		} else {
			content = (
				<WispDoc value={curMap} navOpen={false} loadFN={onDocumentLoadSuccess} numPages={numPages} head={head} />
			); */
		}
	}

	var postLinks;
	if (props.showLinks) {
		postLinks = (
			<div className='postLinkContainer crazyLinks'>
				<PrevLink siteDocMaps={wispData.siteDocMaps} curPageId={curPageId} value={curMap} selPanDocFN={selPanDoc} />
				<NextLink siteDocMaps={wispData.siteDocMaps} curPageId={curPageId} value={curMap} selPanDocFN={selPanDoc} />
			</div>
		);
	}

	var c = 'siteViewCont';
	// if (window.cordova) {
	//	c = 'siteViewContMobile';
	// }
	var cls = '';
	if (props.navOpen) {
		cls = 'float';
	}
	var goBack;
	if (props.goBack) {
		goBack = (
			<div className='tripPageLink' onClick={() => seeAllPosts()}>
				&lt; Go back to {props.backMes}
			</div>
		);
	}

	return (
		<div className={c}>
			<div className={'wispPageContainer wispBlog ' + cls}>
				{goBack}
				{head}
				{content}
				{postLinks}
			</div>
		</div>
    );
}
  