import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
// import $ from 'jquery';

// bcls - background color class
// d - inline-block, etc
// w - width of button
// js - onclick javascript
// m - margins
// mes - button message

export const WispIconButton = (props) => {

    function submit(event) {
        if (typeof props.busy === 'undefined' || props.busy === false) {
            props.clickFN(event);
        }
    }

    var spinner;
    if (props.busy) {
        spinner = (
            <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ color: 'white', position: 'absolute', right: '-2px', fontSize: '12px'}} />
        )
    }

    var buttonStyle = {
        display: props.d,
        margin: props.m,
    };

    if (props.width) {
        buttonStyle.width = props.width;
    }

    var id = '';
    if (props.id) {
        id = props.id;
    }
    return (
        <div key={id}
            className='iconButton'
            style={buttonStyle}
            onClick={submit}
            >
            <FontAwesomeIcon className='authorIcon' icon={props.icon}/>
            {props.text}
            {spinner}
        </div>
    );
}

//     return "<div class='wisp_but webt_ui_color_1 " + bcls + "' style='display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onclick='javascript:" + js + "'>" + mes + "</div>";
