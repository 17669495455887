import React, {useContext, useEffect} from 'react';
import Header from './components/Header';
import CreateAccountPopup from './popups/CreateAccountPopup';
import SignInPopup from './popups/SignInPopup';
import {SubscribePopup} from './popups/SubscribePopup';
import {loadData, getWispUrl, wisp_urls} from './util/fetch.js';
import {loadUserSites} from './util/user.js';
import './css/site-3.9.css';
import './css/wisp-doc.css';
import {WispContext} from './WispProvider';
import {wispHistory} from './components/WispHistory';
import $ from 'jquery';
import CrazyLogo from './images/crazydoes_logo.png';
import CrazyIcon from './images/crazy_icon.png';
import { SplashScreen } from '@capacitor/splash-screen';

var loadingUserSites = false;

export const BaseLayout = (props) => {
    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;

    useEffect(() => {

        if (!wispData.checkedToken) {
            var token = wispData.token;
            var userId = publicState.wispUserId;
            if (!wispData.token) {
                token = window.localStorage.getItem("wisp_token");
                if (token) {
                    wispData.setToken(token);
                }
                userId = window.localStorage.getItem("user_id");
                if (userId) {
                    wispData.setWispUserId(userId);
                }
            }

            if (token && token != '' && userId && userId != '') {
                verifyToken(token, userId);
            } else {
                wispData.setCheckedToken(true);
                checkShowSignIn();
            }
        }
        if (publicState.wispUser && !wispData.userSitesLoaded && !loadingUserSites) {
            loadingUserSites = true;
            console.log('baselayout: loadUserSites');
            loadUserSites(wispData, publicState.wispUser.id, publicState.wispUser.id, wispData.token, getSitesResult, false, null);
		}

		wispData.setWindowSize({w: window.innerWidth, h: window.innerHeight})
		if (window.innerWidth <= 800) {
			wispData.setMobileView(true);
        }

        if (!wispData.eventData['challenges']) {
            loadData(wispData, 'challenges', '5076293569740800', gotChallenges);
        }

	}, [publicState.wispUser]);     // signed in user

    function checkShowSignIn() {
      // if not signed in on mobile, show signin
      if (window.cordova) {
        SplashScreen.hide();
        wispData.setShowSignin(true);
      }
    }

    function gotChallenges() {
		// setForceUpdate(forceUpdate+1);
	}

    function verifyToken(token, userId) {
        var url = wisp_urls.user_get + userId + '/';
        console.log('going to check token with url = ' + url + ' and token = ' + token);

        var headers = {};
        headers['user-id'] = userId;
        headers['Authorization'] = 'Bearer ' + token;
            
        fetch(url, {method: 'GET', mode: 'cors', headers})
        .then(function(response) {
            return response.json();
        })
        .then(data => {
            checkTokenResult(true, data);
        })
        .catch((error) => {
            checkTokenResult(false, error);
        });
    }


	function getSitesResult(success, data) {
        if (success && (data.detail !== 'invalid token')) {
            wispData.setUserSitesAuth(data);
            wispData.setUserSitesLoaded(true);
        }
    }

    function checkTokenResult(success, data) {
        if (success) {
            if (data.detail && (data.detail === 'invalid token') || (data.detail === 'Not Found')) {
                wispData.setValidToken(false);
                checkShowSignIn();
            } else if(data['token'] === null) {
                console.log('no token in user');
                wispData.setValidToken(false);
                checkShowSignIn();
            } else {
                wispData.setValidToken(true);
                console.log('setting wispUser - ' + JSON.stringify(data));
                wispData.setWispUser(data);
            }    
        }
        wispData.setCheckedToken(true);
    }

    function merchFN() {
        window.location = 'https://store.crazydoes.com';
    }

    function logoClick() {
        if (window.cordova) {
            wispHistory.push('/' + wispData.publicState.wispUserId);
        } else {
            wispHistory.push('/');
        }
    }

    function eventFN() {
        var pageElement = document.getElementById("crazyPanel2");
        var positionY = pageElement.offsetTop;
        console.log('footer scroll');
        window.scrollTo(0, positionY-80);        
    }

    function promoClick() {
        window.location = 'https://store.crazydoes.com';
    }

    function onSignIn(userId) {

        if (wispData.inviteSite) {
            wispHistory.push('/' + userId + '/' + wispData.inviteSite.url);
            wispData.setInviteSitemap(null);
            wispData.setInviteSite(null);
        } else if (wispData.createAccRedirect != null) {
            // not gonna handle wispData.createAccRedirect at this point
            // if it's set that means the user is trying to follow a user and is signing in - now we know
            // who they are but we need to load their followmaps which will happen in SiteView useEffect
            // no need to redirect
        } else {
            wispHistory.push('/' + userId);
        }

}

    function faqsClick() {
        wispHistory.push('/faqs');
    }

    var subscribePopup;
    if (publicState.showSubscribe) {
        subscribePopup = (
            <SubscribePopup targetName='WispBook'/>
        )
    }
    let createAccountPopup;
    if (wispData.showCreateAccount) {
        createAccountPopup = (
            <CreateAccountPopup title='New Crazydoes account!' note={wispData.createAccNote} onSignInFN={onSignIn} tracking showExisting termsDocId='5768387913318400' />
        );
    }
    // redirect={wispData.createAccRedirect}
    let signInPopup;
    if (wispData.showSignin) {
        signInPopup = (
            <SignInPopup createOption onSignInFN={onSignIn} note={wispData.createAccNote} />
        );
    }

    var layout = (
        <div />
    );

    var logo = CrazyLogo;
    /* if (wispData.mobileView) {
        logo = CrazyIcon;
    } */

    var promo;
    var setEventFN;
    var homeUrl = process.env.REACT_APP_URL + '/';
    var top = '0px';
    if (wispData.safeArea) {    // only do margin for portrait
        // console.log('safe area top = ' + wispData.safeArea.top);
        top = wispData.safeArea.top + 'px';
    }
    // if (window.location.href === homeUrl) {
    //    setEventFN = eventFN;
        /* promo = (
            <div className='promoBar' onClick={promoClick}>New NH48 shirt!</div>
        )
        var top = '30px'; */
    // }

    // merchClick={merchFN}
    if (wispData.checkedToken === true) {
        layout = (
            <React.Fragment>
                {promo}
                <Header title={props.title} limit={15} top={top} logo={logo} logoAlt="Crazydoes" eventFN={setEventFN} logoClickFN={logoClick} faqsClickFN={faqsClick} home='My Adventures' />
                {createAccountPopup}
                {signInPopup}
                {subscribePopup}
                <div className="pageBody" style={{paddingTop: top}}>    {/* need to use padding here - otherwise the 60px margin in the children for header won't work - probably better to put header margin here instead of children */}
                    {props.children}
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
			{layout}

        </React.Fragment>
    );
}
  