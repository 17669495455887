import React, { useContext, useEffect } from 'react';
import {WispButton} from '../components/WispButton';
import {fileType} from '../util/document.js';
import {WispContext} from '../WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export function SharePopup(props) {
	var wispData = useContext(WispContext);

	useEffect(() => {
        var w = $('body').width();
        var w2 = $("#decisionDiv").width();
        var l = w/2 - w2/2;
        $("#decisionDiv").css('left',l + 'px');
        $("#decisionok").focus();
	}, []);

    function getCreatorId() {
        if (props.map.creator_id) {
            return props.map.creator_id;
        }
        return props.user_id;
    }

    function getLinkValue() {
        return process.env.REACT_APP_URL + "/" + getCreatorId() + '/' + props.site_url + '/' + props.map.doc_id;
    }
    function getEmbedValue() {
        var deployment = 'prod';
        if (process.env.REACT_APP_TEST === 'true') {
            deployment = 'test';
        }

        if (props.map) {
            if (fileType(props.map.filename) === 'mov') {
                // here's the sort of link the word renderer will have to create...
                // 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + map.creator_id + '/' + map.doc_id + '_video/' + map.doc_id + '_1.m3u8',
                return 't_video=' + getLinkValue();
            } else {
                // here's the sort of link the word renderer will have to create...
                // http://127.0.0.1:8000/document/5710330491568128/media/image.jpg
                return 't_photo=' + process.env.REACT_APP_WISP_URL + "/document/" + props.map.doc_id + "/media/image.jpg";
            }
        }
        return '';
    }

    function copyLink() {
        navigator.clipboard.writeText(getEmbedValue());
    }

    function copyShareLink() {
        navigator.clipboard.writeText(getLinkValue());
    }

    function closePopup() {
        props.closeFN();
    }

    var linkVal;
    linkVal = (
        <div className='shareLinkCont'>
            {getLinkValue()}
        </div>
    );
    var embedVal = (
        <div className='shareLinkCont'>
            {getEmbedValue()}
        </div>
    );
    var shareAction = (
        <WispButton
            bcls='primaryButton'
            d='inline-block'
            width="100px"
            js={copyShareLink}
            m='0px'
            mes="Copy"
        />
    );
    var action = (
        <WispButton
            bcls='primaryButton'
            d='inline-block'
            width="100px"
            js={copyLink}
            m='0px'
            mes="Copy"
        />
    );

    var embedEls;
    if (props.embed) {
        embedEls = (
            <div className='shareDiv'>
                <div className='sharePanel'>
                    <div className='referralHint'>Add to Microsoft Word Document</div>
                    <div className='referralLinkDiv'>
                        {embedVal}
                        <div className='referralAction'>
                            {action}
                        </div>
                    </div>
                </div>
            </div>
        );
	}

    return (
        <React.Fragment>
            <div className='sharePopup'>
                <div className="close_pos_float_signin" onClick={closePopup}>
                    <div id="fullscreen_close" className="close">
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                <div className='feature_pan_title' style={{marginBottom:'50px'}}>Share {props.map.filename}</div>
                <div className='shareDiv'>
                    <div className='sharePanel'>
                        <div className='referralHint'>Share Link</div>
                        <div className='referralLinkDiv'>
                            {linkVal}
                            <div className='referralAction'>
                                {shareAction}
                            </div>
                        </div>
                    </div>
                </div>
                {embedEls}
            </div>
            <div className='fullscreen_back'></div>
        </React.Fragment>
    );

}
