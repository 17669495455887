import React, {useContext, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {WispContext} from './WispProvider';
import {TripChallenge} from './TripChallenge';
import {WispButton} from './components/WispButton';
import {WispNotification} from './components/WispNotification';
import {getDateStr} from './util/base.js';
import {wisp_urls} from './util/fetch.js';
import {getSiteFromName} from './util/site.js';
import {loadUserSites} from './util/user.js';
import {wispHistory} from './components/WispHistory.js';
import $ from 'jquery';

// props.user is the user object associated with this dashboard
export const NewTripView = (props) => {

	var forceUpdateCount = 0;
	var newUrl = '';

    var wispData = useContext(WispContext);
	const [forceUpdate, setForceUpdate] = React.useState(0);
    const [createBusy, setCreateBusy] = React.useState(false);
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);
    const [init, setInit] = React.useState(false);

    useEffect(() => {
        wispData.setAlertMessage('');
        wispData.setChallengeItem(null);
        wispData.setTripLat(null);
        wispData.setTripLong(null);
        setInit(true);
    }, []);

    /* function doneFN() {
        setCatBusy(false);
        setForceUpdate(forceUpdate+1);
    } */


    function createTrip() {
        console.log('init = ' + init);
        if (!startDate || !endDate) {
            wispData.setAlertMessage('Please choose a start and end date for your adventure');
            return;
        }
        // chose challenge type?
        if (!wispData.challengeItem) {
            wispData.setAlertMessage('Please choose a challenge type for your adventure');
            return;
        }
        if (wispData.challengeItem.name !== 'No') {
            // selected challenge type but no challenge? 
            if (!wispData.tripChallenge) {
                wispData.setAlertMessage('Please choose a challenge for your adventure');
                return;
            }
            // selected multiloc challenge but no location?
            if (wispData.multiLocs && !wispData.motorChallengeLoc) {
                wispData.setAlertMessage('Please choose a challenge location for your adventure');
                return;
            }

        } else {
            // specified a trip name?
            if (!wispData.tripName || wispData.tripName.length === 0) {
                wispData.setAlertMessage('Please choose a destination for your adventure');
                return;
            }
        }

        var newurl = wispData.tripName.toLowerCase();
        // newurl = newurl + "_" + shortDate(startDate);
        newurl = newurl.replaceAll(' ','_');
        newurl = newurl.replaceAll(',','');

        var newName = wispData.tripName;
        if (getSiteFromName(newName, wispData.userSitesAuth)) {
            newName = newName + ' ' + getDateStr(startDate, false, false);
        }

		var user = wispData.publicState.wispUser;
        var cat = 'motor';
        if (wispData.challengeItem.name === 'No') {
            cat = 'none';
        } else if (wispData.challengeItem.name === 'Yes, walking') {
            cat = 'hike';
        }
        var appData = {
            cat: cat,
            challenge: wispData.tripChallenge,
            loc: wispData.tripLocation,
            lat: wispData.tripLat,
            long: wispData.tripLong,
        };
		var siteData = {
			access: 'public',
			agreed: false,
            app_data: appData,
			app_id: process.env.REACT_APP_ID,
			author_fee: 0,
			date_start: startDate,
			date_end: endDate,
			description: wispData.tripDesc,
			name: newName,
			price: 0,
			url: newurl,
		};

		var token = wispData.token;
		var headers = {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'user-id': user.id,
			'boxes': 'title,library',
			'app-id': process.env.REACT_APP_ID,
		}
        setCreateBusy(true);
		var url = wisp_urls.site_create;
		fetch(url, { method: 'POST', mode: 'cors', headers: headers, body: JSON.stringify(siteData) })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				// reload sites here and then redirect to new site
                setCreateBusy(false);
				newUrl = data['url']
				console.log('dashboardView: loadUserSites');
				loadUserSites(wispData, wispData.publicState.wispUserId, wispData.publicState.wispUserId, wispData.token, getSitesResult, true, newUrl);
			})
			.catch((error) => {
				console.error('Error:', error);
                setCreateBusy(false);
			});
    }

    function getSitesResult(success, data, arg) {
		if (success) {
			wispData.setUserSitesAuth(data);
			wispHistory.push('/' + wispData.publicState.wispUserId + '/' + arg);
		}
	}

    function closeFN() {
		var user = wispData.publicState.wispUser;
        wispHistory.push('/' + user.id);
    }

    function preSetStartDate(date) {
		date.setHours(0, 0, 0, 0);
		setStartDate(date);
        wispData.setAlertMessage('');
	}

	function preSetEndDate(date) {
		date.setHours(0, 0, 0, 0);
		setEndDate(date);
        wispData.setAlertMessage('');
	}


    var okDiv = (
        <div className='newTripActionDiv'>
            <WispButton
                bcls='primaryButton'
                d='inline-block'
                width="200px"
                js={createTrip}
                m='0px 30px 0px 0px'
                id='createTrip'
                key='createTrip'
                mes='Create Adventure' 
                busy={createBusy}
            />
        </div>
    );

    var actionDiv = (
        <div style={{display:'flex'}}>
            {okDiv}
            <div className='newTripActionDiv'>
                <WispButton
                bcls='secondaryButton'
                d='inline-block'
                js={closeFN}
                m='0px'
                mes='Cancel' />
            </div>
        </div>
    );

    var startDateDiv = (
        <div style={{ marginTop: '20px' }}>
            <div className='siteDateDiv'>
                Start Date:&nbsp;
            </div>
            <div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
                <DatePicker selected={startDate} onChange={date => preSetStartDate(date)} dateFormat="MM/dd/yyyy" />
            </div>
        </div>
    );
    var endDateDiv = (
        <div style={{ marginTop: '10px' }}>
            <div className='siteDateDiv'>
                End Date:&nbsp;
            </div>
            <div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
                <DatePicker selected={endDate} onChange={date => preSetEndDate(date)} dateFormat="MM/dd/yyyy" />
            </div>
        </div>
    );

    var alertDiv;
	if (wispData.alertMessage && wispData.alertMessage != '') {
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={wispData.alertMessage} type='error' />
			</div>
		);
	}

    var challenge;
    if (init) { // don't load TripChallenge until cleared out values in useEffect
        challenge = (
            <TripChallenge new />
        );
    }

    var hintText = "(You can change this later)";
    if (wispData.mobileView) {
        hintText = '';
    }

    return (
		<div className='newTripCont'>
            <div className='newTripRadioTitle'>New Adventure!</div>
            <div className='newTripQuestion'>When do you start? <span style={{fontSize:'14px'}}>{hintText}</span></div>
            {startDateDiv}
			{endDateDiv}
            {challenge}
            {actionDiv}
            {alertDiv}
        </div>
    );
}
