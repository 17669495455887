import React, {useCallback, useContext, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import { WispContext } from './WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle as fasCircle, faExclamation, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WispSelect } from './components/WispSelect';
import { WispButton } from './components/WispButton';
import { WispNotification } from './components/WispNotification';
import { loadData, getWispUrl, wisp_urls} from './util/fetch.js';
import { getSiteFromId, getSiteFromName, goToSite, setTripNextId, setTripPrevId } from './util/site.js';
import { getChallengeName, gotMultiLocs, shortDate } from './util/base.js';
import { TripChallenge } from './TripChallenge';
import $ from 'jquery';
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import "react-datepicker/dist/react-datepicker.css";


export const SiteProps = (props) => {

	var wispData = useContext(WispContext);
	var pres1 = 'Feed of All Content Posted to this Trip';
	var pres2 = 'All Content - One Post at a Time';
	var pres3 = 'Feed of MS Word Posts Only';

	const [madeChange, setMadeChange] = React.useState(false);
	const [newUrl, setNewUrl] = React.useState('');
	const [shareAlertMessage, setShareAlertMessage] = React.useState('');
	const [hasAuthorFee, setHasAuthorFee] = React.useState(false);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [savingProps, setSavingProps] = React.useState(false);
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);
	const [prevTrip, setPrevTrip] = React.useState(null);
	const [nextTrip, setNextTrip] = React.useState(null);
	const [viewPref, setViewPref] = React.useState(null);
	
	const [propsInit, setPropsInit] = React.useState(false);

	useEffect(() => {
		if (props.site.author_fee > 0) {
			setHasAuthorFee(true);
		}
		if (props.eventType) {
			if (props.site.app_data) {
				var ad = props.site.app_data;

				if (props.selectView) {
					if (ad.view_pref) {
						setViewPref(propsToViewPref(ad.view_pref));
					} else {
						setViewPref(propsToViewPref('stream'));
					}
				}

				if (ad.lat) {
					wispData.setTripLat(ad.lat);
					$("#siteLat").val(ad.lat);
				}
				if (ad.long) {
					wispData.setTripLong(ad.long);
					$("#siteLong").val(ad.long);
				}
				setPrevTrip("None");
				setNextTrip("None");
				if (ad.trip_prev_id) {
					var tsite = getSiteFromId(ad.trip_prev_id, wispData.userSitesAuth);
					if (tsite) {
						setPrevTrip(tsite.name);
					}
				}
				if (ad.trip_next_id) {
					var tsite = getSiteFromId(ad.trip_next_id, wispData.userSitesAuth);
					if (tsite) {
						setNextTrip(tsite.name);
					}
				}

				var old = false;
				if (ad.cat === 'none') {
					wispData.setChallengeItem({name: 'No'});
				} else if (ad.cat === 'hike') {
					wispData.setChallengeItem({name: 'Yes, walking'});
					var challengeName = getChallengeName(wispData, parseInt(ad.challenge));
					wispData.setHikeChallenge(challengeName);
					wispData.setHikeChallengeLoc(ad.loc);
					loadData(wispData, 'hikes', '5638443006689280', doneCatFN, challengeName, 'hikes'); // doc id from easyappdata
				} else if (ad.cat === 'motor') {
					wispData.setChallengeItem({name: 'Yes, motorized'});
					var challengeName = getChallengeName(wispData, parseInt(ad.challenge));
					wispData.setMotorChallenge(challengeName);
					wispData.setMotorChallengeLoc(ad.loc);
					loadData(wispData, 'motor', '5679818439917568', doneCatFN, challengeName, 'motor'); // doc id from easyappdata
				} else {
					wispData.setChallengeItem({name: 'No'});
					clearTripValues();
					old = true;
				}
				if (!old) {
					wispData.setTripChallenge(ad.challenge);
					wispData.setTripLocation(ad.loc);
				}
			}
		}
		$("#acctProps_bookname").val(props.site.name);
		$("#acctProps_desc").val(props.site.description);
		$("#siteURL").val(props.site.url);
		$("#acctProps_pricebox").html('$' + props.site.price.toFixed(2));
		var wispFee = '$2.50';
		$("#acctProps_wispFee").html(wispFee);   // current app adds to wisp fee for each doc that has forms
		var slider = document.getElementById('acctProps_afeeSldr');
		if (slider && props.site) {
			noUiSlider.create(slider, {
				start: props.site.author_fee,
				handles: 1,
				step: .05,
				format: wNumb({
					decimals: 3,
					thousand: '.',
					postfix: ' (US $)',
				}),
				range: {
					'min': 0,
					'max': 2.50,
				}
			});

			var inputFormat = document.getElementById('acctProps_bPriceVal'); //author fee
			if (inputFormat != 'undefined') {
				slider.noUiSlider.on('update', function (values, handle) {
					inputFormat.value = values[handle];
					setHasAuthorFee(parseFloat(values[handle]) > 0);

					calcPrice(parseFloat(values[handle]));
					/* if (values[handle] !== 0)
						$("#set_banki_div").show();
					else
						$("#set_banki_div").hide(); */

					//			$("#acctProps_authorAmount").html('$' + calculateAuthorPrice(values[handle],'string'));

					var price = parseFloat($("#acctProps_pricebox").html().substring(1));
					if (!props.new && price !== props.site.price) {
						setMadeChange(true);
					}
				});
			}
			inputFormat.addEventListener('change', function(){
				slider.noUiSlider.set(this.value);
			});
		}


		if (props.setDates) {
			if (props.site.date_start != '') {
				setStartDate(new Date(props.site.date_start));
			} else {
				setStartDate(new Date());
			}
			if (props.site.date_end != '') {
				setEndDate(new Date(props.site.date_end));
			} else {
				setEndDate(new Date());
			}
		}
		if (props.site.state === 'creating') {
			var nameInput = document.getElementById('acctProps_bookname');
			nameInput.focus();
			nameInput.select();
		}
		setPropsInit(true);
	}, [wispData.userSitesAuth]);

	function clearTripValues() {
		wispData.setTripChallenge(null);
		wispData.setTripLocation(null);
		wispData.setChallengeItem({name: 'No'});
		wispData.setMultiLocs(false);
		wispData.setHikeChallenge(null);
		wispData.setHikeChallengeLoc(null);
		wispData.setMotorChallenge(null);
		wispData.setMotorChallengeLoc(null);
	}

	function viewPrefToProps(val) {
		if (val === pres1) {
			return 'stream';
		} else if (val === pres2) {
			return 'doc';
		}
		return 'word';
	}

	function propsToViewPref(val) {
		if (val === 'stream') {
			return pres1;
		} else if (val === 'doc') {
			return pres2;
		}
		return pres3;
	}

	function challengeChanged() {
		setMadeChange(true);
	}

	function doneCatFN(challengeName, type) {
		var multipleLocs = gotMultiLocs(wispData, challengeName, type);
		wispData.setMultiLocs(multipleLocs);			
	}

	function preSetStartDate(date) {
		setMadeChange(true);
		date.setHours(0, 0, 0, 0);
		setStartDate(date);
	}

	function preSetEndDate(date) {
		setMadeChange(true);
		date.setHours(0, 0, 0, 0);
		setEndDate(date);
	}

	function urlChange() {
		setMadeChange(true);
	}

	function latChange(val) {
		wispData.setTripLat($("#siteLat").val());
		setMadeChange(true);
	}

	function longChange(val) {
		wispData.setTripLong($("#siteLong").val());
		setMadeChange(true);
	}

	function copyLink() {
		var copyText = document.getElementById("shareUrlDiv");
		var dummy = document.createElement("textarea");
		document.body.appendChild(dummy);
		dummy.value = copyText.textContent;
		dummy.select();
		document.execCommand("copy");
		document.body.removeChild(dummy);
	}

	function newTeamNameChange() {
		// current app checks names to make sure they're unique - we're not going to enforce that now
		setMadeChange(true);
	}

	function teamDescChange() {
		setMadeChange(true);
	}

	function priceChange() {
		setMadeChange(true);
	}

	/* function getTripLatLong() {
		// ["Hikes", "US National Parks", "Motoring"]
		if (eventCatVal === 'Hikes') {
			// 			trips = ["Cotswold Way", "John Muir Trail","LEJOG","NH 4000 Footers", "Other"];
			if (eventTypeVal === 'Cotswold Way') {
				return {lat: 51.3811, long: 2.3590};
			} else if (eventTypeVal === 'John Muir Trail') {
				return {lat: 37.8651, long: 119.5383};
			} else if (eventTypeVal === 'LEJOG') {
				return {lat: 58.6373, long: 3.0689};
			} else if (eventTypeVal === 'NH 4000 Footers') {
				return {lat: 44.2706, long: 71.3033};
			}
		} else if (eventCatVal === 'US National Parks' && wispData.eventData['parks']) {
			var cells = wispData.eventData['parks']['cells'];
			for (var i = 0;i < cells.length;i++) {
				var row = cells[i];
				var name = '';
				if (row['cols'][0]) {
				  name = row['cols'][0]['v'];
				}
				if (name === eventTypeVal) {
					  if (row['cols'][2] && row['cols'][3]) {
						return {
							lat: row['cols'][2]['v'],
							long: row['cols'][3]['v']
						}
					}
				}				
			}
		} else if (eventCatVal === 'Motoring' && wispData.eventData['motor']) {
			var cells = wispData.eventData['motor']['cells'];
			for (var i = 0;i < cells.length;i++) {
				var row = cells[i];
				var name = '';
				if (row['cols'][0]) {
				  name = row['cols'][0]['v'];
				}
				if (name === eventTypeVal) {
					  if (row['cols'][2] && row['cols'][3]) {
						return {
							lat: row['cols'][2]['v'],
							long: row['cols'][3]['v']
						}
					}
				}				
			}
		}
		return {};
	} */

	function setSiteProps() {
		var site = props.site;
		// make sure this is a string
		if (typeof site.id === "number")
			site.id = site.id.toString();
		site.name = $("#acctProps_bookname").val();

		if (props.eventType) {
			if (!startDate || !endDate) {
				setShareAlertMessage('Please choose a start and end date for your adventure');
				return;
			}
			// chose challenge type?
			if (!wispData.challengeItem) {
				setShareAlertMessage('Please choose a challenge type for your adventure');
				return;
			}
			if (wispData.challengeItem.name !== 'No') {
				// selected challenge type but no challenge? 
				if (!wispData.tripChallenge) {
					setShareAlertMessage('Please choose a challenge for your adventure');
					return;
				}
				// selected multiloc challenge but no location?
				if (wispData.multiLocs && !wispData.motorChallengeLoc) {
					setShareAlertMessage('Please choose a challenge location for your adventure');
					return;
				}

			} else {
				// specified a trip name?
				if (!site.name || site.name.length === 0) {
					setShareAlertMessage('Please choose a name for your adventure');
					return;
				}
			}
		}
		site.description = $("#acctProps_desc").val();
		var security = $("#site_security").html();
		if (props.setAccess) {
			if(security === 'Subscription Required') {
				site.access = 'subscription';
			} else if (security === 'Everyone') {
				site.access = 'public';
			} else {
				site.access = 'invite';
			}
		} else {
			site.access = 'public';
		}

		if (site.state === 'creating') {
			site.state = 'created';
		}


		var urlChanged = false;
		/* var turl = site.url;
		if (props.setUrl) {
			var turl = $("#siteURL").val();
		} else if (props.eventType){
			// var event = $("#eventTypeDD").html();
			// only change the url if we haven't already set it to the event
			if (site.url.indexOf(eventTypeVal.toLowerCase()) == -1) {
				turl = eventToUrl(eventTypeVal) + '_' + shortDate(startDate);
			}
		}
		if (turl !== site.url) {
			urlChanged = true;
		}
		site.url = turl; */

		site.app_id = process.env.REACT_APP_ID;

		if (props.eventType) {
			var cat = 'motor';
			if (wispData.challengeItem.name === 'No') {
				cat = 'none';
			} else if (wispData.challengeItem.name === 'Yes, walking') {
				cat = 'hike';
			}
	
			var save_prev_id;
			var save_next_id;
			if (site.app_data) {
				save_prev_id = site.app_data.trip_prev_id;
				save_next_id = site.app_data.trip_next_id;
			}
			site.app_data = {
				cat: cat,
				challenge: wispData.tripChallenge,
				loc: wispData.tripLocation,
				lat: wispData.tripLat,
				long: wispData.tripLong,
				trip_prev_id: null,
				trip_next_id: null,
			};
			if (props.multiTrip){
				if (prevTrip != "None") {
					var tsite = getSiteFromName(prevTrip, wispData.userSitesAuth);
					if (tsite) {
						site.app_data.trip_prev_id = tsite.id;
					}
				}
				if (nextTrip != "None") {
					var tsite = getSiteFromName(nextTrip, wispData.userSitesAuth);
					if (tsite) {
						site.app_data.trip_next_id = tsite.id;
					}
				}
				// update linked sites
				if (save_prev_id !== site.app_data.trip_prev_id) {
					if (save_prev_id) {
						setTripNextId(save_prev_id, null, wispData.userSitesAuth, wispData);
					}
					if (site.app_data.trip_prev_id) {
						setTripNextId(site.app_data.trip_prev_id, site.id, wispData.userSitesAuth, wispData);
					}
				}
				if (save_next_id !== site.app_data.trip_next_id) {
					if (save_next_id) {
						setTripPrevId(save_next_id, null, wispData.userSitesAuth, wispData);
					}
					if (site.app_data.trip_next_id) {
						setTripPrevId(site.app_data.trip_next_id, site.id, wispData.userSitesAuth, wispData);
					}
				}				
			}
			if (props.selectView) {
				var view = $("#viewSel").html();
				site.app_data.view_pref = viewPrefToProps(view);
			}

		} else {
			site.app_data = {};
		}
	
		if($("#acctProps_pricebox").is(':visible')){
			site.price = parseFloat($("#acctProps_pricebox").html().substring(1));
			site.author_fee = parseFloat($("#acctProps_bPriceVal").val());
		}

		if (props.setDates) {
			site.date_start = startDate;
			// add 23 hours 59 minutes to date to pick up positions at the end of the day
			// endDate.setTime(endDate.getTime() + (23*60*60*1000) + (59*60*1000));
			site.date_end = endDate;
		}

		console.log('site = ' + JSON.stringify(site));

		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_edit, site.id);
		var status;
		setSavingProps(true);
		console.log('url = ' + url);
		var b = JSON.stringify(site);
		fetch(url, {method: 'POST', mode: 'cors', headers, body: b})
		.then(function(response) {
			console.log('response.status = ' + response.status); // Will show you the status
			status = response.status;
			return response.json();
		})
		.then(data => {
			setSavingProps(false);
			if (status === 404) {
				if (data.detail === 'invalid URL') {
					setShareAlertMessage(newUrl + ' is invalid');
				} else if (data.detail === 'reserved keyword') {
					setShareAlertMessage(newUrl + ' is a reserved URL');
				} else if (data.detail === 'URL taken') {
					setShareAlertMessage('The ' + newUrl + ' URL is used by another ' + props.siteName);
				} else {
					setShareAlertMessage('URL save failure');
				}
			} else {
				setMadeChange(false);
				if (urlChanged) {
					goToSite(site.url, wispData.publicState.wispUserId);
				}
			}
		})
		.catch((error) => {
			setSavingProps(false);
			console.error('Error:', error);
		});

	}

	function selSecurityTopic() {
		setMadeChange(true);
		setForceUpdate(forceUpdate + 1)
	}

	function showPriceInfo() {

	}

	function calcPrice(afee){
		var wispFee = 2.5;
		var price = parseFloat(wispFee + afee).toFixed(2);
		$("#acctProps_pricebox").html('$' + price);
	}    

	function getSitesList() {
		var tsites = ["None"];
		if (wispData.userSitesAuth) {
			wispData.userSitesAuth.forEach((site) => {
				tsites.push(site.name);
			});
		}
		return tsites;
	}

	function selPrevTrip(val) {
		setPrevTrip(val);
		setMadeChange(true);
	}
	
	function selNextTrip(val) {
		setNextTrip(val);
		setMadeChange(true);
	}

	function selViewPref(val) {
		setViewPref(val);
		setMadeChange(true);
	}

	function wNumb(options){
		// Call 'formatTo' with proper arguments.
		/* var to = function ( input ) {
			return input;
		};
	
		// Call 'formatFrom' with proper arguments.
		var from = function ( input ) {
			return input;
		}; */
	}
/*
	function saveSiteUrl() {
		// validate new URL
		var urlResult = checkUrl(newUrl);
		if (urlResult !== 'valid') {
			setShareAlertMessage(urlResult);
			return;
		} else {
			setShareAlertMessage('');
		}

		var site = props.site;
		site.url = newUrl;
		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_edit, site.id);
		console.log('url = ' + url);
		var status;
		fetch(url, {method: 'POST', mode: 'cors', headers, body: JSON.stringify(site)})
		.then(function(response) {
			status = response.status;
			return response.json();
		})
		.then(data => {
			console.log('data.detail = ' + data.detail);
			if (status === 404) {
				if (data.detail === 'invalid URL') {
					setShareAlertMessage(newUrl + ' is invalid');
				} else if (data.detail === 'reserved keyword') {
					setShareAlertMessage(newUrl + ' is a reserved URL');
				} else if (data.detail === 'URL taken') {
					setShareAlertMessage('The ' + newUrl + ' URL is used by another ' + props.siteName);
				} else {
					setShareAlertMessage('URL save failure');
				}
			} else {
				// goToSite(newUrl);
			}
		})
		.catch((error) => {
			// setEditingUrl(false);
			console.error('Error:', error);
		});
	}
*/
/*	function siteUrlChange() {
		var value = $("#siteUrlInput").val();
		setNewUrl(value);
	}
*/
/*	function doneFN() {
		setMadeChange(true);
		setCatBusy(false);
	} */

	/* function handleEventCat(cat) {
		if (cat !== eventCatVal) {
			if (cat === 'Hikes' && !wispData.eventData['hikes']) {
				setCatBusy(true);
				loadData(wispData, 'hikes', '5638443006689280', doneFN);
			} else if (cat === 'US National Parks' && !wispData.eventData['parks']) {
				setCatBusy(true);
				loadData(wispData, 'parks', '5679818439917568', doneFN);
			} else if (cat === 'Motoring' && !wispData.eventData['motor']) {
				setCatBusy(true);
				loadData(wispData, 'motor', '5689837499711488', doneFN);
			} else {
				setMadeChange(true);
			}
			setEventCatVal(cat);
			setEventTypeVal(null);
		}

	}

	function selEventCat() {
		var cat = $("#eventCatDD").html();
		handleEventCat(cat);
	}

	function selEventType() {
		setEventTypeVal($("#eventTypeDD").html());
		setMadeChange(true);
	}

	function addTrips(cells, trips) {
		for (var i = 0;i < cells.length;i++) {
			var row = cells[i];
			if (row['cols'][0]) {
				// make sure not already in array
				var val = row['cols'][0]['v'];
				var got = false;
				for(var ti = 0;ti < trips.length;ti++) {
					if (trips[ti] === val) {
						got = true;
					}
				}
				if (!got) {
					trips.push(val);
				}
			}				
		}
	}

	function getCatTrips(cat) {
		var trips = [];
		if (cat === 'Hikes') {
//			trips = ["Cotswold Way", "John Muir Trail","LEJOG","NH 4000 Footers", "Other"];
			if (wispData.eventData && wispData.eventData['hikes']) {
				var cells = wispData.eventData['hikes']['cells'];
				addTrips(cells,trips);
			}
		} else if (cat === "US National Parks") {
			if (wispData.eventData && wispData.eventData['parks']) {
				var cells = wispData.eventData['parks']['cells'];
				addTrips(cells,trips);
			}
		} else if (cat === "Motoring") {
			if (wispData.eventData && wispData.eventData['motor']) {
				var cells = wispData.eventData['motor']['cells'];
				addTrips(cells,trips);
			}
		}
		trips.push('Other');
		return trips;
	} */

	var checkDiv;
	var saveButton;
	if (!props.new) {
		if (!wispData.mobileView) {
			checkDiv = (
				<div className='set_sect_stat'>
					<FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} />
				</div>
			);
		}
		if (madeChange) {
			saveButton = (
				<div id='setPropsButDiv' style={{ margin: '40px 0px 0px 0px', position: 'relative' }}>
					<WispButton
						bcls='primaryButton'
						d='inline-block'
						js={setSiteProps}
						busy={savingProps}
						m='0px'
						width='220px'
						mes="Save Properties" />
				</div>
			);
		}
	}
	var topics = ["Everyone", "Invite Only", "Subscription Required"];

	var securityValue;
	var bankSettingsWarning;
	if (props.site.access === 'public') {
		securityValue = topics[0];
	} else if (props.site.access === 'invite') {
		securityValue = topics[1];
	} else {
		securityValue = topics[2];
		if (hasAuthorFee) {
			var userStripeInfo = wispData.publicState.userStripeInfo;
			if (userStripeInfo !== null && typeof userStripeInfo.stripe_acct_id === 'undefined') {
				var link = process.env.REACT_APP_URL + '/account';
				var mes = (
					<div>Bank account information must be provided to receive proceeds from WispBook sales. Bank information is entered on the <a href={link}>account page</a>.</div>
				)
				bankSettingsWarning = (
					<div style={{marginTop: '20px'}}>
						<WispNotification message={mes} type='warning' />
					</div>
				);
			}
		}
	}
	// str += getDropdown("team_security",topics[2],topics.length,topics,"selTeamSecurity","","","","inline-table",'','8','320','320','','0','webt_ui_back_13');

	var propClass;
	if (props.navOpen && wispData.mobileView) {
		propClass = 'propertiesNavOpen';
	}

	var displayStyle = { display: 'none', marginTop: '25px' };
	var security = $("#site_security").html();
	if (security && security === 'Subscription Required') {
		displayStyle['display'] = 'block' ;
	}
	var priceDiv = (
		<div style={displayStyle}>
			<div className='wisp_in_mid settingsPrice'>
				Price:&nbsp;
						</div>
			<div className='wisp_in_mid' style={{ padding: '0px' }}>
				<div style={{ color: 'white', fontSize: '24px', textAlign: 'center', background: 'rgb(170,170,170)', padding: '3px 10px', border: '1px solid rgb(235,235,235)' }} id='acctProps_pricebox'></div>
			</div>
			<div className='wisp_in_mid priceInfo' onClick={showPriceInfo}>
				<span className='edit_info webt_ui_color_1'>
					<FontAwesomeIcon icon={faInfoCircle} style={{ color: '#0aadff' }} />
				</span>
			</div>
			<div className='wisp_in_mid wispFee'>
				Wisp Fee:&nbsp;
						</div>
			<div className='wisp_in_mid wispFeeAmount'>
				<div style={{ fontSize: '16px', textAlign: 'center', background: '#ECECEC', padding: '3px 5px' }} id='acctProps_wispFee'></div>
			</div>
			<div className='wisp_in_mid authorFee'>
				Author Fee:&nbsp;
						</div>
			<div className='wisp_in_mid authorFeeSlider'>
				<div id='acctProps_afeeSldr' className='noUiSlider' style={{ width: '80px' }}></div>
			</div>
			<div className='wisp_in_mid' style={{ margin: '1px 0px 0px 15px', padding: '0px' }}>
				$ <input onChange={priceChange} tabIndex='4' style={{ width: '40px', fontSize: '16px', textAlign: 'center' }} id='acctProps_bPriceVal' type='text'></input>
			</div>
			<div id='price_infoDiv' style={{ display: 'none', fontSize: '12px', margin: '15px 0px 0px 25px' }}>
				<p>Your WispBook price is the combination of a fixed Wisp Fee + an optional Author Fee. The Wisp Fee is $2.50.</p>
				<p>After each " + kw + " sale, the Author Fee is paid to the bank account setup on the Account page.</p>
			</div>

		</div>
	);

	var shareAlertDiv;
	if (shareAlertMessage !== '') {
		shareAlertDiv = (
			<div style={{marginTop: '10px'}}>
				<WispNotification message={shareAlertMessage} type='error' />
			</div>
		);
	}

	var accessDiv;
	if (props.setAccess) {
		accessDiv = (
			<React.Fragment>
				<div style={{ marginTop: '30px' }}>
					<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
						Access:&nbsp;
					</div>
					<div style={{ display: 'inline-table', width: '350px' }}>
						<WispSelect
							id="site_security"
							val={securityValue}
							nOptions={topics.length}
							options={topics}
							fn={selSecurityTopic}
							display='inline-table'
						/>
					</div>
				</div>
				{priceDiv}
			</React.Fragment>
		);
	}
	var viewSelDiv;
	if(props.selectView) {
		var viewTypeList = [pres1, pres2, pres3];
		viewSelDiv = (
			<React.Fragment>
                <div className='viewSelDiv'>
                    <div className='prevNextTrip'>Presentation</div>
                    <div className='newTripSelectCont'>
                        <WispSelect
                            id="viewSel"
                            val={viewPref}
                            nOptions={viewTypeList.length}
                            options={viewTypeList}
                            display='inline-table'
                            fn={selViewPref}
                            w='320'
                            busy={false}
                        />
                    </div>
				</div>
			</React.Fragment>
		);
	}

	var eventTypeDiv;
	if (props.eventType && propsInit) {	// don't show tripchallenge until we have data loaded
		eventTypeDiv = (
            <TripChallenge changeFN={challengeChanged} />
		);
/*		var categories = ["Hikes", "Motoring"];
		var topics = getCatTrips(eventCatVal);
		eventTypeDiv = (
			<React.Fragment>
				<div className='catSectionDiv'>
					<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
						Category:&nbsp;
					</div>
					<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
						<WispSelect
							id="eventCatDD"
							val={eventCatVal}
							nOptions={categories.length}
							options={categories}
							display='inline-table'
							fn={selEventCat}
							w='250'
							busy={catBusy}
						/>
					</div>
				</div>
				<div style={{ marginTop: '20px' }}>
					<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
						Trip:&nbsp;
					</div>
					<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
						<WispSelect
							id="eventTypeDD"
							val={eventTypeVal}
							nOptions={topics.length}
							options={topics}
							display='inline-table'
							fn={selEventType}
							w='250'
						/>
					</div>
				</div>	
			</React.Fragment>
		); */
	}

	var startDateDiv;
	var endDateDiv;
	if (props.setDates) {
		startDateDiv = (
			<div style={{ marginTop: '50px' }}>
				<div className='siteDateDiv'>
					Start Date:&nbsp;
				</div>
				<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
					<DatePicker selected={startDate} onChange={date => preSetStartDate(date)} dateFormat="MM/dd/yyyy" style={{zIndex:1}} />
				</div>
			</div>
		);
		endDateDiv = (
			<div style={{ marginTop: '10px' }}>
				<div className='siteDateDiv'>
					End Date:&nbsp;
				</div>
				<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
					<DatePicker selected={endDate} onChange={date => preSetEndDate(date)} dateFormat="MM/dd/yyyy" />
				</div>
			</div>
		);
	}
	var latDiv;
	var longDiv;
	if (props.setLatLong) {
		latDiv = (
			<div style={{ marginTop: '50px' }}>
				<div className='siteDateDiv'>
					Latitude:&nbsp;
				</div>
				<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
					<input tabIndex='4' id='siteLat' className='settingsInput wt_input' onKeyUp={latChange} />
				</div>
			</div>
		);
		longDiv = (
			<div style={{ marginTop: '10px' }}>
				<div className='siteDateDiv'>
					Longitude:&nbsp;
				</div>
				<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
					<input tabIndex='5' id='siteLong' className='settingsInput wt_input' onKeyUp={longChange} />
				</div>
			</div>
		);
	}

	var urlControls;
	if (props.setUrl) {
		var urlInput;
		if (props.prefix) {
			urlInput = (
				<React.Fragment>
					<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
						<input tabIndex='2' className='wt_input' id='siteURL' style={{height:'29px'}} onKeyUp={urlChange} />
					</div>
					<div className='wisp_in_mid' style={{fontSize:'12px',fontWeight:'600'}}>.{process.env.REACT_APP_URL_POSTFIX}</div>
				</React.Fragment>
			);
		} else {
			urlInput = (
				<React.Fragment>
					<div className='wisp_in_mid' style={{fontSize:'12px',fontWeight:'600'}}>{process.env.REACT_APP_URL}/{wispData.publicState.wispUserId}/</div>
					<div style={{display: 'inline-table', marginRight:'10px',height:'35px', padding: '0px'}}>
						<input tabIndex='2' className='wt_input' id='siteURL' style={{height:'29px'}} onKeyUp={urlChange} />
					</div>
				</React.Fragment>
			);
		}
		urlControls = (
			<div style={{ marginTop: '20px' }}>
				<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
					URL:&nbsp;
				</div>
				{urlInput}
			</div>
		);
	}

	var nameDesc = (
		<React.Fragment>
			<div style={{ marginTop: '15px' }}>
				<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
					Name:&nbsp;
				</div>
				<div style={{ display: 'inline-table', verticalAlign: 'top', padding: '0px' }}>
					<input tabIndex='1' id='acctProps_bookname' className='wt_input settingsInput' onKeyUp={newTeamNameChange} /> 
				</div>
				<div id='newTeamNameMes' className='wisp_in_mid'></div>
			</div>

			<div style={{ marginTop: '20px' }}>
				<div style={{ display: 'inline-table', verticalAlign: 'top', fontSize: '12px', width: '95px', marginRight: '10px', marginTop: '3px' }}>
					Description:&nbsp;
				</div>
				<div className='te_textareadiv active' style={{ display: 'inline-table',height: '60px' }}>
					<textarea tabIndex='3' className='wt_textarea settingsInput' id='acctProps_desc' style={{ height: '50px', fontSize: '12px' }} onKeyUp={teamDescChange}></textarea>
				</div>
			</div>
		</React.Fragment>
	);

	var multiTripDiv;
	if(props.multiTrip) {
		var sitesList = getSitesList();

		multiTripDiv = (
			<React.Fragment>
				<div className='newTripQuestion'>Is your trip part of a multi-trip adventure?</div>
                <div className='questionDiv'>
                    <div className='prevNextTrip'>Previous Trip</div>
                    <div className='newTripSelectCont'>
                        <WispSelect
                            id="prevTripSel"
                            val={prevTrip}
                            nOptions={sitesList.length}
                            options={sitesList}
                            display='inline-table'
                            fn={selPrevTrip}
                            w='320'
                            busy={false}
                        />
                    </div>
                    <div className='prevNextTrip'>Next Trip</div>
                    <div className='newTripSelectCont'>
                        <WispSelect
                            id="nextTripSel"
                            val={nextTrip}
                            nOptions={sitesList.length}
                            options={sitesList}
                            display='inline-table'
                            fn={selNextTrip}
                            w='320'
                            busy={false}
                        />
                    </div>
                </div>
			</React.Fragment>
		);

	}

	return (

		<div className={propClass + ' siteEditSection'}>
			{checkDiv}
			<div className='settings_heading'>
				<span style={{ marginLeft: '25px' }} id='set_prop_span'>Properties</span>
			</div>
			<div className='wisp_app_list'>
				{nameDesc}
				{viewSelDiv}
				{urlControls}
				{eventTypeDiv}
				{startDateDiv}
				{endDateDiv}
				{latDiv}
				{longDiv}
				{accessDiv}
				{multiTripDiv}
				{saveButton}
				{shareAlertDiv}
				{bankSettingsWarning}
			</div>
		</div>
	);

}

