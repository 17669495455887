import React, {useContext, useEffect} from 'react';
import './App.css';
import {BaseLayout} from './BaseLayout';
import {SiteView} from './SiteView.js';
import { Home } from './Home.js';
import HomeUser from './HomeUser.js';
import PasswordReset from './PasswordReset.js';
import InviteView from './InviteView.js';
import AccountView from './AccountView.js';
import {BookSettings} from './BookSettings.js';
import { DocView } from './DocView.js';
import EventView from './EventView.js';
import {FilePickerView } from './FilePickerView.js';
import {NewTripView } from './NewTripView.js';
import {SupportView} from './SupportView.js';
import {FAQView} from './FAQView.js';
import {TermsView} from './TermsView.js';
import {PrivacyView} from './PrivacyView.js';
import {wispHistory} from './components/WispHistory.js';
import { AzureAuth } from './components/AzureAuth.js';
import {WispContext} from './WispProvider.js';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import { stripDomainFromUrl } from './util/base.js';
import { getSiteUrlFromUrl, getSiteFromUrl } from './util/site.js';
import { fetchGet, wisp_urls } from './util/fetch.js';
import './css/webtotality_ui.css';
import './css/webtotality.css';
import './css/crazy.css';
import './css/common.css';
import { SplashScreen } from '@capacitor/splash-screen';

var gettingSiteInfo = 'init';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DATA_KEY);
// var settingOauthCode = false;

export const App = (props) => {
  var wispData = useContext(WispContext);
  var publicState = wispData.publicState;

  // having trouble getting these to work - had to add crap to WispProvider
    const [pathName, setPathName] = React.useState('');

  useEffect(() => {
    wispHistory.listen(({ action, location }) => {
      // for some reason action and location are undefined...
      // in any case, have to change a prop to get the app compmonent to rerender
      if (action) {
        console.log(`The last navigation action was ${action}`);
      }
      if (location) {
        console.log('location = ' + location.pathname);
        setPathName(location.pathname);
      } else {
        console.log('no location');
        setPathName(window.location.href);
      }

    });

    // deviceready event only fires on mobile capacitor
    document.addEventListener("deviceready", onDeviceReady, false);
    if (window.cordova) {
      console.log('ADDING ORIENTATION CHANGE LISTENER');
      window.addEventListener("orientationchange", function(event) {
        window.plugins.safearea.get(
          (result) => {
            // elegantly set the result somewhere in app state
            wispData.setSafeArea(result);
          },
          (error) => {
            // maybe set some sensible fallbacks?
          }
        );
 
      });
    }

  }, []);

  function successCallback(result) {
    wispData.setSafeArea(result);
  }
  
  function errorCallback() {
    console.log('error getting safearea');
  }

  function onDeviceReady() {
    window.plugins.safearea.get(successCallback, errorCallback);

    // Hide the splash (you should do this on app launch)
    SplashScreen.hide();

    // Show the splash for an indefinite amount of time:
    SplashScreen.show({
      autoHide: false
    });

    // Show the splash for ten seconds and then automatically hide it: - should get hidden in BaseLayout once auth/token is checked and we're ready to display stuff
    SplashScreen.show({
      showDuration: 10000,
      autoHide: true
    });    
  }  

  function getSiteInfo(siteUrl){
      console.log('App.js: get site by URL - siteUrl = ' + siteUrl);
      gettingSiteInfo = 'getting';
      /* if (siteUrl.indexOf('azure_auth') > 0) {
          var i = 0;
          alert("F UP!");
      } else { */
      var url = wisp_urls.site_get_by_url;
      var force = true;
      if (wispData.siteInfo && wispData.siteInfo.url === siteUrl) {
          force = false;
      }
      url += '?url=' + siteUrl;
      fetchGet(url, getSiteResult, wispData, force, null);   // if you don't have true then won't reload even though 'url' param is different
      // }
  }

  function getSiteResult(success, data) {
    if (data === 'fetching') {  // not sure why it does this...
      return;
    }
    if (success) {
      // console.log('setting siteInfo to ' + JSON.stringify(data));
      wispData.setSiteInfo(data);
    }    
    console.log('setting gettingSiteInfo to got');
    gettingSiteInfo = 'got';
  }

  function getEventFromUrl(href) {
    var event = null;

    // href = stripDomainFromUrl(href);
  
    var i = href.indexOf('event/');
    if (i === 0) {
      event = href.substring(i+6);
    }
    return event;
  }

  function getUserIdFromUrl(href) {
    var userId = null;
    var i = href.indexOf('/');
    var i2 = href.indexOf('?');
    if (i > 0) {
      userId = href.substring(0,i);
    } else if (i2 > 0) {
      userId = href.substring(0,i2);
    } else {
      userId = href;
    }

    // test server fix - don't think we need this anymore because not passing in lowercase href
    // 2/18/23 - gonna put it back in because userids shouldn't be case sensitive
    // crazydoes.com/JeffTidd should get you crazydoes.com/jefftidd
    userId = userId.toLowerCase();
    if (userId === 'judixuk3mqpacmlxhfi1') {
       userId = 'JUDIXUK3MQPACMLXHFI1';
    }
    return userId;
  }

  // i is after user id/
  function getSiteUrlFromUrl(href, i) {
    var siteName = null;
    if (href.length > i) {
      var i2 = href.indexOf('/', i);
      if (i2 > 0) {
        siteName = href.substring(i,i2);
      } else {
        siteName = href.substring(i);
      }
    }
    return siteName;
  }

  // i is after user id/
  function getDocIdFromUrl(href, i) {
    var docId = null;
    // skip site url
    if (href.length > i) {
      var i2 = href.indexOf('/', i);
      if (i2 > 0) {
        if (href.length > i2) {
          // got a docId
          docId = href.substring(i2+1);
        }
      }
    }
    return docId;
  }
  
  var pathname = wispHistory.location.pathname;
  var href = window.location.href;
  // console.log('href = ' + href);
    var hrefLower = href.toLowerCase();  // watch out - this will kill caps in azure_auth code, etc
    hrefLower = decodeURI(hrefLower);

  if (hrefLower.indexOf('http://localhost') === 0) { // to handle azure's stupid requirement to only redirect to http://localhost
    window.location.href = 'http://app.localhost' + href.substring(16);
    return;
  } else if (hrefLower.indexOf('https://crazydoes.com') === 0) {
    window.location.href = 'https://www.crazydoes.com' + href.substring(21);
    return;
  } else if (hrefLower.indexOf('capacitor://localhost') === 0) {
    hrefLower = 'https://www.crazydoes.com' + hrefLower.substring('capacitor://localhost'.length + 1);
    href = 'https://www.crazydoes.com' + href.substring('capacitor://localhost'.length + 1);
  }
  // crazydoes URLS 
  // - nothing - crazydoes home page
  // - keyword (azure_auth, password_reset, support, account)
  // - event/eventid - crazydoes events
  // - file_picker/siteid - filepicker for that site
  // - userid - a user's dashboard (accessible by anyone)
  // - userid?code= - when linking stripe
  // - userid/storyurl - a user's trip

  var content;
  hrefLower = stripDomainFromUrl(hrefLower);  // we want href lower to eliminate page url case issues - but it's going to screw up any userId
  // console.log('hrefLower = ' + hrefLower);
  var strippedDomain = stripDomainFromUrl(href);
  if (hrefLower === '') {
    if (window.cordova && wispData.checkedToken === true && publicState.validToken && publicState.wispUserId) {
      //    window.location.href = process.env.REACT_APP_URL + '/' + userId;
      wispHistory.push('/' + publicState.wispUserId);
    } else {
      content = (
        <Home />
      );
    }
  } else {
      if (hrefLower.indexOf('azure_auth/') === 0) {
          console.log('azure_auth url');
      content = (
        <AzureAuth href={href}/>  // pass in original href to get original params - i.e. code
      );
    } else if (hrefLower.indexOf('app/gear') === 0) {
      var type = '';
      var ti = hrefLower.indexOf('?type=');
      if (ti > 0) {
        type = hrefLower.substring(ti+6);
        var ti2 = type.indexOf('#');
        if (ti2 > 0) {
          type = type.substring(0,ti2);
        }
      }
      content = (
        <Home gear={type} />
      );
    } else if (hrefLower.indexOf('password_reset') === 0) {
      content = (
        <PasswordReset />
      );
    } else if (hrefLower.indexOf('invite') === 0) {
      content = (
        <InviteView />
      );
    } else if (hrefLower === 'support') {
      content = (
        <SupportView />
      );  
    } else if (hrefLower === 'faqs') {
      content = (
        <FAQView />
      );  
    } else if (hrefLower === 'terms') {
      content = (
        <TermsView />
      );  
    } else if (hrefLower === 'privacy') {
      content = (
        <PrivacyView docId='5643677540024320' />
      );  
    } else if (hrefLower === 'account') {
      content = (
        <AccountView />
      );
    } else if (hrefLower === 'newtrip') {
      content = (
        <NewTripView />
      );
    } else if (hrefLower.indexOf('file_picker/') === 0) {
      if (wispData.checkedToken === true) {
        if (wispData.userSitesLoaded === true) {
          //  if it's not in there then go get it
          var i = hrefLower.indexOf('file_picker');
          var siteUrl = hrefLower.substring(i+12);
          var site = getSiteFromUrl(siteUrl, wispData.userSitesAuth);
          content = (
            <FilePickerView site={site} />
          );
        }
      }
    } else {
      var event = getEventFromUrl(hrefLower);
      if (event != null) {
        content = (
          <EventView event={event} />
        );
      } else {
        var userId = getUserIdFromUrl(strippedDomain);
        var i = hrefLower.indexOf('/');
        if (i > 0) {  // got a site after userid
          var siteUrl = getSiteUrlFromUrl(hrefLower,i+1);
          var docId = getDocIdFromUrl(hrefLower,i+1); // this will also pickup site/edit - i.e. docId == edit - not sure if this is a problem
          // console.log('docId = ' + docId);  
          // we need to know if the user's logged in - so see if we know that yet
          if (wispData.checkedToken === true) {

            if (docId && docId !== '' && docId !== 'edit') {
              if (wispData.siteInfo) {
                // does our siteInfo match the siteUrl?
                if (wispData.siteInfo.url === siteUrl) {
                  content = (
                        <DocView site={wispData.siteInfo} docId={docId} userId={userId} showLinks backMes='trip page' goBack />
                    );
                } else if (gettingSiteInfo != 'getting') {
                    getSiteInfo(siteUrl);
                }
              } else if (gettingSiteInfo === 'init') {
                  getSiteInfo(siteUrl);
              }

            } else if (!publicState.validToken) {    // if the user's not logged in, then just go get the site
                if (wispData.siteInfo) {
                  // does our siteInfo match the siteUrl?
                  if (wispData.siteInfo.url === siteUrl) {
                    content = (
                          <SiteView site={wispData.siteInfo} userId={userId} />
                      );
                  } else if (gettingSiteInfo != 'getting') {
                      getSiteInfo(siteUrl);
        					}
                } else if (gettingSiteInfo === 'init') {
                    getSiteInfo(siteUrl);
                }
            } else {
              // the user's signed in!
              // wait til sites are loaded to see if it's in there
              if (wispData.userSitesLoaded === true) {
                //  if it's not in there then go get it
                var site = getSiteFromUrl(siteUrl, wispData.userSitesAuth);
                if (site) { // got this site in our site maps
                  if (docId && docId === 'edit') {
                    content = (
                      <BookSettings site={site} />
                    );
                  } else {
                    content = (
                      <SiteView site={site} userId={userId} />
                    );
                  }
                } else if (wispData.siteInfo && wispData.siteInfo.url === siteUrl) {
                  content = (
                    <SiteView site={wispData.siteInfo} userId={userId} />
                  );          
                } else if (gettingSiteInfo === 'init' || gettingSiteInfo === 'got') {
                    getSiteInfo(siteUrl);
                }
              }
            }
          }
        } else {
          content = (
            <HomeUser userId={userId} />
          );    
        }       
      }
    }
  }

  return (
    <Elements stripe={stripePromise}>
      <BaseLayout>
        {content}
      </BaseLayout>
    </Elements>
  );
}
