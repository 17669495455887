import React, {useContext, useEffect} from 'react';
import {WispContext} from './WispProvider.js';
import {WispNotification} from './components/WispNotification';
import { getUrlParameter, resetInputClicks } from './util/base.js'; 
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {doSignOut, getUserEmail, loadUserSites} from './util/user.js';
import { completeInvite, goToSite } from './util/site.js';
import $ from 'jquery';
import { WispButton } from './components/WispButton';

export default function InviteView(props) {

    var wispData = useContext(WispContext);
    const [sitemapId, setSitemapId] = React.useState(null);
    const [showBusy, setShowBusy] = React.useState(true);
    const [alertMessage, setAlertMessage] = React.useState('');

    useEffect(() => {
        var sitemapId = getUrlParameter('sitemap');
        setSitemapId(sitemapId);

        if (wispData.publicState.wispUser) {
            var url = getWispUrl(wisp_urls.user_uids_get, wispData.publicState.wispUser.id);
            fetchGet(url, getUIDsResult, wispData);
        }

        var url = getWispUrl(wisp_urls.sitemap_get, sitemapId);
        // setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
        fetchGet(url, getSitemapResult, wispData, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getUIDsResult(success, data) {
        if (success) {
            wispData.setUserUids(data);
        }
    }

    function getSitemapResult(success, data) {
        console.log('got invite sitemap');
        if (success && data) {
            if (data['state'] !== 'invited') {
                setAlertMessage("This invitation is no longer valid");
            } else {
                wispData.setInviteSitemap(data);
                console.log('setting invite sitemap');
                var url = getWispUrl(wisp_urls.site_get, data.site_id);
                fetchGet(url, getSiteResult, wispData, true);
            }
        } else {
            setAlertMessage("We couldn't find this invitation!");
            setShowBusy(false);
        }
    }

    function getSiteResult(success, data) {
        if (success) {
            console.log('setting invite site');
            wispData.setInviteSite(data);
        }
        setShowBusy(false);
    }

    function getSitesResult(success, data) {
        if (success && (data.detail !== 'invalid token')) {
            wispData.setUserSitesAuth(data);
            wispData.setUserSitesLoaded(true);
            goToSite(wispData.inviteSite.url, wispData.publicState.wispUser.id);
			wispData.setInviteSitemap(null);
			wispData.setInviteSite(null);
        }
    }

    function doneSignout() {
        // the fact that we set inviteSitemap above will tell signin/create account what to do after that's done
        wispData.setShowCreateAccount(true);
    }

    function acceptInvite() {
        // already signed in and the invited user?
        if (!showBusy) {
            var gotMatch = false;
            if (wispData.publicState.wispUser) {
                var email = getUserEmail(wispData);
                if (email === wispData.inviteSitemap['user_id']) {
                    gotMatch = true;
                }
            }

			
            if (!gotMatch) {    // not signed in
                if (wispData.publicState.wispUser) {    // someone else is signed in
                    doSignOut(wispData, doneSignout);   // sign them out and then have them sign in/create account
                } else {
                    // this is the most likely scenario - have them sign in/create account
                    // the fact that we set inviteSitemap above will tell signin/create account what to do after that's done
                    // wispData.setShowSignin(true);
                    wispData.setShowCreateAccount(true);
                }                
            } else {    // the person's signed in - just call the server to update the sitemap state to active
                completeInvite(wispData, wispData.inviteSitemap, doneFN, wispData.publicState.wispUserId, wispData.token);
            }
        }
    }

    function doneFN(data, userId, token) {
        setShowBusy(false);
        if (data) {
            if (data.has('detail')) {
                setAlertMessage("Failed to activate invitation. Please contact Crazydoes support");
            } else {
                loadUserSites(wispData, userId, userId, token, getSitesResult, true, null);
            }
        } else {
			setShowBusy(false);
			setAlertMessage("Failed to activate invitation. Please contact Crazydoes support");
        }
    }

    var trip = '';
    if (wispData.inviteSite) {
        trip = wispData.inviteSite.name;
    }
    var alertDiv;
	if (alertMessage != '') {
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage} type='error' />
			</div>
		);
	}

    return (
        <div id="wrapper">

            <div className='page_ex_cont_div'>
                <div className='feature_pan_title' style={{textAlign: 'center'}}>Join the <i>{trip}</i> team!</div>
                <div className='feature_pan_subtitle'>Post photos, videos and messages & more</div>
                <div style={{marginTop: '20px'}}>
                    <WispButton
                        bcls='primaryButton'
                        busy={showBusy}
                        d='inline-block'
                        width="100%"
                        js={acceptInvite}
                        m='0px'
                        mes="Accept Invitation" />
                    {alertDiv}
                </div>
            </div>
        </div>  /* wrapper */
    );
}
  