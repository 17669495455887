import React, { useContext, useEffect } from 'react';
import { WispContext } from '../WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {stopDefault} from '../util/base.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';

export function MapGroup(props) {

    var wispData = useContext(WispContext);

	useEffect(() => {
	}, []);

    function mapGroupClick(group) {
        props.openFN(group);
    }

    function selectItem(name) {
//        var val = e.target.innerText;
        props.clickFN(name);
    }

    var items = [];
    var siteDocMaps = props.siteDocMaps;
    var content;
    // console.log('rendering mapgroup with open = ' + props.isOpen);
    if (props.isOpen) {
        if (siteDocMaps && siteDocMaps.length > 0) {
            siteDocMaps.forEach((map) => {
                if (props.groupMaps[map.id] === props.group['num']) {
                    var border = '1px solid #EEE';
                    if(items.length === 0){
                        border = '';
                    }
                    items.push(
                        <li onMouseDown={stopDefault}
                            key={items.length + '_key'}
                            style={{height:'19px', border: border, padding: '2px'}}
                            onClick={e => selectItem(map.filename)}>
                                {map.filename}
                        </li>
                    );
                }
            });
        } else if (props.sites && props.sites.length > 0) {
            props.sites.forEach((site) => {
                if (props.groupMaps[site.id] === props.group['num']) {
                    var border = '1px solid #EEE';
                    if(items.length === 0){
                        border = '';
                    }
                    items.push(
                        <li onMouseDown={stopDefault}
                            key={items.length + '_key'}
                            style={{height:'19px', border: border, padding: '2px'}}
                            onClick={e => selectItem(site.name)}>
                                {site.name}
                        </li>
                    );
                }
            });

        }
        content = (
            <div key={props.group['num']} className='mapGroup mapGroupOpen'>
                <div className="close_float_caption" onClick={props.closeFN} style={{marginTop: '-22px'}}>
                    <div id="fullscreen_close" className="close" style={{color: 'rgb(218, 218, 60)'}}>
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes}/>
                    </div>
                </div>
                <ul>
                    {items}
                </ul>
            </div>
        )
    } else {
        content = (
            <div key={props.group['num']} className='mapGroup mapGroupClosed' onClick={() => mapGroupClick(props.group)}>
                {props.numDocs}
            </div>
        );
    }
    return (
        <React.Fragment>
            {content}
        </React.Fragment>
    );

}