import React, {useEffect} from 'react';
import WispLogo from './images/wisp_logo_beta.png';
import { getUrlParameter, resetInputClicks } from './util/base.js'; 
// import {WispContext} from './WispProvider';
import {WispInput} from './components/WispInput';
import {wispHistory} from './components/WispHistory';
import $ from 'jquery';
import { WispButton } from './components/WispButton';

export default function PasswordReset(props) {

    // var wispData = useContext(WispContext);
    // var email = '';
    // var code = '';

    const [passwordResetState, setPasswordResetState] = React.useState({
        alertMessage: '',
    })
    const [urlEmail, setUrlEmail] = React.useState("");
    const [urlCode, setUrlCode] = React.useState("");

    useEffect(() => {
        resetInputClicks();
        $("#new_password").focus();
        $("#new_password").attr('type','password');

        setUrlEmail(getUrlParameter('email'));
        setUrlCode(getUrlParameter('code'));
        console.log('email = ' + urlEmail);

		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setAlertMessage(val) {
        if (passwordResetState.alertMessage !== val) {
            setPasswordResetState((prevState) => {
                return {
                    ...prevState,
                    alertMessage: val,
                }
            });
        }
    }

    function newPass() {
        var url = process.env.REACT_APP_WISP_URL + "/user/set_password/";
        var headers = {
            'Content-Type': 'application/json',
            email: urlEmail,
            code: urlCode,
            password: $("#new_password").val(),
        }
        console.log(headers);
        fetch(url, {method: 'post', mode: 'cors', headers})
        .then(function(response) {
            console.log('response.status = ' + response.status); // Will show you the status
            return response.json();
        })
        .then(data => {
            wispHistory.push('/');
        })
        .catch((error) => {
            console.error('Error:', error);
            setAlertMessage(error);
        });

    }

    var alertDiv;
    if (passwordResetState.alertMessage !== '') {
        alertDiv = (
            <div className="wisp_alert">
                <div className="arrow"></div>
                <p id='login_alert_p'>
                    {passwordResetState.alertMessage}
                </p>
            </div>        
        );
    }


    return (
        <div id="wrapper">

            <div className='page_ex_cont_div'>
                <div className='feature_pan_title' style={{marginBottom:'50px'}}>Set New Password</div>

                <div>
                    <WispInput
                        id='new_password'
                        hint='New Password'
                        hint2='New Password'
                        hint2Style={{left: '13px'}}
                        initValue=''
                        inputStyle={{width:'100%', paddingTop: '0px'}}
                        classes='active'
                        ti='1'
                    />
               </div>
                <div style={{marginTop: '20px'}}>
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="100%"
                        js={newPass}
                        m='0px'
                        mes="Set New Password" />
                    {alertDiv}
                </div>
            </div>
        </div>  /* wrapper */
    );
}
  