import React, {useContext, useEffect} from 'react';
import {fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import { getFirstPageDoc, getNextPageDoc } from './util/site.js';
import {fileType} from './util/document.js';
import {WispContext} from './WispProvider.js';
import {WispNotification} from './components/WispNotification';
import {WispNav} from './components/WispNav';
import {DocView} from './DocView';
import { Document, Page } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NextLink } from './components/NextLink';


export default function EventView(props) {

    var wispData = useContext(WispContext);
    var publicState = wispData.publicState;
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [curPageId, setCurPageId] = React.useState(0);
	const [curView, setCurView] = React.useState(null);
	const [site, setSite] = React.useState(null);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);
	const [navOpen, setNavOpen] = React.useState(!wispData.mobileView);
	const [eurl, setEurl] = React.useState('');
	const [eclass, setEclass] = React.useState('');
	const [shopifyInit, setShopifyInit] = React.useState(false);
	const [haveGear, setHaveGear] = React.useState(false);

    useEffect(() => {
        // load the site associated with this event
		var ev = props.event;
		var teurl = props.event;		//  + "event";
		if (process.env.REACT_APP_TEST === 'true') {
			if (ev === 'jmt_0012021') {
				teurl = 'johnmuirtr_2012021';
			} else if (ev === 'cotswold_0012021') {
				teurl = 'cotswoldwa_2041';
			} else if (ev === 'nh48_0012021') {
				teurl = 'nh4000foot_5012021';
			} else if (ev === 'lejog_0012021') {
				teurl = 'lejogevent';
			} else {	//} if (ev === 'pct_2054' || ev === 'cdt_3012021' || ev === 'at_3012021') {
				teurl = 'johnmuirtr_2012021';
			}
		}
		if (ev === 'jmt_0012021') {
			setEclass('eventjmt');
		} else if (ev === 'cotswold_0012021') {
			setEclass('eventcotswold');
		} else if (ev === 'nh48_0012021') {
			setEclass('eventnh48');
			setHaveGear(true);
		} else if (ev === 'lejog_0012021') {
			setEclass('eventlejog');
		} else if (ev === 'pct_2054') {
			setEclass('eventpct');
		} else if (ev === 'cdt_3012021') {
			setEclass('eventcdt');
		} else if (ev === 'at_3012021') {
			setEclass('eventat');
		} else if (ev === 'pennine_way_08272021') {
			setEclass('eventpennine');
		} else if (ev === 'oregon_challenge_08272021') {
			setEclass('eventoregon');
		} else if (ev === 'tour_de_mont_blanc') {
			setEclass('eventtmb');
		}
		setEurl(teurl);

		console.log('Event View: getting site by url');
		var url = wisp_urls.site_get_by_url + "?url=" + teurl;
		fetchGet(url, getSiteResult, wispData, true, '');

        if (curPageId != 0) {
            var url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
            fetchGet(url, getDocMapsResult, wispData, true);
		}
		setNavOpen(!wispData.mobileView);

	}, [curPageId]);

	function ShopifyBuyInit() {
		setShopifyInit(true);
		var client = window.ShopifyBuy.buildClient({
			domain: 'crazy-does.myshopify.com',
			storefrontAccessToken: '45f7a50b3fc023616f8bb06c9b0e676c',
		});
		window.ShopifyBuy.UI.onReady(client).then(function (ui) {
			ui.createComponent('collection', {
				id: '238833991871',
				node: document.getElementById('collection-component-1627327490536'),
				moneyFormat: '%24%7B%7Bamount%7D%7D',
				options: {
					"product": {
						"styles": {
						"product": {
							"@media (min-width: 601px)": {
							"max-width": "calc(25% - 20px)",
							"margin-left": "20px",
							"margin-bottom": "50px",
							"width": "calc(25% - 20px)"
							},
							"img": {
							"height": "calc(100% - 15px)",
							"position": "absolute",
							"left": "0",
							"right": "0",
							"top": "0"
							},
							"imgWrapper": {
							"padding-top": "calc(75% + 15px)",
							"position": "relative",
							"height": "0"
							}
						}
						},
						"buttonDestination": "modal",
						"contents": {
						"options": false
						},
						"text": {
						"button": "View product"
						}
					},
					"productSet": {
						"styles": {
						"products": {
							"@media (min-width: 601px)": {
							"margin-left": "-20px"
							}
						}
						}
					},
					"modalProduct": {
						"contents": {
						"img": false,
						"imgWithCarousel": true,
						"button": false,
						"buttonWithQuantity": true
						},
						"styles": {
						"product": {
							"@media (min-width: 601px)": {
							"max-width": "100%",
							"margin-left": "0px",
							"margin-bottom": "0px"
							}
						}
						},
						"text": {
						"button": "Add to cart"
						}
					},
					"option": {},
					"cart": {
						"text": {
						"total": "Subtotal",
						"button": "Checkout"
						}
					},
					"toggle": {}
				},
			});
		});
	}

    function getSiteResult(success, data) {
        if (success) {
            if (data.detail && data.detail === "No site exists with that url") {
                setAlertMessage({text:"Can't find this event! Please check back later!",type:'error'});
            } else {
                setSite(data);
                var url = getWispUrl(wisp_urls.site_pages_get, data.id);
                fetchGet(url, getPagesResult, wispData, false, data);
            }
         }
    }

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

    function getPagesResult(success, data, site) {
        if (success) {
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[site.id] = data;
            } else {
                var id = site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
                var url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
                fetchGet(url, getDocMapsResult, wispData, true, site);
            }
        }
	}

	function getDocMapsResult(success, data, site) {
		if (success) {
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
			// figure out the view - if member should go to first doc in list
			if (props.docId) {
				var map = getMapFromDocId(data, props.docId);
				selPanDoc(map, data);
			} else if (site) {
				var map = getFirstPageDoc(curPageId, data);	// pass data, can't count on wispData.siteDocMaps being set yet
				selPanDoc(map, data);
			}
			setForceUpdate(forceUpdate + 1);

		}
	}

	function getMapFromDocId(maps, docId) {
		var themap;
		maps.forEach((map) => {
			if (map.doc_id === parseInt(docId)) {
				themap = map;
			}
		});
		return themap;
	}

    function selPanDoc(map, siteDocMaps) {
		if (map) {
			if (wispData.mobileView) {
				setNavOpen(false);
			}
			if (!wispData.docContent[map.doc_id] && fileType(map.filename) != 'pdf' && map.url === null) {
				var url = getWispUrl(wisp_urls.document_get, map.doc_id);
				if (wispData.mobileView) {
					url += "?view=mobile";
				} else {
					url += "?view=desktop";
				}
				url += "&format=html";
				// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
				fetchGet(url, getDocResults2, wispData, true, map, {style: 'blog' }, 'html');
			} else {
				setView({ type: 'document', value: map });
				checkForNextView(curPageId, siteDocMaps);
			}
		}
	}

	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[map.doc_id] = data;
			wispData.setDocContent(content);
			setView({ type: 'document', value: map });
			checkForNextView(curPageId, wispData.siteDocMaps);
		}
	}

	function checkForNextView(map, data) {
		var map = getNextPageDoc(map, data);	// pass data, can't count on wispData.siteDocMaps being set yet

	}

    function setView(view) {
		if (view.type === 'gear') {
			if (window.ShopifyBuy && !shopifyInit) {
				if (window.ShopifyBuy.UI) {
					ShopifyBuyInit();
				}
			}	
		}
        setCurView(view);
    }

	function wisp_popup_toggle() {
		setNavOpen(!navOpen);
	}

	var guardian;
	if (wispData.mobileView) {
		var guardianIcon = faArrowRight;
		var gclass = 'closed';
		if (navOpen) {
			guardianIcon = faArrowLeft;
			gclass = 'open';
		}
		guardian = (
			<div className={gclass + ' wisp_guardian'} onClick={wisp_popup_toggle} style={{top: '260px'}}>
				<div className='wisp_m_icon'>
					<FontAwesomeIcon icon={guardianIcon} />
				</div>
			</div>
		);
	}

    var title;
    if (props.event === 'nh48_0012021') {
        title = "New Hampshire's 48 4000ft+ Peaks";
    } else if (props.event === 'lejog_0012021') {
        title = "Land's End to John O' Groats";
    } else if (props.event === 'cotswold_0012021') {
        title = "The Cotswold Way";
    } else if (props.event === 'jmt_0012021') {
        title = "The John Muir Trail";
    } else if (props.event === 'pct_2054') {
        title = "Pacific Crest Trail";
    } else if (props.event === 'cdt_3012021') {
        title = "Continental Divide Trail";
    } else if (props.event === 'at_3012021') {
        title = "Appalachian Trail";
    } else if (props.event === 'tour_de_mont_blanc') {
        title = "TMB";
    }
	
	var docView;
    if (curView) {
		if (docMapsLoaded && curView.type === 'document'){
			var cls = '';
			if (navOpen) {
				cls = 'float';
			}
			if(curView.value.url != null && curView.value.url != '') {
				var url = curView.value.url;
				var i = url.lastIndexOf('/');
				if (i > 0) {
					url = 'https://www.youtube.com/embed' + url.substring(i);
				}
				docView = (
					<div className={'wispPageContainer wispBlog ' + cls} style={{marginTop: '80px', width: '100%', height: '100%'}}>
						<iframe width='100%' height='100%' style={{ minHeight: '500px' }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						<NextLink siteDocMaps={wispData.siteDocMaps} value={curView.value} curPageId={curPageId} selPanDocFN={selPanDoc} />
					</div>
				);
			} else if (fileType(curView.value.filename) !== 'pdf') {
				if (site) {
					docView = (
						<DocView site={site} navOpen={navOpen} docId={curView.value.doc_id} backMes='home page' goBack />
					);
				}
			} else {
				var url = getWispUrl(wisp_urls.document_get, curView.value.doc_id) + '?format=native&view=mobile';
				var pages=[];
				for(var i = 0; i < numPages; i++) {
					var loadingPage = (
						<div className='pdfLoadPageDiv'>
							<FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: 'rgb(45,120,205)', marginTop: '3px', marginRight: '2px' }} />
						</div>
					);
					pages.push(
						<Page key={i+1} pageNumber={i+1} loading={loadingPage} />
					);
				}

				var file = {
					url: url,
					httpHeaders: {'Authorization': 'Bearer ' + wispData.token, 'user-id': wispData.publicState.wispUserId},
				};

				var loadingContent = (
					<div className='pdfLoadDocDiv'>{'Loading ' + curView.value.filename + '...'}</div>
			    );

				// set document style class - wispBlog or wispClassic
				docView = (
					<div className={'wispPageContainer wispBlog ' + cls}>
						<Document file={file} onLoadSuccess={onDocumentLoadSuccess} loading={loadingContent}>
							{pages}
						</Document>
					</div>
				);
			}
		}
	}

	var t = '570px';
	if (wispData.mobileView) {
		t = '250px';
	}

    var tnav;
    if (site && navOpen) {
        tnav = (
			<WispNav site={site} curPageId={curPageId} setViewFn={setView} curView={curView} selPanDocFN={selPanDoc} type='Story' top={t} gear={haveGear} />
        );
	}
	
	var nav = (
		<div style={{position: 'relative'}}>
			{tnav}
			{guardian}
		</div>
	)

	var alertDiv;
	if (alertMessage.text !== '') {
		console.log('alert = ' + alertMessage.text);
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage.text} type={alertMessage.type} />
			</div>
		);
	}

	var nextLink;

	var c = 'eventViewCont';
	if (window.cordova) {
		c = 'eventViewContMobile';
	}

	var display = 'none';
	if (curView && curView.type === 'gear') {
		display = 'block';
	}

    return (
        <div className={c}>
            <div className={eclass + 'Main'} >
                <div className="eventMainTitle">
                    {title}
                </div>
				{nav}
                <div className="eventMainTextCont">
                    {docView}
                    {alertDiv}
                </div>
				<div className='gearDiv' style={{display: display}}>
					<div id='collection-component-1627327490536'></div>
				</div>
				{nextLink}
            </div>
        </div>
    );
}