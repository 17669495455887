import React, {useContext, useEffect} from 'react';
import { HTMLRenderer } from './components/HTMLRenderer';

export const FAQView = (props) => {
    return (
        <div className='pageContainer'>
            <HTMLRenderer
                url='https://api.crazydoes.com/document/5732016788078592?style=classic'
            />
        </div>
    );
}