import React, {useCallback, useContext, useEffect} from 'react';
import { WispIconButton } from './WispIconButton';
import { WispNotification } from './WispNotification';
import { WispContext } from '../WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faImage, faShareSquare, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import {_base64ToUint8Array, getDateStr} from '../util/base.js';
import { fileType } from '../util/document.js';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { Geolocation } from '@capacitor/geolocation';
import { useDropzone } from 'react-dropzone';
import $ from 'jquery';

var tuploading = false;
export const Uploader = (props) => {

	const [alertDocMessage, setAlertDocMessage] = React.useState('');
    const [curChunk, setCurChunk] = React.useState(1);
    const [numChunks, setNumChunks] = React.useState(null);
    const [fileBuffer, setFileBuffer] = React.useState(null);
    const [uploading, setUploading] = React.useState(false);
    const [processing, setProcessing] = React.useState(false);
    const [delaySecond, setDelaySecond] = React.useState(0);
    const [newDoc, setNewDoc] = React.useState(null);

    var wispData = useContext(WispContext);
    var chunkSize = 500000;

	useEffect(() => {
        if (!uploading) {
            tuploading = true;
            setUploading(true);
            uploadDoc();
        }
        return () => { console.log(' UPLOADER UNMOUNTED'); };
	}, []);

	function uploadDoc() {
		console.log('uploadDoc');

        var fileBytes = props.bytes;
        // how many chunks??
        var nChunks = parseInt(fileBytes.byteLength/chunkSize);
        if (fileBytes.byteLength > (nChunks * chunkSize)) {
            nChunks += 1;
        }
        console.log('nChunks = ' + nChunks);
		setAlertDocMessage("");
        setCurChunk(1);
        setNumChunks(nChunks);
        setFileBuffer(fileBytes);

        uploadChunk(1, nChunks, fileBytes);

    }

    function doProcessDelay(second) {
        setDelaySecond(second);
        if (second === 15) {
            setProcessing(false);
            var tnewDoc;
            setNewDoc(newDoc => {tnewDoc = newDoc; return (newDoc)});
            props.doneFN(tnewDoc);
        } else {
            setTimeout(function () { 
                doProcessDelay(second + 1);
            }, 1000);
        }
         
    }

    function uploadChunk(nChunk, nChunks, fileBytes) {
        var starti = (nChunk-1) * chunkSize;
        var endi = starti + chunkSize;
        if (nChunk === nChunks) {
            endi = starti + (fileBytes.byteLength % chunkSize);
        }
        /* var bytes = new Uint8Array(endi - starti);
        for (var i = starti; i < endi; i++) {
            bytes[i] = fileBytes[i];
        } */
        var bytes = new Uint8Array(fileBytes.slice(starti, endi));
        // var blob = new Blob(bytes);
        // get the boxId from the first page
        var boxId = '';
        var pages = wispData.sitePages[props.site.id];
        if (pages) {
            var page = pages[0];
            var box = page.boxes[0];
            boxId = box.id;
        }
        var token = wispData.token;

        var alt = props.alt;
        var lat = props.lat;
        var long = props.long;
        var date_time = props.date_time;
        if (date_time === null) {
            date_time = '';
		}

        var headers = {
            'app-id':  process.env.REACT_APP_ID,
            'Authorization': 'Bearer ' + token,
            'user-id': wispData.publicState.wispUser.id,
            'box-id': boxId,
            'position': 'first',
            'filename': props.name,
            'site-url': props.site.url,
            'source': 'chunk',
            'chunk-num': nChunk,
            'num-chunks': nChunks,
            'lat': lat,
            'long': long,
            'alt': alt,
            'date-time': date_time,
        }
        var url = wisp_urls.document_create;
        console.log('url = ' + url);
        fetch(url, { method: 'POST', headers: headers, body: bytes })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
                if (nChunk < nChunks) {
                    setCurChunk(nChunk+1);
                    uploadChunk(nChunk+1, nChunks, fileBytes);
                } else {
                    var timeout = 0;
                    if (fileType(props.name) === 'mov') {	// allow time for video processing
                        timeout = 15000;
                        console.log('delaying for video processing');
                        setProcessing(true);
                        setNewDoc(data);
                        doProcessDelay(1);
                    } else {
                        console.log('file uploaded');
                        props.doneFN(data);
                    }
                    // setTimeout(function () { 
                        /* if ('detail' in data) {
                            if (data['detail'] === 'exceeded storage') {
                                setAlertDocMessage("You've exceeded your 1 GB storage limit");
                            } else {
                                setAlertDocMessage("Failed to add the document(s) to this " + props.type);
                            }
                        } else if (realTimeUploadCount === 0) {
                            // var url = getWispUrl(wisp_urls.site_docMaps_get, props.site.id);
                            // fetchGet(url, getDocMapsResult, wispData, true);
                            props.addFN();
                        } */
                    // }, timeout);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                props.doneFN();
            });
	}



    var alertDiv;
    if (alertDocMessage !== '') {
        alertDiv = <WispNotification message={alertDocMessage} type='error' />
	}
    var content = (
        <React.Fragment>
            <div>uploading...</div>
            <div style={{margin: '0px 0px 0px 5px'}}>
                {parseInt(curChunk/numChunks*100)}%
            </div>
        </React.Fragment>
    );
    if (processing) {
        content = (
            <React.Fragment>
                <div>processing...</div>
                <div style={{margin: '0px 0px 0px 5px'}}>
                    {parseInt(delaySecond/15*100)}%
                </div>
            </React.Fragment>
        )
    }

    return (
        <div className='uploaderCont'>
            {content}
           {alertDiv}
        </div>
    );
}

