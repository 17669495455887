import { truncateName } from './base.js';
import { Camera, CameraResultType } from '@capacitor/camera';
import { fetchGet, getWispUrl, wisp_urls} from './fetch.js';
import {_base64ToArrayBuffer, _base64ToUint8Array} from './base.js';
import { Geolocation } from '@capacitor/geolocation';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { getDateStr } from '../util/base.js';

var mov_locationIndex = -1;
var mov_keyCount = 0;
var mov_location = '';
var mov_creationDate = '';
var mov_creationDateIndex = -1;

export function fileType(filename) {
	var tfn = filename.toLowerCase();
	if (tfn.indexOf('.pdf') >= 0) {
		return 'pdf';
	} else if (tfn.indexOf('.docx') >= 0) {
		return 'docx';
	} else if (tfn.indexOf('.xlsx') >= 0 || tfn.indexOf('.xlsm') >= 0) {
		return 'xlsx';
	} else if (tfn.indexOf('.mov') >= 0) {
		return 'mov';
	} else if (tfn.indexOf('.jpg') >= 0 || tfn.indexOf('.jpeg') >= 0) {
		return 'jpeg';
	} else if (tfn.indexOf('.png') >= 0) {
		return 'png';
	}
    return 'unknown';
}

export function fileTypeIsSupported(fType) {
	if (fType === 'docx' || fType === 'xlsx' || fType === 'pdf') {
		return true;
	}
	return false;
}

export function getDocName(filename, wispData) {
	var name = filename;
	name = name.charAt(0).toUpperCase() + name.slice(1);
	var i = name.indexOf('.');
	if (i > 0) {
		name = name.substring(0, i);
	}
	var trunc = 30;
	if (wispData.mobileView) {
		trunc = 24;
	}
	name = truncateName(name,trunc);
	return name;
}

export const getVidMetaData = async (vidBytes, videoUri, doneFN) => {
	var lat, long, alt;
	var date = new Date();
	var date_time = date.toUTCString();
	// see if we can get the location info from the file
	var tpath = videoUri.toLowerCase();
	if (tpath.indexOf('.mov') > 0) {
		// let arrayBuffer = new Uint8Array(reader.result);
		// var buf = arrayBuffer.buffer;
		var dataview = new DataView(vidBytes.buffer, 0);
		var index = 0;
		mov_locationIndex = -1;
		mov_keyCount = 0;
		mov_location = '';
		mov_creationDate = '';
		mov_creationDateIndex = -1;

		// count is a safety check
		for (var count = 0; index < vidBytes.byteLength && count < 300; count++) {
			index += readAtom(dataview, index, null);
		}
		if (mov_location !== '') {
			console.log('got mov_location');
			// extract lat, long and alt - typical value is +40.4169-111.9089+1388.960/ which is lat long alt
			var longiminus = mov_location.indexOf('-', 1);
			var longiplus = mov_location.indexOf('+', 1);
			var longi = longiminus;
			if (longiminus === -1 || (longiplus > 0 && longiplus < longiminus)) {
				longi = longiplus;
			}
			console.log('longi = ' + longi);
			if (longi > 0) {
				var altiminus = mov_location.indexOf('-', longi+1);
				var altiplus = mov_location.indexOf('+', longi+1);
				var alti = altiminus;
				if (altiminus === -1 || (altiplus > 0 && altiplus < altiminus)) {
					alti = altiplus;
				}
				console.log('alti = ' + alti);
				if (alti > longi) {
					lat = mov_location.substring(0,longi);
					long = mov_location.substring(longi,alti);
					alt = mov_location.substring(alti, mov_location.length-1);
				}
			}
		}
		if (mov_creationDate !== '') {
			date_time = mov_creationDate;
			// seeing format like 2021-08-16T14:22:26-0800 where 14:22 is local time and the -0800 isn't helpful
			// not sure the -0800 isn't helpful - it's offset from GMT I believe
			// well, I think it just gets used to set the time zone. So if the video says 4:30 pm and it's got -6:00 then it think 10:30 MT
			var ti = date_time.lastIndexOf('+');
			var ti2 = date_time.lastIndexOf(':');
			if (ti === -1) {
				ti = date_time.lastIndexOf('-');
			}
			if (ti > 0 && ti > ti2) {
				date_time = date_time.substring(0);
			}
			console.log('got date_time = ' + date_time);
		}
	}

	if (!lat) {
		console.log('trying to get lat from Geolocation');
		try {
			const permissions = await Geolocation.requestPermissions();
			console.log('permissions = ' + JSON.stringify(permissions));
			const coordinates = await Geolocation.getCurrentPosition();
			if (coordinates) {
				console.log('coordinates = ' + JSON.stringify(coordinates));
				var coords = coordinates.coords;
				if (coords && coords.latitude && coords.longitude) {
					lat = coords.latitude;
					long = coords.longitude;
				}
			}
		} catch(err) {
			console.log('Geolocation error = ' + err);
		}	
	} else {
		console.log('got lat previously');
	}
	doneFN(vidBytes, videoUri, alt, lat, long, date_time);
}

export const takePicture = async (wispData, site, takePicture2) => {
	/* navigator.camera.getPicture(onSuccess, onFail, { quality: 50,
		destinationType: Camera.DestinationType.FILE_URI }); */

	const permissions = await Camera.checkPermissions();
	console.log('permissions = ' + JSON.stringify(permissions));
	
	const image = await Camera.getPhoto({
	  quality: 90,
	  allowEditing: true,
	  resultType: CameraResultType.Base64,			// Uri, Base64
	}).catch((e) => {
		console.log('error = ' + e);
		takePicture2(false);
		return;
    });
  
	// image.webPath will contain a path that can be set as an image src.
	// You can access the original file using image.path, which can be
	// passed to the Filesystem API to read the raw data of the image,
	// if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
	// var imageUrl = image.webPath;
	console.log('exif = ' + JSON.stringify(image.exif));

	var boxId = '';
	var pages = wispData.sitePages[site.id];
	if (pages) {
		var page = pages[0];
		var box = page.boxes[0];
		boxId = box.id;
	}
	var token = wispData.token;

	var headers = {
		'Authorization': 'Bearer ' + token,
		'user-id': wispData.publicState.wispUser.id,
		'box-id': boxId,
		'position': 'first',
		'site-url': site.url,
		'source': 'file_bytes',
	};

	var gotLatLong = false;
	if (image.exif) {
		if (image.exif.GPS && image.exif.GPS.Latitude) {
			headers.lat = image.exif.GPS.Latitude;
		}
		if (image.exif.GPS && image.exif.GPS.Longitude) {
			headers.long = image.exif.GPS.Longitude;
			if (image.exif.GPS.LongitudeRef && image.exif.GPS.LongitudeRef === 'W') {
				headers.long = -headers.long;
				gotLatLong = true;
			}
		}
	}
	if (!gotLatLong) {	// this seems to happen if you use the camera versus grabbing a photo
		const coordinates = await Geolocation.getCurrentPosition();
		if (coordinates) {
			console.log('coordinates = ' + JSON.stringify(coordinates));
			var coords = coordinates.coords;
			if (coords && coords.latitude && coords.longitude) {
				headers.lat = coords.latitude;
				headers.long = coords.longitude;
			}
		}
	}

	var date_time = new Date();
	date_time = date_time.toUTCString();
	if (image.exif) {
		if (image.exif.DateTimeOriginal) {
			date_time = image.exif.DateTimeOriginal;
			// this date time is in the timezone where the photo was taken
			// there's a OffsetTimeOriginal field

			/*
			// what time zone is that? Can use Google's Time Zone API

			var url = 'https://maps.googleapis.com/maps/api/timezone/json?location=';
			url += headers.lat;
			url += ',';
			url += headers.long;
			url += '&timestamp=1331161200&key=AIzaSyC_0yZlUYxSO0TKuO4FgOIPytyHQWda9pI';

			fetch(url, {method: 'GET', mode: 'cors'})
			.then(function(response) {
				return response.json();
			})
			.then(data => {
				console.log('timezone = ' + JSON.stringify(data));
				var offset = parseInt(data.rawOffset);
				// The offset from UTC (in seconds) for the given location. This does not take into effect daylight savings.
				offset = offset / 60 / 60;
			*/
			var offset = 0;
			if (image.exif.OffsetTimeOriginal) {
				offset = parseInt(image.exif.OffsetTimeOriginal);
			}

			// this format will be something like 2021:06:01 17:16:40
			// the format we want is something like 2021-04-29 (16:59:26.818) MDT
			console.log('date_time 111 = ' + date_time);
			try {
				/* date_time = date_time.replace(':', '-');
				date_time = date_time.replace(':', '-');
				console.log('date_time 2 = ' + date_time);
				var millis = Date.parse(date_time + ' GMT');
				console.log('millis = ' + millis);
				var tdate = new Date(millis);
				date_time = tdate.toUTCString(); */
				var i = date_time.indexOf(' ');
				var dt = date_time.substring(0,i) + 'T' + date_time.substring(i+1);		// + '-09:00';	// ' + offset + ':00';
				console.log('date_time 2 = ' + dt);
				var arr = dt.split(/[\-\+ :T]/);
		
				var date = new Date();
				date.setUTCFullYear(arr[0]);
				date.setUTCMonth(arr[1] - 1);
				date.setUTCDate(arr[2]);
				date.setUTCHours(parseInt(arr[3]) - offset);
				date.setUTCMinutes(arr[4]);
				date.setUTCSeconds(arr[5]);
		
				//var millis = Date.parse(dt + ' GMT');
				//console.log('millis = ' + millis);
				//var tdate = new Date(millis);
				date_time = date.toISOString();
				// toUTCString() follows ECMAScript 2018 and is a string in the form Www, dd Mmm yyyy hh:mm:ss GMT,
				// server is looking for '%Y-%m-%dT%H:%M:%S-%f' - ISO 8601 format
				console.log('date_time 3 = ' + date_time);
				/*
					takePicture2(wispData, site, onDone, date_time, headers, image);
				} catch(err) {
					console.log('error = ' + err);
				}	
				console.log('date_time 3 = ' + date_time); */
			} catch(err) {
				console.log('error = ' + err);
			}		
		}
	}
	takePicture2(true, wispData, site, date_time, headers, image);
}
/*
function takePicture2(wispData, site, onDone, date_time, headers, image) {

	var filename = 'camera_' + date_time + '.' + image.format;
	console.log('filename = ' + filename);
	// no content type?

	headers.filename = filename;
	headers['date-time'] = date_time;

	console.log('headers = ' + JSON.stringify(headers));

	var imgBytes = _base64ToArrayBuffer(image.base64String);

	var url = wisp_urls.document_create;
	
	fetch(url, { method: 'POST', headers: headers, body: imgBytes })	//  mode: 'cors', headers: headers,
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			if ('detail' in data) {
			} else {
				var turl = getWispUrl(wisp_urls.site_docMaps_get, site.id);
				fetchGet(turl, getDocMapsResult, wispData, true, wispData);
			}
			onDone(true, data);
		})
		.catch((error) => {
			console.error('Error:', error);
			onDone(false);
		});
}
*/
function getDocMapsResult(success, data, wispData) {
	if (success) {
		wispData.setSiteDocMaps(data);
	}
}

export const readVideoFile = async (vidPath, site, wispData, setAlertDocMessage, type, setGettingVideo, doneFN) => {
	console.log('vidPath = ' + vidPath);

	setAlertDocMessage({text: '', type: 'error'});
	const contents = await Filesystem.readFile({
	path: vidPath,
	});
	console.log('got video contents');
	// console.log(JSON.stringify(contents));

	
	// upload the video
	var date = new Date();
	var date_time = date.toUTCString();
	var filename = site.name + ' ' + getDateStr(date, false) + ".mov";
	var token = wispData.token;
	var boxId = '';
	var pages = wispData.sitePages[site.id];
	if (pages) {
		var page = pages[0];
		var box = page.boxes[0];
		boxId = box.id;
	} else {
		setAlertDocMessage({text:'Video upload error. Please contact ' + process.env.REACT_APP_NAME + ' support.',type:'error'});
		return;
	}
	var vidBytes = _base64ToUint8Array(contents.data);  // vidBytes is an ArrayBuffer

	var lat, long, alt;
	// see if we can get the location info from the file
	var tpath = vidPath.toLowerCase();
	if (tpath.indexOf('.mov') > 0) {
		// let arrayBuffer = new Uint8Array(reader.result);
		// var buf = arrayBuffer.buffer;
		var dataview = new DataView(vidBytes.buffer, 0);
		var index = 0;
		mov_locationIndex = -1;
		mov_keyCount = 0;
		mov_location = '';
		mov_creationDate = '';
		mov_creationDateIndex = -1;

		// count is a safety check
		for (var count = 0; index < vidBytes.byteLength && count < 300; count++) {
			index += readAtom(dataview, index, null);
		}
		if (mov_location !== '') {
			console.log('got mov_location');
			// extract lat, long and alt - typical value is +40.4169-111.9089+1388.960/ which is lat long alt
			var longiminus = mov_location.indexOf('-', 1);
			var longiplus = mov_location.indexOf('+', 1);
			var longi = longiminus;
			if (longiminus === -1 || (longiplus > 0 && longiplus < longiminus)) {
				longi = longiplus;
			}
			console.log('longi = ' + longi);
			if (longi > 0) {
				var altiminus = mov_location.indexOf('-', longi+1);
				var altiplus = mov_location.indexOf('+', longi+1);
				var alti = altiminus;
				if (altiminus === -1 || (altiplus > 0 && altiplus < altiminus)) {
					alti = altiplus;
				}
				console.log('alti = ' + alti);
				if (alti > longi) {
					lat = mov_location.substring(0,longi);
					long = mov_location.substring(longi,alti);
					alt = mov_location.substring(alti, mov_location.length-1);
				}
			}
		}
		if (mov_creationDate !== '') {
			date_time = mov_creationDate;
			// seeing format like 2021-08-16T14:22:26-0800 where 14:22 is local time and the -0800 isn't helpful
			var ti = date_time.lastIndexOf('+');
			var ti2 = date_time.lastIndexOf(':');
			if (ti === -1) {
				ti = date_time.lastIndexOf('-');
			}
			if (ti > 0 && ti > ti2) {
				date_time = date_time.substring(0,ti);
			}
			console.log('got date_time = ' + date_time);
		}
	}

	if (!lat) {
		try {
			const permissions = await Geolocation.requestPermissions();
			console.log('permissions = ' + JSON.stringify(permissions));
			const coordinates = await Geolocation.getCurrentPosition();
			if (coordinates) {
				console.log('coordinates = ' + JSON.stringify(coordinates));
				var coords = coordinates.coords;
				if (coords && coords.latitude && coords.longitude) {
					lat = coords.latitude;
					long = coords.longitude;
				}
			}
		} catch(err) {
			console.log('error = ' + err);
		}	
	}
	var headers = {
		'app-id':  process.env.REACT_APP_ID,
		'Authorization': 'Bearer ' + token,
		'user-id': wispData.publicState.wispUser.id,
		'box-id': boxId,
		'position': 'first',
		'filename': filename,
		'lat': lat,
		'long': long,
		'alt': alt,
		'filename': filename,
		'source': 'file_bytes',
		'date-time': date_time,
		'site-url': site.url,
	}
	var url = wisp_urls.document_create;
	console.log('url = ' + url);
	fetch(url, { method: 'POST', headers: headers, body: vidBytes })	//  mode: 'cors', headers: headers,
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			if ('detail' in data) {
				if (data['detail'] === 'exceeded storage') {
					setAlertDocMessage({text:"You've exceeded your 1 GB storage limit",type:'error'});
				} else {
					setAlertDocMessage({text:"Failed to add the document(s) to this " + type,type:'error'});
				}
			} else {
			}
			setTimeout(function () { 
				setGettingVideo(false);
				doneFN();
				// var url = getWispUrl(wisp_urls.site_docMaps_get, site.id);
				// fetchGet(url, getDocMapsResult, wispData, true);
				// url = getWispUrl(wisp_urls.site_pages_get, site.id);
				// fetchGet(url, getPagesResult, wispData, true);
			}, 15000);
		})
		.catch((error) => {
			console.error('Error:', error);
			setAlertDocMessage({text:'Video upload error. Please try again later.',type:'error'});
			setGettingVideo(false);
			doneFN();
		});
};

function readAtom(dataview, index, parent) {
	var atomSize = 10000;
	try {
		atomSize = dataview.getUint32(index);
		// console.log('atomSize = ' + atomSize);
		if (atomSize === 1 || atomSize === 0) {
			atomSize = 5;
		}
		var atomType = getAtomType(dataview, index+4);
		// console.log('atomType = ' + atomType);
		var endIndex = index + atomSize;
		if (atomType === 'moov') {
			var tindex = index + 8;
			while (tindex < endIndex) {
				tindex += readAtom(dataview, tindex, atomType);
			}
		} else if(atomType === 'meta') {
			var tindex = index + 8;
			while (tindex < endIndex) {
				// console.log('meta read');
				tindex += readAtom(dataview, tindex, atomType);
				// console.log('meta indices = ' + tindex + ',' + endIndex);
			}			
		} else if(atomType === 'keys' && parent === 'meta') {
			var nKeys = dataview.getUint32(index+12);	// skip atomSize, atomType and Version & flags
			var tindex = index + 16;	// same skip as above + nKeys
			for(var nKey = 0;nKey < nKeys;nKey++) {
				var keySize = dataview.getUint32(tindex);
				var keyName = getStringFromView(dataview,tindex+8,keySize-8, false);	// skip keySize and namespace
				// console.log('keyName = ' + keyName);
				if (keyName === "com.apple.quicktime.location.ISO6709") {
					mov_locationIndex = nKey + 1;
				} else if (keyName === 'com.apple.quicktime.creationdate') {
					mov_creationDateIndex = nKey + 1;
				}
				tindex += keySize;
			}
			mov_keyCount = nKeys;
			console.log('locationKeyIndex = ' + mov_locationIndex);
			console.log('mov_creationDateIndex = ' + mov_creationDateIndex);
		} else if(atomType === 'ilst' && parent === 'meta' && mov_keyCount > 0 && mov_keyCount < 1000) {
			var tindex = index + 8;
			while (tindex < endIndex) {
				var tatomSize = dataview.getUint32(tindex);
				// console.log('tatomSize = ' + tatomSize);
				if (tatomSize === 1 || tatomSize === 0) {
					tatomSize = 5;
				}
				var keyIndex = dataview.getUint32(tindex+4);
				console.log('keyIndex = ' + keyIndex);
				if (keyIndex === mov_locationIndex && mov_locationIndex != -1) {
					readAtom(dataview, tindex+8, 'mov_location');
				} else if (keyIndex === mov_creationDateIndex && mov_creationDateIndex != -1) {
					readAtom(dataview, tindex+8, 'mov_createDate');
				}
				tindex += tatomSize;
			}
			// console.log('done with ilst');
		} else if(atomType === 'data' && parent === 'mov_location') {
			mov_location = getStringFromView(dataview,index+16,atomSize-16, false);	// The Data atom has an atom type of ‘data’, and contains four bytes each of type and locale indicators - so not sure why incrementing by 16...
			console.log('mov_location = ' + mov_location);
			// example coords are +40.4169-111.9089+1388.960/
		} else if(atomType === 'data' && parent === 'mov_createDate') {
			mov_creationDate = getStringFromView(dataview,index+16,atomSize-16, false);
			console.log('mov_creationDate = ' + mov_creationDate);
		}
	} catch(err) {
		console.log('readAtom error!');
	}
	// console.log('returning atomSize = ' + atomSize);
	return atomSize;
}

function getAtomType(dataview, i) {
	var str = String.fromCharCode(dataview.getUint8(i)) +
		String.fromCharCode(dataview.getUint8(i+1)) +
		String.fromCharCode(dataview.getUint8(i+2)) +
		String.fromCharCode(dataview.getUint8(i+3));

	return str;
}

function getStringFromView(dataview, i, size, log) {
	var str = '';
	for(var ti = 0;ti < size;ti++) {
		var u = dataview.getUint8(i + ti);
		var c = String.fromCharCode(u);
		if (log) {
			console.log('u = ' + u + ' and c = ' + c);
		}
		str += c
	}
	return str;
}

export function replaceDoc(wispData, source, docId, data, onDone) {
	if (data === null) {
		return;
	}
	var token = wispData.token;
	var headers = {
		'Authorization': 'Bearer ' + token,
		'app-id': process.env.REACT_APP_ID,
		'user-id': wispData.publicState.wispUser.id,
		'source': source,
	}
	var url = getWispUrl(wisp_urls.document_replace, docId);
	fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(data) })
		.then(function (response) {
			console.log('response.status = ' + response.status); // Will show you the status
			return response.json();
		})
		.then(data => {
			console.log('success = ' + data);
			if (onDone) {
				onDone();
			}
		})
		.catch((error) => {
			console.error('Error:', error);
		});

}

export function getLinkedDoc(maps, doc_id) {
	var result;
	maps.forEach((map) => {
		if (map.link_doc_id === doc_id.toString()) {
			result = map;
		}

	});

	return result;
}
