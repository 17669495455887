import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from '../WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCircle as fasCircle, faExclamation, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { WispButton } from './WispButton';
import { WispNotification } from './WispNotification';
import { WispSelect } from './WispSelect';
import { Note } from './Note';
import { StoryPickerPopup } from '../popups/StoryPickerPopup';
import { YouTubePopup } from '../popups/YouTubePopup';
import { fetchGet, getWispUrl, wisp_urls} from '../util/fetch.js';
import { takePicture, fileType, readVideoFile } from '../util/document.js';
import { useDropzone } from 'react-dropzone';
import {_base64ToUint8Array} from '../util/base.js';
import RefreshPopup from '../popups/RefreshPopup.js';
import PositionPopup from '../popups/PositionPopup.js';

export const DocManager = (props) => {

	const [madeChange, setMadeChange] = React.useState(false);
    const [selectedDocs, setSelectedDocs] = React.useState({});
	const [numSelected, setNumSelected] = React.useState(0);
	const [alertDocMessage, setAlertDocMessage] = React.useState({text: '', type: 'warning'});
	const [barBusy, setBarBusy] = React.useState(false);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [showStoryPicker, setShowStoryPicker] = React.useState(false);
	const [showYouTubePopup, setShowYouTubePopup] = React.useState(false);
	const [showNote, setShowNote] = React.useState(false);
	const [takingPicture, setTakingPicture] = React.useState(false);
	const [gettingVideo, setGettingVideo] = React.useState(false);
	const [uploadCount, setUploadCount] = React.useState(0);
	const [refreshPrompt, setRefreshPrompt] = React.useState(false);
	const [showPosPopup, setShowPosPopup] = React.useState(false);
	const [selectedDoc, setSelectedDoc] = React.useState(null);
	const [docAlgs, setDocAlgs] = React.useState({});

    var wispData = useContext(WispContext);

	var realTimeUploadCount = 0;		// can't count on uploadCont being up to date - gonna lag as crappy state stuff does...

	useEffect(() => {
		if (!props.new) {
			refreshPageDocs();
        }
		console.log('wispData.showUpdateMes = ' + wispData.showUpdateMes);
		if (wispData.showUpdateMes) {
			setAlertDocMessage({text: 'You are now authenticated to OneDrive. You can update your documents now.', type: 'success'});
			wispData.setShowUpdateMes(false);
		}
	}, []);

	function refreshPageDocs() {
		var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
		fetchGet(url, getPagesResult, wispData, true);
	}

	function onSuccess(result) {
		// convert JSON string to JSON Object
		console.log('result = ' + result);
		// var thisResult = JSON.parse(result);
	 
	/*	// convert json_metadata JSON string to JSON Object 
		var metadata = JSON.parse(thisResult.json_metadata);
	 
		 var image = document.getElementById('myImage');
		 image.src = thisResult.filename
	 
		 if (thisResult.json_metadata != "{}") {
			 if (device.platform == 'iOS') {
	 
			   // notice the difference in the properties below and the format of the result when you run the app.
			   // iOS and Android return the exif and gps differently and I am not converting or accounting for the Lat/Lon reference.
			   // This is simply the raw data being returned.
	 
			   navigator.notification.alert('Lat: '+metadata.GPS.Latitude+' Lon: '+metadata.GPS.Longitude);
			 } else {
				navigator.notification.alert('Lat: '+metadata.gpsLatitude+' Lon: '+metadata.gpsLongitude);
			 }
	 
		 } */
	 }
	 
	 function onFail(message) {
		 alert('Failed because: ' + message);
	 }
	
	
	function getDocMapsResult(success, data) {
		if (success) {
			wispData.setSiteDocMaps(data);
		}
	}

	function MyDropzone() {
		const onDrop = useCallback(acceptedFiles => {
		  // Do something with the files
			acceptedFiles.forEach((file) => {
				uploadDoc(file)
			//const reader = new FileReader()
	  
			//reader.onabort = () => console.log('file reading was aborted')
			//reader.onerror = () => console.log('file reading has failed')
			//reader.onload = () => {
			//	// Do whatever you want with the file contents
			//	const binaryStr = reader.result
			//	// console.log(binaryStr)
			//	// var byteArray = new Uint8Array(binaryStr);
			//	// var bytes = new [byteArray.byteLength]
			//	// for (var i = 0; i < byteArray.byteLength; i++) {
			//	//     bytes[i] = byteArray[i];
			//	//}
			//	uploadDoc(binaryStr, file);
			//}
			//reader.readAsBinaryString(file);        //readAsArrayBuffer(file) 
		  });
		}, [])
		const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: props.accept}) // .mov, .mp4,
	  
		// , border: 'dashed', background: '#F0F0F0', padding: '5px'
		return (
			<div className='addItem' style={{marginTop: '20px', marginLeft: '0px'}}>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					{
					isDragActive ?
					<p>Drop the files here ...</p> :
					<div style={{width: '100%'}}>
						<WispButton
							bcls='primaryButton'
							busy={uploadCount > 0}
							d='inline-block'
							width="100%"
							js={doNothing}
							m='0px'
							mes="Device" />
					</div>
					}
				</div>
			</div>
		)
	}

	function ReplaceDropzone() {
		const onDrop = useCallback(acceptedFiles => {
			// Do something with the files
			acceptedFiles.forEach((file) => {
				// get the docId of the selected file
				var docs = wispData.siteDocMaps;
				var docId = '';
				if (docs) {
					docs.forEach((docmap, i) => {
						if (selectedDocs[docmap.doc_id]) {
							docId = docmap.doc_id;
						}
					});
				}
				if (docId !== '') {
					var token = wispData.token;
					var headers = {
						'Authorization': 'Bearer ' + token,
						'app-id': process.env.REACT_APP_ID,
						'user-id': wispData.publicState.wispUser.id,
						'source': 'file',
					}
					var url = getWispUrl(wisp_urls.document_replace, docId);
					const formData = new FormData();
					formData.append('file', file);
					setBarBusy(true);
					fetch(url, { method: 'POST', headers: headers, body: formData })	//  mode: 'cors', headers: headers,
						.then(function (response) {
							console.log('response.status = ' + response.status); // Will show you the status
							return response.json();
						})
						.then(data => {
							setBarBusy(false);
							console.log('success = ' + data);
						})
						.catch((error) => {
							setBarBusy(false);
							console.error('Error:', error);
						});
				}
			});
		}, [])
		const { getRootProps, getInputProps } = useDropzone({ onDrop })

		return (
			<div className='wisp_app_link book_list_action'>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
						update
				</div>
			</div>
		)
	}

	function uploadDoc(file) { // binaryStr, file) {
		console.log('uploadDoc');

		setAlertDocMessage({text: '', type: 'error'});
		realTimeUploadCount++;
		setUploadCount(realTimeUploadCount);
		// get the boxId from the first page
		var boxId = '';
		var pages = wispData.sitePages[props.site.id];
		if (pages) {
			var page = pages[0];
			var box = page.boxes[0];
			boxId = box.id;
		}
		var token = wispData.token;
		var headers = {
            'app-id':  process.env.REACT_APP_ID,
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
			'box-id': boxId,
			'position': 'last',
			'filename': file.name,
			'site-url': props.site.url,
			'source': 'file',
		}
		var url = wisp_urls.document_create;
		console.log('url = ' + url);
		const formData = new FormData();
		formData.append('file', file);
		fetch(url, { method: 'POST', headers: headers, body: formData })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				realTimeUploadCount--;
				var timeout = 0;
				if (realTimeUploadCount === 0 && fileType(file.name) === 'mov') {	// allow time for video processing
					timeout = 10000;
					console.log('delaying for video processing');
				}
				console.log('file uploaded');
				setTimeout(function () { 
					setUploadCount(realTimeUploadCount);
					if ('detail' in data) {
						if (data['detail'] === 'exceeded storage') {
							setAlertDocMessage({text:"You've exceeded your 1 GB storage limit",type: 'error'});
						} else {
							setAlertDocMessage({text:"Failed to add the document(s) to this " + props.type,type:'error'});
						}
					} else if (realTimeUploadCount === 0) {
						refreshPageDocs();
					}
				}, timeout);
			})
			.catch((error) => {
				console.error('Error:', error);
				realTimeUploadCount--;
				setUploadCount(realTimeUploadCount);
			});
	}

	function moveSelDown() {
		if (!barBusy) {
			var busyCount = 0;
			setAlertDocMessage({text: '', type: 'error'});
			var docs = wispData.siteDocMaps;
			if (docs) {
				docs.forEach((docmap, i) => {
					if (selectedDocs[docmap.doc_id]) {
						var numDocs = getNumDocs();
						if (docmap.order === numDocs) {
							setAlertDocMessage({text:docmap.filename + ' is already the last document',type: 'error'});
						} else {
							var token = wispData.token;
							var headers = {
								'Authorization': 'Bearer ' + token,
								'user-id': wispData.publicState.wispUser.id,
								'order': docmap.order + 1,
							}
							var url = getWispUrl(wisp_urls.docmap_reorder, docmap.id);
							busyCount = busyCount + 1;
							setBarBusy(true);
							fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
								.then(function (response) {
									console.log('response.status = ' + response.status); // Will show you the status
									return response.json();
								})
								.then(data => {
									console.log('success = ' + data);
									busyCount = busyCount - 1;
									if (busyCount === 0) {
										refreshPageDocs();
										setBarBusy(false);
									}
								})
								.catch((error) => {
									console.error('Error:', error);
									busyCount = busyCount - 1;
									if (busyCount === 0) {
										setBarBusy(false);
									}
								});

						}
					}
				});
			}
		}
	}

	function canMoveSelUp() {
		if (getNumDocs() <= 1) {
			return false;
		}
		var can = true;
		var docs = wispData.siteDocMaps;
		if (docs) {
			docs.forEach((docmap, i) => {
				if (selectedDocs[docmap.doc_id]) {
					if (docmap.order === 1) {
						can = false;
					}
				}
			});
		}
		return can;
	}

	function canMoveSelDown() {
		if (getNumDocs() <= 1) {
			return false;
		}
		var can = true;
		var docs = wispData.siteDocMaps;
		if (docs) {
			docs.forEach((docmap, i) => {
				if (selectedDocs[docmap.doc_id]) {
					var numDocs = getNumDocs();
					if (docmap.order === numDocs) {
						can = false;
					}
				}
			});
		}
		return can;
	}

	function getDocUrl(map) {	// process.env.REACT_APP_PROTOCOL + "://" + 
		if (process.env.REACT_APP_SUBDOMAINS === 'true') {
			return props.site.url + '.' + process.env.REACT_APP_URL_POSTFIX + '?id=' + map.doc_id;
		} else if ( wispData.publicState.wispUser != null){
			return process.env.REACT_APP_PROTOCOL + '://' + process.env.REACT_APP_URL_POSTFIX + '/' + wispData.publicState.wispUser.id + '/' + props.site.url + '/' + map.doc_id;
		}
		return '';
	}

	// prefix will cause different behavior when onedrive redirects back
	// pass nothing to redirect back to site page (used to restablish auth to onedrive)
	// pass 'picker' to redirect to file picker
	function authOneDrive(prefix) {
		var redirectURI = process.env.REACT_APP_AZURE_REDIRECT_URI;
		window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
			'client_id=' + props.azure_client_id +
			'&response_type=code' +
			'&redirect_uri=' + redirectURI +
			'&response_mode=query' +
			'&scope=offline_access%20user.read%20files.read' +
			'&state=' + prefix + props.site.url
	}

	const getItemStyle = (isDragging, draggableStyle) => ({
		// some basic styles to make the items look a bit nicer
		// userSelect: "none",
		// padding: grid * 2,
		// margin: `0 0 ${grid}px 0`,

		// change background colour if dragging
		// background: isDragging ? "lightgreen" : "grey",

		// styles we need to apply on draggables
		...draggableStyle
	});

	const getListStyle = isDraggingOver => ({
		// background: isDraggingOver ? "lightblue" : "lightgrey",
		// padding: grid,
		// width: 250
	});

	function onDragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		// page will rerender while waiting for reorder to take place on server
		// reorder docmaps
		var oldOrder = 1;
		for (var i = 0; i < wispData.siteDocMaps.length; i++) {
			if (wispData.siteDocMaps[i].id === parseInt(result.draggableId)) {
				oldOrder = wispData.siteDocMaps[i].order;
			}
		}
		var newOrder = result.destination.index + 1;
		var docMaps = [];
		if (newOrder === oldOrder) {
			return;
		} else if (newOrder < oldOrder) {
			for (i = 1; i <= wispData.siteDocMaps.length; i++) {
				if (i < newOrder || i > oldOrder) {
					docMaps.push(wispData.siteDocMaps[i - 1]);
				} else if (i === newOrder) {
					docMaps.push(wispData.siteDocMaps[oldOrder - 1]);
				} else {
					docMaps.push(wispData.siteDocMaps[i - 2]);
				}
			}
		} else {
			for (i = 1; i <= wispData.siteDocMaps.length; i++) {
				if (i > newOrder || i < oldOrder) {
					docMaps.push(wispData.siteDocMaps[i - 1]);
				} else if (i === newOrder) {
					docMaps.push(wispData.siteDocMaps[oldOrder - 1]);
				} else {	// i = 1 and newOrder = 2, then want the second item in list, which is 1
					docMaps.push(wispData.siteDocMaps[i]);
				}
			}
		}

		wispData.setSiteDocMaps(docMaps);

		var token = wispData.token;
		var headers = {
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
			'order': newOrder,
		}
		var url = getWispUrl(wisp_urls.docmap_reorder, result.draggableId);
		setBarBusy(true);
		fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				refreshPageDocs();
				setBarBusy(false);
			})
			.catch((error) => {
				console.error('Error:', error);
				setBarBusy(false);
			});

	}

	function doNothing() {

	}

	function toggleDoc(id) {
		var curValue = selectedDocs[id];
		var tnumSelected = numSelected;
		if (curValue === true) {
			selectedDocs[id] = false;
			tnumSelected = tnumSelected - 1;
		} else {
			selectedDocs[id] = true;
			tnumSelected = tnumSelected + 1;
		}
		setSelectedDocs(selectedDocs);
		setNumSelected(tnumSelected);
		getSelectedDoc(wispData.wispDocs);	// can't call this in render - it will update state
	}

	function delSel() {
		if (!barBusy) {
			// iterate through pages & their docmaps - not the site docs which don't have boxes
			// goes through every page on every site we have loaded... unnecessary, should be using
			var pages = wispData.sitePages[props.site.id];
			// var pages = wispData.sitePages;
			let busyCount = 0;
			// for (var key in pages) {
			//	var docMaps = pages[key][0].doc_maps;	// data is encoded weird - not sure why we need [0] - i.e. have dictionary with array
			var docMaps = pages[0].doc_maps;	// data is encoded weird - not sure why we need [0] - i.e. have dictionary with array
			docMaps.forEach((docMap, i) => {
				if (selectedDocs[docMap.doc_id] === true) {
					var url = getWispUrl(wisp_urls.document_delete, docMap.doc_id);
					var token = wispData.token;
					var headers = {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token,
						'app-id': process.env.REACT_APP_ID,
						'user-id': wispData.publicState.wispUser.id,
						'box-id': docMap.box_id,
					}
					setBarBusy(true);
					busyCount = busyCount + 1;
					fetch(url, { method: 'POST', mode: 'cors', headers })
						.then(function (response) {
							console.log('response.status = ' + response.status); // Will show you the status
							return response.json();
						})
						.then(data => {
							console.log('success = ' + data);
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
								setNumSelected(0);
								refreshPageDocs();
							}

							selectedDocs[docMap.doc_id] = false;
							setSelectedDocs(selectedDocs);

						})
						.catch((error) => {
							console.error('Error:', error);
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
							}
						});

				}
			});
//			}
		}
	}

    function getSelectedDoc(wispDocs) {
		var docMaps = wispData.siteDocMaps;
		if (docMaps) {
			docMaps.forEach((docMap, i) => {
				var docId = docMap.doc_id;
				if (selectedDocs[docId] === true) {
					if (typeof wispDocs[docId] !== 'undefined' && wispDocs[docId] !== null) {
						if (wispDocs[docId] !== 'loading') {
							setSelectedDoc(wispDocs[docId]);
						}
					} else {
						wispDocs[docId] = 'loading';
						wispData.setWispDocs(wispDocs);

						var url = getWispUrl(wisp_urls.document_get, docMap.doc_id);
						url += '?format=metadata';
						var token = wispData.token;
						var headers = {
							'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + token,
							'user-id': wispData.publicState.wispUser.id,
						}
						fetch(url, { method: 'GET', mode: 'cors', headers })
							.then(function (response) {
								console.log('response.status = ' + response.status); // Will show you the status
								return response.json();
							})
							.then(data => {
								var wispDocs = wispData.wispDocs;
								wispDocs[docId] = data;
								wispData.setWispDocs(wispDocs);
								setSelectedDoc(data);
								setForceUpdate(forceUpdate + 1)
							})
							.catch((error) => {
								console.error('Error:', error);
							});
					}
				}
			});
		}
	}

	function downSel() {
		if (!barBusy) {
			var busyCount = 0;
			var docMaps = wispData.siteDocMaps;
			docMaps.forEach((docMap, i) => {
				if (selectedDocs[docMap.doc_id] === true) {
					var filename = getFileName(docMap.doc_id);
					var url = getWispUrl(wisp_urls.document_get, docMap.doc_id);
					url += '?format=native';
					var token = wispData.token;
					var headers = {
						'Content-Type': 'multipart/form-data',
						'Authorization': 'Bearer ' + token,
						'user-id': wispData.publicState.wispUser.id,
					}
					busyCount = busyCount + 1;
					setBarBusy(true);
					fetch(url, { method: 'GET', mode: 'cors', headers })
						.then(function (response) {
							console.log('response.status = ' + response.status); // Will show you the status
							return response.blob();
						})
						.then(blob => {
							var url = window.URL.createObjectURL(blob);
							var a = document.createElement('a');
							a.href = url;
							a.download = filename;
							document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
							a.click();
							a.remove();  //afterwards we remove the element again
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
							}
						})
						.catch((error) => {
							console.error('Error:', error);
							busyCount = busyCount - 1;
							if (busyCount === 0) {
								setBarBusy(false);
							}
						});
				}
			});
		}
	}

	function moveSelUp() {
		if (!barBusy) {
			var busyCount = 0;
			setAlertDocMessage({text: '', type: 'error'});
			var docs = wispData.siteDocMaps;
			if (docs) {
				docs.forEach((docmap, i) => {
					if (selectedDocs[docmap.doc_id]) {
						if (docmap.order === 1) {
							setAlertDocMessage({text:docmap.filename + ' is already the top document',type: 'error'});
						} else {
							var token = wispData.token;
							var headers = {
								'Authorization': 'Bearer ' + token,
								'user-id': wispData.publicState.wispUser.id,
								'order': docmap.order - 1,
							}
							var url = getWispUrl(wisp_urls.docmap_reorder, docmap.id);
							busyCount = busyCount + 1;
							setBarBusy(true);
							fetch(url, { method: 'POST', headers: headers })	//  mode: 'cors', headers: headers,
								.then(function (response) {
									console.log('response.status = ' + response.status); // Will show you the status
									return response.json();
								})
								.then(data => {
									console.log('success = ' + data);
									busyCount = busyCount - 1;
									if (busyCount === 0) {
										refreshPageDocs();
										setBarBusy(false);
									}
								})
								.catch((error) => {
									busyCount = busyCount - 1;
									if (busyCount === 0) {
										setBarBusy(false);
									}
									console.error('Error:', error);
								});

						}
					}
				});
			}
		}
	}

	function getPagesResult(success, data) {
		if (success && props && props.site) {
			// put the site pages into the dictionary of site pages
			var curValue = wispData.sitePages;
			if (typeof curValue !== 'undefined') {
				curValue[props.site.id] = data;
			} else {
				// var id = props.site.id;
				curValue = { id: data };
			}
			wispData.setSitePages(curValue);
			var docMaps = data[0].doc_maps;	// data is encoded weird - not sure why we need [0] - i.e. have dictionary with array
			wispData.setSiteDocMaps(docMaps);	// not sure why we have this locally too - in any case, DocManager/SiteEdit use the wispData one
		}
	}

	function getFileName(doc_id) {
		var docmaps = wispData.siteDocMaps;
		var filename = '';
		if (docmaps) {
			docmaps.forEach((docmap, i) => {
				if (docmap.doc_id === doc_id) {
					filename = docmap.filename;
				}
			});
		}
		return filename;
	}

	function setDocPosition(selectedDoc) {
		setShowPosPopup(true);
	}			

	function updateDoc(selectedDoc) {
		if (!barBusy) {
			setAlertDocMessage({text:'OneDrive can take up to 5 minutes to reflect changes made in Microsoft Word & Excel. Be sure to check your updated document to confirm it got your changes!',type:'warning'});

			var url = getWispUrl(wisp_urls.document_update, selectedDoc.id);
			var headers = {
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer ' + wispData.token,
				'user-id': wispData.publicState.wispUser.id,
				'app-id': process.env.REACT_APP_ID,
			}
			setBarBusy(true);
			fetch(url, { method: 'POST', mode: 'cors', headers })
				.then(function (response) {
					console.log('response.status = ' + response.status); // Will show you the status
					return response.json();
				})
				.then(data => {
					if ('detail' in data) {
						setRefreshPrompt(true);
					} else {
						var content = wispData.docContent;
						delete content[selectedDoc.id];
						delete content[selectedDoc.id + "_post"];
						wispData.setDocContent(content);
						var url = getWispUrl(wisp_urls.document_get, selectedDoc.id);
						if (wispData.mobileView) {
							url += "?view=mobile";
						} else {
							url += "?view=desktop";
						}
						url += "&format=html";
						// console.log('siteview getting doc = ' + map.doc_id);
						// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
						fetchGet(url, getDocResults2, wispData, true, selectedDoc, {style: 'blog', selection: 'post' }, 'html');

					}
					setBarBusy(false);
				})
				.catch((error) => {
					setBarBusy(false);
					console.error('Error:', error);
				});
		}
	}

	function getDocResults2(success, data, selectedDoc) {
		if (success) {
			var content = wispData.docContent;
			content[selectedDoc.id + '_post'] = data;
			wispData.setDocContent(content);
		}
	}

    function getNumDocs() {
		var docs = wispData.siteDocMaps;
		var numDocs = 0;
		if (docs) {

			docs.forEach((docmap, i) => {
				if (docmap.link_doc_id === null) {
					numDocs++;
				}
			});
		}
		return numDocs;
	}

	function addStory() {
		setShowStoryPicker(true);
	}

	function closePicker() {
		setShowStoryPicker(false);
	}

	function onPicker() {
		refreshPageDocs();
		closePicker();
	}

	function onVideo() {
		refreshPageDocs();
		closeYouTube();
	}

	function closeYouTube() {
		setShowYouTubePopup(false);
	}

	function cameraSuccess(videoUri, arg2) {
		console.log('videoUri = ' + videoUri);
		console.log('arg2 = ' + arg2);
		readVideoFile(videoUri, props.site, wispData, setAlertDocMessage, props.type, setGettingVideo);
  
		/*  
		window.resolveLocalFileSystemURL(videoUri, function success(fileEntry) {

			// Do something with the FileEntry object, like write to it, upload it, etc.
			// writeFile(fileEntry, imgUri);
			console.log("got file: " + fileEntry.fullPath);
			// displayFileData(fileEntry.nativeURL, "Native URL");
	
		}, function (e) {
			console.log('failure - ' + JSON.stringify(e));
		  // If don't get the FileEntry (which may happen when testing
		  // on some emulators), copy to a new FileEntry.
			// createNewFileEntry(imgUri);
		});
		readVideoFile(videoUri)
		setGettingVideo(false);
		*/
	}

	function cameraError() {
		setGettingVideo(false);		
	}

	function addVideo() {
		if (window.cordova) {
			var cameraOptions = {
				destinationType: 1,		//Camera.DestinationType.FILE_URI,
				sourceType: 2, 			// 0 - Camera.PictureSourceType.PHOTOLIBRARY,
				mediaType: 1,			// Camera.MediaType.VIDEO,
			};
			setGettingVideo(true);

			// put it on timeout otherwise video button doesn't rerender for some reason
			setTimeout(function () { 
				navigator.camera.getPicture(cameraSuccess, cameraError, cameraOptions);
			}, 50);
		}
	}

	function addNote() {
		setShowNote(true);
        var pageElement = document.getElementById("docManagerDiv");
        var positionY = pageElement.offsetTop;
        window.scrollTo(0, positionY-80);        
		console.log('docmanager scroll');
    }	

	function cancelNote() {
		setShowNote(false);
	}

	function noteAdded() {
		refreshPageDocs();
	}

	function onDonePicture(success, data) {
		setTakingPicture(false);
		if (success) {
			if ('detail' in data) {
				if (data['detail'] === 'exceeded storage') {
					setAlertDocMessage({text:"You've exceeded your 1 GB storage limit",type:'error'});
				} else {
					setAlertDocMessage({text:"Failed to add the photo to this " + props.type,type:'error'});
				}
			}		
		} else {
			setAlertDocMessage({text:"Failed to add the photo to this " + props.type,type:'error'});
		}
	}

	function preTakePicture() {
		setTakingPicture(true);
		takePicture(wispData, props.site, onDonePicture);		
	}

	function videoSelected(doc) {
		if (fileType(doc.filename) === 'mov') {
			return true;
		}
		return false;
	}

	function rotateDoc(doc) {
		if (!barBusy) {
			var url = getWispUrl(wisp_urls.document_rotate, doc.id);
			var headers = {
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer ' + wispData.token,
				'user-id': wispData.publicState.wispUser.id,
				'app-id': process.env.REACT_APP_ID,
			}
			setBarBusy(true);
			fetch(url, { method: 'POST', mode: 'cors', headers })
				.then(function (response) {
					console.log('response.status = ' + response.status); // Will show you the status
					return response.json();
				})
				.then(data => {
					var content = wispData.docContent;
					delete content[selectedDoc.id];
					wispData.setDocContent(content);
					setBarBusy(false);
				})
				.catch((error) => {
					setBarBusy(false);
					console.error('Error:', error);
				});
		}
	}

	function closeRefresh() {
		setRefreshPrompt(false);
	}

	function setPosition() {
		// this affects the actual docs (wispData.setWispDocs) so reset that doc (unlike move up or down which just affects the docmap)
		if (selectedDoc) {
			var wispDocs = wispData.wispDocs;
			wispDocs[selectedDoc['id']] = null;
			wispData.setWispDocs(wispDocs);
			getSelectedDoc(wispDocs);	// can't call this in render - it will update state
		}
		refreshPageDocs();
		closePosition();
	}
	function closePosition() {
		setShowPosPopup(false);
	}

	var positionPopup;
	if (showPosPopup && selectedDoc) {
		positionPopup = (
			<PositionPopup
				doc={selectedDoc}
				successFN={setPosition}
				cancelFN={closePosition}
			/>
		)
	}

    var checkDiv;
	if (props.status) {
		if (!props.new && wispData.siteDocMaps && wispData.siteDocMaps.length > 0) {
			if (!wispData.mobileView) {
				checkDiv = (
					<div className='set_sect_stat'>
						<FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} />
					</div>
				);
			}
		} else {
			checkDiv = (
				<div className='set_sect_stat' style={{ background: '#eaeaea' }}>
					<FontAwesomeIcon icon={faExclamation} style={{ color: '#ffff1e' }} />
				</div>
			);
		}
	}

	function setAlgorithm(val, arg1) {
		docAlgs[arg1] = val
		setDocAlgs(docAlgs)
		alert(docAlgs[arg1])
		setMadeChange(true);
		setForceUpdate(forceUpdate + 1)
	}

    var docList = "Loading...";

    var docmaps = wispData.siteDocMaps;
    var bookListActions;
    if (docmaps) {
		docList = "No " + props.label.toLowerCase() + " yet";
		if (docmaps.length > 0) {
			var selectCount = 0;
			docList = docmaps.map(function (docmap, i) {
				if (docmap.link_doc_id === null) {	// don't list docs that link to another doc in the box - aka photo captions
					var stl = {};
					if (i === 0) {
						stl = { borderTop: '1px solid #AAA' };
					}
					var icon = (
						<FontAwesomeIcon icon={farCircle} />
					);
					if (selectedDocs[docmap.doc_id] === true) {
						selectCount++;
						icon = (
							<FontAwesomeIcon icon={fasCircle} />
						);
					}
					var status;
					if (props.custom) {
						status = (
							<div className='status_column wisp_app_hint' onClick={() => props.customFN(docmap.doc_id, docmap.filename)}>
								{props.custom}
							</div>
						);
					}
					var options = props.dropdownOptions;
					if (props.dropdown) {
						status = (
							<div className='wisp_in_mid' style={{marginLeft: '30px', float: 'right'}}>
								<WispSelect
									id="docAlgorithmOptions"
									val={options[0]}
									nOptions={options.length}
									arg1={docmap.doc_id}
									options={options}
									fn={setAlgorithm}
									display='inline-table'
									w='130'
								/>
							</div>
						);
					}

					/*() = (
						<div className='status_column wisp_app_hint' >
							{docmap.state}
						</div>
					); */

					var docurl;
					if (!wispData.mobileView && props.showDocUrl) {
						docurl = (
							<div className='doc_column'>{getDocUrl(docmap)}</div>
						);
					}

					if (props.draggable) {
						return (
							<Draggable key={docmap.doc_id.toString()} draggableId={docmap.id.toString()} index={i}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style
										)}
									>
										<div id={docmap.doc_id.toString() + '_div'} className='vip_doc_item wisp_in' style={stl} key={i}>
											<div className='wisp_in_mid vip_circle' id={'vip_' + i + '_circle'} onClick={() => toggleDoc(docmap.doc_id)}>
												{icon}
											</div>
											<div className='wisp_in_mid'>{docmap.filename}</div>
											{status}
											{docurl}
										</div>
									</div>
								)}
							</Draggable>
						);
					} else {
						return (
							<div id={docmap.doc_id.toString() + '_div'} className='vip_doc_item wisp_in' style={stl} key={i}>
								<div className='wisp_in_mid vip_circle' id={'vip_' + i + '_circle'} onClick={() => toggleDoc(docmap.doc_id)}>
									{icon}
								</div>
								<div className='wisp_in_mid'>{docmap.filename}</div>
								{status}
								{docurl}
							</div>
						);
					}
				}
			});
		}
        /* onClick={() => toggleDoc(docmap.doc_id)} */
        if (selectCount > 0) {
            var listActions = [];
            if (selectCount === 1) {
                if (canMoveSelUp() && props.draggable) {
                    listActions.push(<div key='moveup' className='wisp_app_link book_list_action' onClick={moveSelUp}>move up</div>);
                }
                if (canMoveSelDown() && props.draggable) {
                    listActions.push(<div key='movedown' className='wisp_app_link book_list_action' onClick={moveSelDown}>move down</div>);
                }
                if (selectedDoc) {
                    if (selectedDoc.external_id) {
                        listActions.push(<div key='update' className='wisp_app_link book_list_action' onClick={() => updateDoc(selectedDoc)}>update</div>);
                    } else {
                        listActions.push(<ReplaceDropzone key='update' />);
                    }
					if (videoSelected(selectedDoc)) {
						listActions.push(<div key='rotate' className='wisp_app_link book_list_action' onClick={() => rotateDoc(selectedDoc)}>rotate</div>);
					}
					if (props.setPosition) {
						listActions.push(<div key='position' className='wisp_app_link book_list_action' onClick={() => setDocPosition(selectedDoc)}>set position</div>);
					}
				}
            }
            listActions.push(<div key='download' className='wisp_app_link book_list_action' onClick={downSel}>download</div>);
            listActions.push(<div key='delete' className='wisp_app_link book_list_action' onClick={delSel}>delete</div>);
            var spinner;
            if (barBusy) {
                spinner = (
                    <div className='wisp_app_link book_list_action'>
                        <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: '#white', marginTop: '3px', marginRight: '2px' }} />
                    </div>
                )
            }


            bookListActions = (
                <div className='book_list_action_container'>
                    {listActions}
                    {spinner}
                </div>
            )
        }
    }
    var alertDiv;
    if (alertDocMessage.text !== '') {
        alertDiv = (<WispNotification message={alertDocMessage.text} type={alertDocMessage.type} />);
	}
	
	var pickerDiv;
	if (showStoryPicker) {
		pickerDiv = (
			<StoryPickerPopup closeFn={closePicker} successFn={onPicker} type={props.type} site={props.site} />
		)
	}
	var youTubeDiv;
	if (showYouTubePopup) {
		youTubeDiv = (
			<YouTubePopup closeFn={closeYouTube} successFn={onVideo} type={props.type} site={props.site} />
		)
	}

	var storyButton;
	if (props.story) {
		storyButton = (
			<div className='addItem'>
				<WispButton
					bcls='primaryButton'
					d='inline-block'
					width="100%"
					js={addStory}
					m='0px'
					mes="Trip" />
			</div>
		);
    }

	var videoButton;
	if (props.video && window.cordova) {
		var mes = 'Add Video';
		videoButton = (
			<div className='addItem'>
				<WispButton
					bcls='primaryButton'
					busy={gettingVideo}
					d='inline-block'
					width="100%"
					js={addVideo}
					m='0px'
					mes={mes} />
			</div>
		);
	}

	var addDeviceControls;
	if (window.cordova) {
		addDeviceControls = (
			<WispButton
				bcls='primaryButton'
				busy={takingPicture}
				d='inline-block'
				width="100%"
				js={preTakePicture}
				m='0px'
				mes="Add Photo" />
		);
	} else {
		addDeviceControls = (
			<MyDropzone />
		);
	}

	var addNoteDiv;
	if (showNote) {
		addNoteDiv = (
			<Note closeFN={cancelNote} site={props.site} successFN={noteAdded}/>
		)
	}

	var nMes = 'Note';
	if (window.cordova) {
		nMes = 'Add Note';
	}
	var addNoteButton;
	
	/*= (
		<div className='addItem'>
			<WispButton
				bcls='primaryButton'
				d='inline-block'
				width="100%"
				js={addNote}
				m='0px'
				mes={nMes} />
		</div>
	) */

	var addContentEls;
	if (window.cordova) {
		addContentEls = (
			<React.Fragment>
				<div className='addItemsDiv'>
					{addDeviceControls}
					{videoButton}
					{addNoteButton}
				</div>
			</React.Fragment>
		);
	} else {
		addContentEls = (
			<React.Fragment>
				{pickerDiv}
				<div className="addDocTitle">{"Add " + props.label.toUpperCase() + " From..."}</div>
				<div className='addItemsDiv'>
					{addDeviceControls}
					<div className='addItem'>
						<WispButton
							bcls='primaryButton'
							d='inline-block'
							width="100%"
							js={() => authOneDrive('picker_')}
							m='0px 0px 0px 10px'
							mes="OneDrive" />
					</div>
					{videoButton}
					{addNoteButton}
					{storyButton}
				</div>
			</React.Fragment>
		);
	}

    var hintDiv;
    if (props.preferMobile && !window.cordova) {	// 2/17/24 - the only app setting this is Crazydoes
		hintDiv = (
			<div style={{marginTop: '20px'}}>
	        	<WispNotification message="The Crazydoes mobile app automatically geotags photos, videos &amp; notes. Use the 'set position' feature above to manually set position information." type='warning' />
			</div>
		);
	}

	var refreshPopup;
	if (refreshPrompt) {
		refreshPopup = (
			<RefreshPopup closeFN={closeRefresh} authFN={() => authOneDrive('')} />
		);
	}

	var addEls;
	if (props.addContent) {
		addEls = (
			<div className='addContentDiv'>
				{addContentEls}
			</div>
		);
	}

    return (
        <div className='siteEditSection' id='docManagerDiv'>
            {checkDiv}
            <div className='settings_heading'>
                {props.label}
            </div>
            <div className='wisp_app_list'>
                <div id='mat_type_vip_div'>
					{addEls}
                    {bookListActions}
                    <div id='vip_docsDiv'>
                        <div style={{background: '#FFF', textAlign: 'center' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {docList}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
					{alertDiv}
					{hintDiv}
                </div>
            </div>
			{addNoteDiv}
			{refreshPopup}
			{positionPopup}
			{youTubeDiv}
        </div>
    );
}

