import React, { useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { fetchGet, getWispUrl, loadData, wisp_urls } from './util/fetch.js';
import { getDateStr, idsMatch, isAuthenticated, limitString} from './util/base.js';
import {fileType, getLinkedDoc} from './util/document.js';
import { goToSite, getSiteAuthor, getSiteFromId, getSiteMembers, isSiteAuthor } from './util/site.js';
import { followDoer, isFollowing, loadUserSites, unfollowDoer } from './util/user.js';
import { AuthorPanel } from './components/AuthorPanel';
import { NextLink } from './components/NextLink';
import { PositionMap } from './components/PositionMap';
import {WispButton} from './components/WispButton';
import {WispDoc} from './components/WispDoc.js';
import {wispHistory} from './components/WispHistory.js';
import {WispNotification} from './components/WispNotification';
import { SharePopup } from './popups/SharePopup.js';
import {WispContext} from './WispProvider';
import {DocView} from './DocView';
import { getChallengeDiamonds } from './EventPopup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faMapMarkerAlt, faPen, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { Document, Page, pdfjs } from 'react-pdf';
import videojs from 'video.js';
import $ from 'jquery';
import ReactPlayer from 'react-player';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// props.userId is the admin user for this site - obtained from url
export const SiteView = (props) => {

	var forceUpdateCount = 0;

    var wispData = useContext(WispContext);
    const [alertMessage, setAlertMessage] = React.useState({text: '', type: 'warning'});
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [curPageId, setCurPageId] = React.useState(0);
	const [curView, setCurView] = React.useState(null);	// valid values are stream and document
	const [navOpen, setNavOpen] = React.useState(!wispData.mobileView);
	const [docMapsLoaded, setDocMapsLoaded] = React.useState(false);
	const [refreshingDocMaps, setRefreshingDocMaps] = React.useState(false);
    const [homeUser, setHomeUser] = React.useState(null);
	// const [followStatus, setFollowStatus] = React.useState(null);
//	const [videoDocRefs, setVideoDocRefs] = React.useState({});
	const [vidPlayers, setVidPlayers] = React.useState({});
	const [posters, setPosters] = React.useState({});
	const [centerMapId, setCenterMapId] = React.useState(null);
	const [fullDesc, setFullDesc] = React.useState(false);
	const [siteDocMaps, setSiteDocMaps] = React.useState(null);
	const [nRenderDocs, setNRenderDocs] = React.useState(3);
	const [yScroll, setYScroll] = React.useState(0);
	const [showShareMap, setShowShareMap] = React.useState(null);

	const [createdListener, setCreatedListener] = React.useState(false);

	// pdf stuff
	const [numPages, setNumPages] = React.useState(null);
	const [pageNumber, setPageNumber] = React.useState(1);

    useEffect(() => {
		console.log('useEffect');
		if (isAuthenticated(wispData) && idsMatch(props.userId, wispData.publicState.wispUserId)) {
			wispData.setUserSitesNav(wispData.userSitesAuth);
		}
		if (!refreshingDocMaps) {
			setRefreshingDocMaps(true);
			var tCurView;
			if (props.site.app_data && props.site.app_data.view_pref) {
				var pref = props.site.app_data.view_pref;
				tCurView = {type: pref, value: null};
			} else {
				tCurView = {type: 'stream', value: null};
			}
			setCurView(tCurView);
	

			var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
			fetchGet(url, getPagesResult, wispData, true, tCurView);	// will get one page with all the docBoxMaps, so probably redundant we're calling getDocMaps next

			if (isAuthenticated(wispData) && idsMatch(props.userId, wispData.publicState.wispUserId)) {
				setHomeUser(wispData.publicState.wispUser);
			} else {
				var url = wisp_urls.user_get + props.userId + '/';
				fetchGet(url, getUserResult, wispData);
				if (wispData.userSitesNav === null) {
					loadUserSites(wispData, props.userId, wispData.publicState.wispUserId, wispData.token, getSitesResult, true, null);
				}
			}

			if (props.site.id in wispData.siteAuthors === false) {
				getSiteMembers(props.site.id, ['author', 'admin'], gotMembers, '');
			}

			if (isAuthenticated(wispData) && wispData.following === null) {
				reloadFollowing(false);
			}
		}
		/* not doing anything it seems
		if (wispData.publicState.wispUser && !idsMatch(props.userId, wispData.publicState.wispUser.id)) {	// if the auth user isn't the owner of this site, not sure why using wispUser.id vs wispUserId above - hate how there's both
			var url = getWispUrl(wisp_urls.user_follow, wispData.publicState.wispUser.id);
			fetchGet(url, getFollowResult, wispData);
		} */
		if (!createdListener) {
			console.log('creating listener');
			setCreatedListener(true);
			window.addEventListener('scroll', handleScroll);
		}
		setNumPages(100);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [wispData.userSitesAuth]);		// curPageId won't change for a site so useEffect will only get called once per site loading - hmmm seeing it called twice

    function getSitesResult(success, data) {
        if (success && data != 'fetching') {
            // if the authenticated user is this "Home User", then set provider's userSites value
            wispData.setUserSitesNav(data);
        }
    }

	function getFollowingResults(success, data) {
		if (success) {
            if ('detail' in data) {
				console.log('failed to load following list: ' + data);
			} else {
				wispData.setFollowing(data);
				var tFollowUser;
				wispData.setFollowUser(prevValue => {tFollowUser = prevValue; return (prevValue)});

				if (tFollowUser != null) {
					// do we have a followmap to this user?
					var found = false;
					data.forEach((id) => {
						if (id === tFollowUser.user_id) {
							found = true;
						}
					})
					if (!found) {
						followDoer(wispData, '/' + props.userId + '/' + props.site.url, tFollowUser, props.userId, reloadFollowing, homeUser);
					} else {
						setAlertMessage({text:"You're already following " + tFollowUser.first_name + " and will get notified when " + tFollowUser.first_name + " content is updated.",type:'warning'});
					}
					//var tRedir = wispData.createAccRedirect;
					wispData.setCreateAccRedirect(null);
					wispData.setFollowUser(null);
					wispData.setCreateAccNote(null);
					setForceUpdate(forceUpdateCount+1);
					// wispHistory.push(tRedir);
					// shouldn't have to redirect
				}
			}
		}
	}

	function handleScroll() {
		var tcurView;
		setCurView(prevValue => {tcurView = prevValue; return (prevValue)});
		if (tcurView && (tcurView.type === 'stream' || tcurView.type === 'word')) {
			var tYScroll;
			setYScroll(prevScroll => {tYScroll = prevScroll; return (prevScroll)});
			if (window.scrollY != tYScroll) {
				setYScroll(window.scrollY);
				// how far are we down the window?
				var el = document.getElementById('siteContainer');
				if (el) {
					var height = el.offsetHeight;
					var windowHeight = window.innerHeight;
					var yPos = window.scrollY + windowHeight;
					// console.log('yPos = ' + yPos + ' and height = ' + height);
					if ((yPos + 600) > height) {
						var nTRenderDocs;
						setNRenderDocs(prevCount => {nTRenderDocs = prevCount+3; return (prevCount + 3)});
						// setNRenderDocs(prevState => ({value: prevState.value + 3}));
						var tSiteDocMaps;
						setSiteDocMaps(prevMaps => {tSiteDocMaps = prevMaps; return (prevMaps)});
						if (tSiteDocMaps) {
							loadCurrentDocs(tSiteDocMaps, nTRenderDocs, tcurView);
						}					
						forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
						setForceUpdate(forceUpdateCount+1);
					}
				}
			}
		}
	}

	function gotMembers(data) {
		var siteAuthors = wispData.siteAuthors;
		siteAuthors[props.site.id] = data;
		wispData.setSiteAuthors(siteAuthors);
	}

	function getUserResult(success, data) {
        if (success) {
            setHomeUser(data);
        }
    }

	/* function getFollowResult(success, data) {
		if (success) {
            if ('detail' in data) {
				console.log('failed to get follow status - ' + data.detail);
			} else {
				setFollowStatus(data.status);
			}
		}
	} */

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	function doEditToggle() {
		wispHistory.push('/' + props.userId + '/' + props.site.url + '/edit');
//		setEdit(!edit);
	}

    function getPagesResult(success, data, tCurView) {
        if (success && props && props.site) {
			setRefreshingDocMaps(false);
            // put the site pages into the dictionary of site pages
            var curValue = wispData.sitePages;
            if (typeof curValue != 'undefined') {
                curValue[props.site.id] = data;
            } else {
                var id = props.site.id;
                curValue = { id: data };
            }
            wispData.setSitePages(curValue);

            // this needs work, but for now if there's only one page then load the page docs
            if (data.length >= 1) {
                setCurPageId(data[0].id);	//hooks suck - if the fetchGet below doesn't call the backend then 
            }

			var deployment = 'prod';
			if (process.env.REACT_APP_TEST === 'true') {
				deployment = 'test';
			}
			var docMaps = data[0].doc_maps;	// data is encoded weird - not sure why we need [0] - i.e. have dictionary with array
			setSiteDocMaps(docMaps);
			wispData.setSiteDocMaps(docMaps);	// not sure why we have this locally too - in any case, DocManager/SiteEdit use the wispData one
			var nTRenderDocs;
			setNRenderDocs(prevCount => {nTRenderDocs = prevCount; return (prevCount)});
			loadCurrentDocs(docMaps, nTRenderDocs, tCurView);
			setDocMapsLoaded(true);
        }
	}

	function loadCurrentDocs(docMaps, nRenderDocs2, tCurView) {
		// console.log('loadCurrentDocs with nRenderDocs2 = ' + nRenderDocs2);
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}
		var tContent;
		wispData.setDocContent(prevContent => {tContent = prevContent; return (prevContent)});
		var tPosters;
		setPosters(prevValue => {tPosters = prevValue; return (prevValue)});

		var tcount = 0;	// do count separate from forEach cause linkDocs (captions) don't count
		docMaps.forEach((map) => {
			if (tCurView && tCurView.type === 'doc' && tCurView.value === null && tcount === 0) {
				setCurView({type: 'doc', value: map})
			} else if (tCurView && (tCurView.type === 'stream' || tCurView.type === 'word')) {
				// console.log('i = ' + i + ' and nRenderDocs2 = ' + nRenderDocs2 + ' and tContent = ' + tContent[map.doc_id + '_post'] + ' and tPosters = ' +  tPosters[map.doc_id]);
				if ((tcount+1) <= nRenderDocs2 && typeof tContent[map.doc_id + '_post'] === 'undefined' && typeof tPosters[map.doc_id] === 'undefined') {
					// console.log('gonna render ' + map.filename);
					if (props.docId && props.docId !== '' && props.docId !== 'edit' && map.doc_id === parseInt(props.docId)) {	// don't see anyone setting props.docId
						setCurView({type: 'doc', value: map})
					} else {
						if (fileType(map.filename) === 'mov') {
							// console.log('setting poster for ' + map.filename);
							var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + map.creator_id + '/' + map.doc_id + '_video/' + map.doc_id + '.0000000.jpg';
							tPosters[map.doc_id] = poster;
							setPosters(tPosters);
							/* var count = Object.keys(videoDocRefs).length;
							videoDocRefs[map.doc_id] = videoRefs[count];
							setVideoDocRefs(videoDocRefs); */
							// when a doc is added, a previous mov file will already be loade din videoDocRefs, but have to recreate the player it seems
							/* setTimeout(function () {
								createPlayer(map.doc_id, "", map.creator_id, deployment, props.userId, 0);
							}, 1000);			*/
						} else {	
							var url = getWispUrl(wisp_urls.document_get, map.doc_id);
							if (wispData.mobileView) {
								url += "?view=mobile";
							} else {
								url += "?view=desktop";
							}
							url += "&format=html";
							// console.log('siteview getting doc = ' + map.doc_id);
							// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
							var options = {style: 'blog' };
							if (tCurView && tCurView.type === 'stream') {
								options.selection = 'post';
							}
							fetchGet(url, getDocResults2, wispData, true, map, options, 'html');
						}
					}
				}
			}
			if (map.link_doc_id === null) {
				tcount++;
			}
		});
	}
	/*
	function getDocMapsResult(success, data) {
		if (success) {
			setRefreshingDocMaps(false);
			wispData.setSiteDocMaps(data);
			setDocMapsLoaded(true);
		}
	} */

	// tried putting this in base.js but got issues with videoDocRefs
	// postfix allows for embedding videos in docx files - i.e. you can have a video on a page with docId=x and the same video embedded in a word document
	function createPlayer(docId, postfix, creatorId, deployment, userId, count) {
		// console.log('base videoDocRefs = ' + videoDocRefs);
		/* var tVideoDocRefs;
		setVideoDocRefs(currentState => {tVideoDocRefs = currentState; return (currentState)});
		if (tVideoDocRefs[docId + postfix] && typeof tVideoDocRefs[docId + postfix].current != 'undefined') {
			if (!isNaN(docId)) {
				console.log('creating player for ' + docId + ' with postfix = ' + postfix);
				const videoJsOptions = {
					autoplay: false,
					controls: true,
					fill: true,
					sources: [{
						src: 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '_1.m3u8',
						type: 'application/vnd.apple.mpegurl',
					}]
				}
				var player = videojs(videoDocRefs[docId + postfix].current, videoJsOptions, function onPlayerReady() {
					console.log('onPlayerReady', this)
				});
			}
		} else if (count < 10) {
			// console.log('video element not ready');
			setTimeout(function () {
				createPlayer(docId, postfix, creatorId, deployment, userId, count + 1);
			}, 1000);
		} */
	}


	function getDocResults2(success, data, map) {
		if (success) {
			var content = wispData.docContent;

			if (fileType(map.filename) === 'docx') {
				setTimeout(function () {
					processVideoNodes(map);
				}, 1000);
			}
			content[map.doc_id + '_post'] = data;
			wispData.setDocContent(content);
			forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
			setForceUpdate(forceUpdateCount+1);
		}
	}

	function processVideoNodes(map) {
		// var dom_nodes = $($.parseHTML(data));
		// var vidnodes = dom_nodes.find(".tengine-video");
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}
		var tPosters;
		setPosters(prevValue => { tPosters = prevValue; return (prevValue) });
		var vidnodes = $(".tengine_video_" + map.doc_id);
		var postfix = 'docx';
		
		for (var i = 0; i < vidnodes.length; i++) {
			var node = vidnodes[i];
			var html = node.innerHTML;
			// html will be of the format - process.env.REACT_APP_URL + "/" + getCreatorId() + '/' + props.site.url + '/' + props.map.doc_id;
			var ti = html.lastIndexOf('/');
			if (ti > 0) {
				var docId = html.substring(ti + 1);
				html = html.substring(0, ti);
				ti = html.lastIndexOf('/');
				if (ti > 0) {
					var siteUrl = html.substring(ti + 1);
					html = html.substring(0, ti);
					ti = html.lastIndexOf('/');
					if (ti > 0) {
						var creatorId = html.substring(ti + 1);
						console.log('setting poster for docId = ' + docId);
						var poster = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '.0000000.jpg';
						tPosters[docId] = poster;
						setPosters(tPosters);
						// var count = Object.keys(videoDocRefs).length;
						// videoDocRefs[docId + postfix] = videoRefs[count];
						// setVideoDocRefs(videoDocRefs);
						var turl = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + creatorId + '/' + docId + '_video/' + docId + '_1.m3u8';
						var h = '700px';
						if (wispData.mobileView) {
							h = '280px';
						}
						var videoContent = (
							<div className='videoPlayerCont'>
								<ReactPlayer 
									url={turl}
									controls={true}
									width='100%'
									height={h} 
									/>
							</div>
						);
						ReactDOM.render(videoContent, node);
						// when a doc is added, a previous mov file will already be loade din videoDocRefs, but have to recreate the player it seems
						// createPlayer(docId, postfix, creatorId, deployment, props.userId, 0);
					}
				}
			}
		}
		/* 								<div data-vjs-player>
									<video controls poster={posters[docId]} ref={videoDocRefs[docId + postfix]} className="video-js" ></video>
								</div>
		*/
		
		var i = 0;

	}

	function getMapFromDocId(maps, docId) {
		var themap;
		maps.forEach((map) => {
			if (map.doc_id === parseInt(docId)) {
				themap = map;
			}
		});
		return themap;
	}

	function selPanDoc(map) {
		if (map) {
			wispHistory.push('/' + props.userId + '/' + props.site.url + '/' + map.doc_id);
			// var dt = new Date(map.date_time);
			// dt = getDateStr(dt, false);
			// setView({ type: 'document', value: map, date: dt });

		}
	}

    function setView(view) {
		/* if (wispData.mobileView) {
			setNavOpen(false);
		} */
		setCurView(view);
    }

	function wisp_popup_toggle() {
		setNavOpen(!navOpen);
	}

	function refreshDocMaps() {
		console.log('Refreshing doc maps');
		setRefreshingDocMaps(true);
	}

	function getChallengeName(challenge) {
		if (wispData.eventData['challenges']) {
            var cells = wispData.eventData['challenges']['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][0] && row['cols'][2] && row['cols'][0]['v'] === challenge) {
                    return (
						<div style={{margin: '0px 20px'}}>{row['cols'][2]['v']}</div>
					);
				}
			}
		}
	}

	function reloadFollowing(refresh) {
		var url = getWispUrl(wisp_urls.user_following, wispData.publicState.wispUserId);
		fetchGet(url, getFollowingResults, wispData, true, null, {'app-id': process.env.REACT_APP_ID});
		if (refresh) {
			setForceUpdate(forceUpdateCount+1);			
		}
	}

	function postOnMap(map) {
		setCenterMapId(map.id);
        var pageElement = document.getElementById("siteMapCont");
		if (pageElement) {
	        window.scrollTo(0, pageElement.offsetTop);
			console.log('siteview scroll');
		}
	}

	function goRead(map, dt) {
		/* if (fileType(map.filename) !== 'mov') {
			var content = wispData.docContent;
			if (typeof content[map.doc_id] === 'undefined') {	
				var url = getWispUrl(wisp_urls.document_get, map.doc_id);
				if (wispData.mobileView) {
					url += "?view=mobile";
				} else {
					url += "?view=desktop";
				}
				url += "&format=html";
				// setting force update to true because docContent is only null under two scenarios: 1) haven't got it yet, 2) did update doc in booksettings and cleared out the html
				fetchGet(url, getDocResults3, wispData, true, map, {style: 'blog' }, 'html');
			}
		} */
		wispHistory.push('/' + props.userId + '/' + props.site.url + '/' + map.doc_id);
		// setView({ type: 'document', value: map, date: dt });
		// window.scrollTo(0, 400);
	}

	function getDocResults3(success, data, map) {
		if (success) {
			var content = wispData.docContent;
			content[map.doc_id] = data;
			wispData.setDocContent(content);
			forceUpdateCount++;	// don't use forceUpdate itself - this function can get called twice before shitty effects will update forceUpdate
			setForceUpdate(forceUpdateCount+1);
		}
	}

	function goToPosts() {
		setView({ type: 'stream', value: {}})		
	}

	function addFN() {
		console.log('addFN');
		setRefreshingDocMaps(true);
		// reload docmaps
		var url = getWispUrl(wisp_urls.site_pages_get, props.site.id);
		fetchGet(url, getPagesResult, wispData, true);	// will get one page with all the docBoxMaps, so probably redundant we're calling getDocMaps next
	}

	function gotoUser(userId) {
		wispHistory.push('/' + userId);
	}

	function readMore() {
		setFullDesc(true);
	}

	function readLess() {
		setFullDesc(false);
	}

	function getPositionIcon(author) {
		var icon = (
			<div className='doerIcon'>
				<FontAwesomeIcon className='fa-2x' icon={faUser} style={{color: 'rgb(225,225,225)'}}/>
			</div>
		);
		var markerColors = [
			'rgb(80,80,240)',
			'rgb(240,80,80)',
			"rgb(70,240,70)",
			'rgb(160,160,70)',
			'rgb(70,160,160)',
		];
		if (wispData.locationData) {
			wispData.locationData.forEach((location, i) => {
				if (location.user_id === author.user_id) {
					icon = (
						<div className='doerIcon'>
							<FontAwesomeIcon className='fa-2x' icon={faUser} style={{color: markerColors[i]}}/>
						</div>			  
					);
				}
			});			
		}
		return icon;
	}

	function goToDoerPage(author) {
        wispHistory.push('/' + author.user_id);
	}

	function onShare(map) {
		setShowShareMap(map);
	}

	function closeSharePopup() {
		setShowShareMap(null);
	}

	function selTrip(site) {
		setRefreshingDocMaps(false);
		setSiteDocMaps(null);
		var url = getWispUrl(wisp_urls.site_pages_get, site.id);
		fetchGet(url, getPagesResult, wispData);	// will get one page with all the docBoxMaps, so probably redundant we're calling getDocMaps next
		goToSite(site.url, props.userId);
	}


	// console.log('render siteview with nRenderDocs = ' + nRenderDocs);

	var vidLeft = '-10000px';
	var vidPosition = 'absolute';
	var docView;
	if (curView && curView.type === 'doc') {

		var head;
		
		if (homeUser) {
			head = (
				<div className='postTitleCont'>
					<div className='postItemName'>by {homeUser.first_name + ' ' + homeUser.last_name}</div>
					<div className='postItemDate'>{curView.date}</div>
				</div>
			);
		}

		// may not have value yet
		if (curView.value) {
			if (curView.value.url != null && curView.value.url != '') {
				var url = curView.value.url;
				var i = url.lastIndexOf('/');
				if (i > 0) {
					url = 'https://www.youtube.com/embed' + url.substring(i);
				}
				docView = (
					<div className={'wispPageContainer wispBlog'} style={{ marginTop: '80px', width: '100%', height: '100%' }}>
						<iframe width='100%' height='100%' style={{ minHeight: '500px' }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						<NextLink siteDocMaps={siteDocMaps} value={curView.value} curPageId={curPageId} selPanDocFN={selPanDoc} />
					</div>
				);
			} else if (fileType(curView.value.filename) === 'mov') {
				docView = (
					<div className={'wispPageContainer wispBlog'} style={{ marginTop: '80px', width: '100%', height: '100%' }}>
						{head}
						<NextLink siteDocMaps={siteDocMaps} value={curView.value} curPageId={curPageId} selPanDocFN={selPanDoc} />
					</div>
				);
			} else if (fileType(curView.value.filename) !== 'pdf') {
				docView = (
					<DocView site={props.site} docId={curView.value.doc_id} navOpen={navOpen} curPageId={curPageId} selPanDocFN={selPanDoc} head={head} seeAllPosts={goToPosts} userId={props.userId} backMes='trip page' />
				)
			} else {
				docView = (
					<WispDoc value={curView.value} navOpen={navOpen} loadFN={onDocumentLoadSuccess} numPages={numPages} head={head} />
				);
			}
		}
	}
	
	var c = 'siteViewCont';
	// if (window.cordova) {
	// 	c = 'siteViewContMobile';
	// }

	var challengeInfo;
    /* var toggleIconCont;
    var toggleClass = 'mapToggleDiv';
	var editToggle;
	if (curView.type === 'stream' && isAuthor) {
        toggleIconCont = (
            <FontAwesomeIcon className='tripBarIcon mapToggleIcon' icon={faPen}/>
		);

		editToggle = (
			<div className={toggleClass + ' noteEditToggle'} onClick={doEditToggle}>
				{toggleIconCont}
			</div>
		);
	} 
	<div className='noteEditCont'>
		{editToggle}
	</div>
	
	*/

	var site = props.site;
	if (!site) {
		return;
	}
	var appData = site.app_data;
	if (appData && appData.challenge && appData.challenge != '0') {
		var diamonds = getChallengeDiamonds(wispData, appData.challenge);
		challengeInfo = (
			<div className='tripChallengeInfo'>
				{diamonds}
				{getChallengeName(appData.challenge)}
			</div>
		);
	}

	var doerInfo;
	var description;
	if (homeUser) {
		var siteAuthors = wispData.siteAuthors;
		if (props.site.id in siteAuthors) {
			var authors = siteAuthors[props.site.id].map(function (author, i) {
				if (author.state === 'active') {
					var followBut;
					// not signed in or not this author
					if (!isAuthenticated(wispData) || (wispData.following && (wispData.publicState.wispUser && author.user_id != wispData.publicState.wispUser.id))){
						if (isFollowing(wispData, author.user_id)) {
							followBut = (
								<div key='followBut' className='followBut' onClick={() => unfollowDoer(wispData, props.userId, reloadFollowing)}>
									following
								</div>
							);
						} else {
							followBut = (
								<div key='followBut' className='followBut' onClick={() => followDoer(wispData, '/' + props.userId + '/' + props.site.url, author, props.userId, reloadFollowing, homeUser)}>
									follow
								</div>
							);
						}
					}
					// got position info for this author?
					var positionIcon = getPositionIcon(author);
					return (
						<div className='doerInfo' key={'doerInfo'+i}>
							{positionIcon}
							<div key='doerName' className='doerName' onClick={() => goToDoerPage(author)}>
								{author.first_name + ' ' + author.last_name}
							</div>
							{followBut}
						</div>
					);
				}
			});
			doerInfo = (
				<div className='teamContainer'>
					{authors}
				</div>
			)
		}

		var tdesc = site.description;
		if (tdesc && tdesc.length > 200) {
			if (fullDesc === false) {
				tdesc = tdesc.substring(0,200);
				var ti = tdesc.lastIndexOf(' ');
				if (ti > 100) {
					tdesc = tdesc.substring(0,ti);
				}
				tdesc = (
					<div>
						{tdesc}...
						<span className='readMore' onClick={readMore}>Read More</span>
					</div>
				);
			} else {
				tdesc = (
					<div>
						{tdesc}
						<span className='readMore' onClick={readLess}>Read Less</span>
					</div>
				);
			}
		}
		description = (
			<div className='siteDesc'>
				{tdesc}
			</div>
		);
	}

	var isAuthor = isSiteAuthor(props.site);


	var googleMap;
	var posts;
	var editBar;
	var editDiv;

	if (siteDocMaps && homeUser) {
		var deployment = 'prod';
		if (process.env.REACT_APP_TEST === 'true') {
			deployment = 'test';
		}

		var maps = siteDocMaps;
		var tracks = true;
		var showMap = true;
/*		if (curView.type === 'document') {
			// only show that doc on the map
			tracks = false;
			maps = [];
			// may not have curView.value yet
			if (curView.value != null && curView.value.doc_id) {
				siteDocMaps.forEach((map) => {
					if (map.doc_id === curView.value.doc_id && map.lat && map.long) {
						maps.push(map);
					}
				});
			}
			if (maps.length === 0) {
				showMap = false;
			}
		} */
		if (showMap) {
			var mapClass = 'sitePositionMap';
			if (isAuthor) {
				mapClass = 'sitePositionMapEdit';
			}
			// pass nDocMaps to get the PositionMap to rerender when additional docmaps are added
			googleMap = (
				<PositionMap 
					key={maps.length}
					centerMapId={centerMapId}
					site={props.site} 
					user={homeUser} 
					mapClass={mapClass}
					siteDocMaps={maps}
					tracks={tracks}
				/>
			);
		}
		if (curView && (curView.type === 'stream' || curView.type === 'word')) {
			var tc = 0;
			posts = siteDocMaps.map(map => {
				// console.log('render map = ' + map.filename);
				if (map.link_doc_id === null) {
					tc = tc + 1;
					// console.log('tc = ' + tc + ' and nRenderDocs = ' + nRenderDocs);
					if (tc <= nRenderDocs && (curView.type === 'stream' || fileType(map.filename) === 'docx')) {
						var dt;
						if (map.date_time.length > 0) {
							dt = new Date(map.date_time);
							dt = getDateStr(dt, false);
						}
						var content;
						var mapFloater;
						var keepReading;

						if (fileType(map.filename) === 'mov') {
							var tcreatorId = homeUser.id;
							if (map.creator_id) {
								tcreatorId = map.creator_id;	// should have the creatorId in map
							}

							var h = '700px';
							if (wispData.mobileView) {
								h = '280px';
							}
							var turl = 'https://d3utvv1dkhkch6.cloudfront.net/' + deployment + '/wisp/' + tcreatorId + '/' + map.doc_id + '_video/' + map.doc_id + '_1.m3u8';
							content = (
								<div className='videoPlayerCont'>
									<ReactPlayer 
										url={turl}
										controls={true}
										width='100%'
										height={h} 
										/>
								</div>
							);
						} else if (wispData.docContent[map.doc_id + '_post']) {
							var cls = '';
							if (fileType(map.filename) === 'pdf') {
								cls = ' pdfSmallView';
							}
							content = (
								<div className={'wispBlog' + cls} dangerouslySetInnerHTML={{ __html: wispData.docContent[map.doc_id + '_post'] }} style={{ position: 'relative' }} /> 
							);

							if (curView && curView.type === 'stream' && ((fileType(map.filename) === 'docx' || fileType(map.filename) === 'xlsx') && map.doc_bytes > 2000) || fileType(map.filename) === 'pdf') {
								keepReading = (
									<div className='keepReading' onClick={() => { goRead(map, dt) }}>Keep Reading <span style={{marginLeft: '10px'}}>&gt;</span></div>
								);
							}

						}
						var cls = 'lower';
						if (fileType(map.filename) === 'docx' || fileType(map.filename) === 'xlsx') {
							cls = 'upper';
						}
						if (map.lat && map.long) {
							mapFloater = (
								<div className='postIconDiv' onClick={() => { postOnMap(map) }} >
									<div className={'postIconInnerDiv ' + cls}>
										<FontAwesomeIcon className='postMapIcon' icon={faMapMarkerAlt} />
									</div>
								</div>
							);
						}
						var shareFloater = (
							<div className='postIconDiv' onClick={() => { onShare(map) }} >
								<div className={'postIconInnerDiv ' + cls}>
									<FontAwesomeIcon className='postMapIcon' icon={faShareSquare} />
								</div>
							</div>
						);

						var creatorName = homeUser.first_name + ' ' + homeUser.last_name;
						var creatorId = homeUser.id;
						if (map.creator_id) {
							var creator = getSiteAuthor(wispData, props.site.id, map.creator_id);
							if (creator && creator.first_name && creator.first_name != '') {
								creatorName = creator.first_name + ' ' + creator.last_name;
								creatorId = creator.id;
							}
						}

						var linkedDocEl;
						var linkedDocMap = getLinkedDoc(siteDocMaps, map.doc_id);
						if (linkedDocMap) {
							var lcontent;
							if (wispData.docContent[linkedDocMap.doc_id + '_post']) {
								lcontent = (
									<div className='wispBlog' dangerouslySetInnerHTML={{ __html: wispData.docContent[linkedDocMap.doc_id + '_post'] }} style={{ position: 'relative' }} /> 
								);
							}
							linkedDocEl = (
								<div className='te_caption' key={linkedDocMap.doc_id + "_caption"}>
									{lcontent}
								</div>
							);
						}

						return (
							<div key={map.id} className='postItemCont' style={{position:'relative'}}>
								<div className='postTitleCont' id={map.doc_id + '_pan_item'}>
									<div className='postItemName'>by <span className='userLink' onClick={() => gotoUser(creatorId)}>{creatorName}</span></div>
									<div className='postItemDate'>{dt}</div>
								</div>
								{shareFloater}
								{mapFloater}
								<div id={map.doc_id + '_content'} className='contentWrapper'>
									{content}
									{linkedDocEl}
								</div>
								{keepReading}
							</div>
						);
					} else {
						return (
							<div key={map.id} />
						)
					}
				}
			});
			/* if (!posts || posts.length === 0) {
				posts = (
					<div className='noContentDiv'>
						<div>No content is currently available for <i>{props.site.name}</i>. Please check back later.</div>
					</div>
				);
			} */
		}
		if (isAuthor && wispData.eventData['challenges']) {	// wait til challenges loaded and locationData (i.e. map can be drawn) - why wait for map???  && wispData.locationData
			editBar = (
				<AuthorPanel site={props.site} azure_client_id='cbec7cde-9647-4672-90c4-a4554f0f24fa' addFN={addFN} type='Trip' />
			);
		}
		if (isAuthor) {
			editDiv = (
				<span className='editSiteCont' onClick={doEditToggle}>
					edit
				</span>
			);
		}	
	}

	var sharePopup;
	if (showShareMap) {
		var embed = false;
		var type = fileType(showShareMap.filename);
		if (type === 'mov' || type === 'jpeg' || type === 'png') {
			embed = true;
		}
		sharePopup = (
			<SharePopup map={showShareMap} site_url={props.site.url} user_id={homeUser.id} closeFN={closeSharePopup} embed={embed} />
		)
	}
	// <ReactPlayer url='https://d3utvv1dkhkch6.cloudfront.net/prod/wisp/jefftidd/5709850591887360_video/5709850591887360_1.m3u8' controls='true' />
	// <ReactPlayer url='https://d3utvv1dkhkch6.cloudfront.net/prod/wisp/jefftidd/5644003093512192_video/5644003093512192_1.m3u8' controls='true' />
	var alertDiv;
	if (alertMessage.text !== '') {
		console.log('alert = ' + alertMessage.text);
		alertDiv = (
			<div style={{ marginTop: '15px' }}>
				<WispNotification message={alertMessage.text} type={alertMessage.type} />
			</div>
		);
	}

	var prevDiv;
	var nextDiv;
	var tripCenterClass = 'tripCenterDivWide';
	if (appData && wispData.userSitesNav && appData.trip_prev_id || appData.trip_next_id) {
		tripCenterClass = 'tripCenterDiv';
		var prevSite = getSiteFromId(appData.trip_prev_id, wispData.userSitesNav);
		if (prevSite) {
			prevDiv = (
				<div className='navDescriptPrev' onClick={() => selTrip(prevSite)}>
					<div className='descLabel'>Previous Trip</div>
					<div>{limitString(prevSite.name,17,'...') }</div>
				</div>
			);
		}
		if (appData.trip_next_id) {
			var nextSite = getSiteFromId(appData.trip_next_id, wispData.userSitesNav);
			if (nextSite) {
				nextDiv = (
					<div className='navDescriptNext' onClick={() => selTrip(nextSite)}>
						<div className='descLabel'>Next Trip</div>
						<div>{limitString(nextSite.name,17,'...') }</div>
					</div>
				);
			}
		}		
	}

    return (
		<div className={c} id='siteContainer'>
			{alertDiv}
			<div className='tripHeader'>
				<div className='navDescriptCont'>
					{prevDiv}
					{nextDiv}
				</div>
				<div className={tripCenterClass}>
					<div>
						<div className='tripTitleCont'>
							<div className='tripTitle'>{props.site.name}{editDiv}</div>
							
						</div>
						{challengeInfo}
					</div>
					{description}
					{doerInfo}
				</div>
			</div>
			<div id='siteMapCont'>
				{googleMap}
			</div>
			{editBar}
			{posts}
            {docView}
			{sharePopup}
       </div>
    );
}
  