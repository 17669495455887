import React, {useContext, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {WispContext} from './WispProvider';
import {WispRadio} from './components/WispRadio.js';
import {WispButton} from './components/WispButton';
import {WispNotification} from './components/WispNotification';
import {WispSelect} from './components/WispSelect';
import {loadData, wisp_urls} from './util/fetch.js';
import {getChallengeIndex, gotMultiLocs} from './util/base.js';
import $ from 'jquery';

// props.user is the user object associated with this dashboard
export const TripChallenge = (props) => {

	var forceUpdateCount = 0;
	var newUrl = '';

    var wispData = useContext(WispContext);
	const [forceUpdate, setForceUpdate] = React.useState(0);
	const [catBusy, setCatBusy] = React.useState(false);

    useEffect(() => {
    }, []);

    function radioSel(item) {
        wispData.setAlertMessage('');
        wispData.setChallengeItem(item);
        wispData.setTripChallenge(null);
        wispData.setHikeChallenge(null);
        wispData.setMotorChallenge(null);
        if (item.name === 'Yes, walking') {
            setCatBusy(true);
            loadData(wispData, 'hikes', '5638443006689280', doneFN); // doc id from easyappdata
        } else if (item.name === 'No') {
            $("#acctProps_bookname").val(wispData.tripName);
            wispData.setTripChallenge(0);
            wispData.setTripLat(0);
            wispData.setTripLong(0);
        } else {
            setCatBusy(true);
            loadData(wispData, 'motor', '5679818439917568', doneFN); // doc id from easyappdata
        }
        if (props.changeFN) {
            props.changeFN();
        }
    }

    function doneFN() {
        setCatBusy(false);
        setForceUpdate(forceUpdate+1);
    }

    function getCatTrips(cat) {
		var trips = [];
		if (cat === 'hikes' || cat === 'motor') {
			if (wispData.eventData && wispData.eventData['challenges']) {
				var cells = wispData.eventData['challenges']['cells'];
				addChallengeTrips(cells,trips, cat);
			}
		}
		return trips;
	}

    function getCatTripLocs(cat, challenge) {
		var trips = [];
        var ci = getChallengeIndex(wispData, challenge);
        if (cat === 'motor') {            
            if (wispData.eventData && wispData.eventData['motor']) {
                var cells = wispData.eventData['motor']['cells'];
                addTrips(cells,trips, ci);
            }
        }
		return trips;
	}

    function addTrips(cells, trips, ci) {
		for (var i = 0;i < cells.length;i++) {
			var row = cells[i];
			if (row['cols'][0] && row['cols'][6] && row['cols'][6]['v'] === ci.toString()) {
				/* // make sure not already in array
				var val = row['cols'][0]['v'];
				var got = false;
				for(var ti = 0;ti < trips.length;ti++) {
					if (trips[ti] === val) {
						got = true;
					}
				}
				if (!got) { */
				trips.push(row['cols'][0]['v']);
			}				
		}
	}

    function addChallengeTrips(cells, trips, cat) {
		for (var i = 0;i < cells.length;i++) {
			var row = cells[i];
			if (row['cols'][2] && row['cols'][4] && row['cols'][4]['v'] === cat) {
				trips.push(row['cols'][2]['v']);
			}				
		}
	}


    function getChallengeLat(cat, loc, challIndex) {
        if (wispData.eventData && wispData.eventData[cat]) {
            var cells = wispData.eventData[cat]['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][2] && row['cols'][6] && row['cols'][6]['v'] === challIndex.toString()) {
                    if (!loc || loc === row['cols'][0]['v']) {
                        return row['cols'][2]['v']
                    }
                }				
            }
        }
    }

    function getChallengeLong(cat, loc, challIndex) {
        if (wispData.eventData && wispData.eventData[cat]) {
            var cells = wispData.eventData[cat]['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][3] && row['cols'][6] && row['cols'][6]['v'] === challIndex.toString()) {
                    if (!loc || loc === row['cols'][0]['v']) {
                        return row['cols'][3]['v']
                    }
                }				
            }
        }
    }

    function getChallengeDesc(cat, loc, challIndex) {
        var desc = '';
        if (loc) {
            var cells = wispData.eventData[cat]['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][4] && row['cols'][0] && row['cols'][0]['v'] === loc) {
                    desc = row['cols'][4]['v'];
                }
            }

        } else {
            var cells = wispData.eventData['challenges']['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][3] && row['cols'][0] && row['cols'][0]['v'] === challIndex.toString()) {
                    desc = row['cols'][3]['v'];
                }
            }
        }
        return desc;
    }

    function selHikeChallenge(val) {
        if (props.changeFN) {
            props.changeFN();
        }
        wispData.setAlertMessage('');
        wispData.setHikeChallenge(val);
        var challIndex = getChallengeIndex(wispData, val);
        wispData.setTripChallenge(challIndex);
        wispData.setHikeChallengeLoc(null);
        var multipleLocs = gotMultiLocs(wispData, val, 'hikes');
        wispData.setMultiLocs(multipleLocs);
        if (!multipleLocs) {
            wispData.setTripLat(getChallengeLat('hikes', null, challIndex));
            wispData.setTripLong(getChallengeLong('hikes', null, challIndex));
            wispData.setTripName(val);
            wispData.setTripDesc(getChallengeDesc('hikes', null, challIndex));
        }
    }
    function selMotorChallenge(val) {
        if (props.changeFN) {
            props.changeFN();
        }
        wispData.setAlertMessage('');
        wispData.setMotorChallenge(val);
        var challIndex = getChallengeIndex(wispData, val);
        wispData.setTripChallenge(challIndex);
        wispData.setMotorChallengeLoc(null);
        var multipleLocs = gotMultiLocs(wispData, val, 'motor');
        wispData.setMultiLocs(multipleLocs);
        if (!multipleLocs) {
            wispData.setTripLat(getChallengeLat('motor', null, challIndex));
            wispData.setTripLong(getChallengeLong('motor', null, challIndex));
            wispData.setTripName(val);
            wispData.setTripDesc(getChallengeDesc('motor', null, challIndex));
        }
    }

    function selMotorChallengeLoc(val) {
        if (props.changeFN) {
            props.changeFN();
        }
        wispData.setAlertMessage('');
        wispData.setMotorChallengeLoc(val);
        wispData.setTripLocation(val);
        wispData.setTripLat(getChallengeLat('motor', val, wispData.tripChallenge));
        wispData.setTripLong(getChallengeLong('motor', val, wispData.tripChallenge));
        wispData.setTripName(val);
        wispData.setTripDesc(getChallengeDesc('motor', val, 0)); // last param is challIndex which isn't used if loc (2nd param) is set
    }

	function newTeamNameChange() {
        if (props.changeFN) {
            props.changeFN();
        }
        wispData.setTripName($("#acctProps_bookname").val());
        wispData.setAlertMessage('');
	}

    function teamDescChange() {
        if (props.changeFN) {
            props.changeFN();
        }
        wispData.setTripDesc($("#acctProps_desc").val());
    }

    /* function getCurChallenge() {
        var challenge = '';
        if (wispData.eventData && wispData.eventData['challenges']) {
            var cells = wispData.eventData['challenges']['cells'];
            for (var i = 0;i < cells.length;i++) {
                var row = cells[i];
                if (row['cols'][0] && row['cols'][2] && row['cols'][0]['v'] === curChallenge) {
                    challenge = row['cols'][2]['v'];
                }				
            }
        }
        return challenge;
    } */

    var catItems = [
        {name: 'No',
        },
        {name: 'Yes, walking',
        },
        {name: 'Yes, motorized',
        }
    ];

    var hikeQuestion;
    var motorQuestion;
    var noQuestion;
    var locationEls;
    if (wispData.challengeItem) {
        if (wispData.challengeItem.name === 'Yes, walking') {
            var items = getCatTrips('hikes');
            hikeQuestion = (
                <div className='questionDiv'>
                    <div className='newTripQuestion'>Which walking challenge?</div>
                    <div className='newTripSelectCont'>
                        <WispSelect
							id="hikeTripSel"
							val={wispData.hikeChallenge}
							nOptions={items.length}
							options={items}
							display='inline-table'
							fn={selHikeChallenge}
							w='320'
							busy={catBusy}
						/>
                    </div>
                </div>
            );
        } else if (wispData.challengeItem.name === 'Yes, motorized') {
            var items = getCatTrips('motor');
            if (wispData.motorChallenge) {
                if (wispData.multiLocs) {
                    var items2 = getCatTripLocs('motor', wispData.motorChallenge);
                    locationEls = (
                        <div className='questionDiv'>
                            <div className='newTripQuestion'>{'Which ' + wispData.motorChallenge + ' location?'}</div>
                            <div className='newTripSelectCont'>
                                <WispSelect
                                    id="motorTripLocSel"
                                    val={wispData.motorChallengeLoc}
                                    nOptions={items2.length}
                                    options={items2}
                                    display='inline-table'
                                    fn={selMotorChallengeLoc}
                                    w='320'
                                />
                            </div>
                        </div>    
                    );
                }
            }
            motorQuestion = (
                <div className='questionDiv'>
                    <div className='newTripQuestion'>Which motorized challenge?</div>
                    <div className='newTripSelectCont'>
                        <WispSelect
                            id="motorTripSel"
                            val={wispData.motorChallenge}
                            nOptions={items.length}
                            options={items}
                            display='inline-table'
                            fn={selMotorChallenge}
                            w='320'
                            busy={catBusy}
                        />
                    </div>
                </div>
            )            
        } else if (wispData.challengeItem.name === 'No' && props.new) {
            noQuestion = (
                <div>
                    <div className='questionDiv' key='tripName'>
                        <div className='newTripQuestion'>Where are you going?</div>
                        <div className='newTripSelectCont'>
                            <input tabIndex='1' id='acctProps_bookname' className='settingsInput wt_input' onChange={newTeamNameChange} value={wispData.tripName} />
                        </div>
                    </div>
                    <div className='questionDiv' key='tripDesc'>
                        <div className='newTripQuestion'>Trip Description (optional)</div>
                        <div className='newTripSelectCont'>
                            <div className='te_textareadiv active' style={{ display: 'inline-table',height: '60px', width:'100%' }}>
                                <textarea tabIndex='3' className='wt_textarea' id='acctProps_desc' style={{ height: '50px' }} onChange={teamDescChange} value={wispData.tripDesc}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
		<div>
            <div className='newTripQuestion'>Is your adventure part of a Crazydoes challenge?</div>
            <div className='newTripRadioCont'>
    			<WispRadio items={catItems} radioClass='radioButton' initItem={wispData.challengeItem} id='categories' clickFN={radioSel} />
            </div>
            {hikeQuestion}
            {motorQuestion}
            {noQuestion}
            {locationEls}
        </div>
    );
}
